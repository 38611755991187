/**
 * @generated SignedSource<<53d77fa47a453c497c240b0c61928047>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PostCardFragment$data = {
  readonly id: string;
  readonly feedId: string;
  readonly releasedAt: string | null;
  readonly feed: {
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  };
  readonly content: {
    readonly id: string;
    readonly type: ContentType;
    readonly " $fragmentSpreads": FragmentRefs<"ContentCard_ContentFragment" | "FlagContentButtonFragment" | "ContentFlaggingsChipFragment" | "useCanFlagContentFragment">;
  };
  readonly product: {
    readonly id: string;
    readonly slug: string;
    readonly name: string;
    readonly status: ProductStatus;
  } | null;
  readonly pinnedPost: {
    readonly id: string;
    readonly ordering: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment" | "EditPostButtonFragment" | "DeletePostButtonFragment" | "SharePostButtonFragment">;
  readonly " $fragmentType": "PostCardFragment";
};
export type PostCardFragment$key = {
  readonly " $data"?: PostCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostCardFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "usePermissionsFragment"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dashboardId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "feedId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feedId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releasedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Feed",
      "kind": "LinkedField",
      "name": "feed",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentCard_ContentFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FlagContentButtonFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentFlaggingsChipFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useCanFlagContentFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "dashboardId",
          "variableName": "dashboardId"
        },
        {
          "kind": "Variable",
          "name": "feedId",
          "variableName": "feedId"
        }
      ],
      "concreteType": "PinnedPost",
      "kind": "LinkedField",
      "name": "pinnedPost",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ordering",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditPostButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeletePostButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharePostButtonFragment"
    }
  ],
  "type": "Post",
  "abstractKey": null
};
})();

(node as any).hash = "c09581773cfa6da3e43b9116804d7cc9";

export default node;
