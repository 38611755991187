import { ContentType } from "@/content-usage/__generated__/ContentUsageUtils_useNavigateToNextContentUsageFragment.graphql"
import { PostFormState } from "@/content/form/util/contentFormUtil"
import FormStore from "@/core/form/store/FormStore"
import { CreatePostInput } from "@/post/add/__generated__/CreatePostModalMutation.graphql"
import { EditPostInput } from "@/post/edit/__generated__/EditPostModalMutation.graphql"
import { SharePostInput } from "@/post/share/__generated__/SharePostModalMutation.graphql"
import { DiscoSwitch } from "@disco-ui"
import { Grid, useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
interface PostNotificationEmailCtaFormFieldProps extends TestIDProps {
  form: FormStore<(CreatePostInput | EditPostInput | SharePostInput) & PostFormState>
  contentType: ContentType
}

function ContentFormNotificationEmailCtaField({
  form,
  testid = "ContentFormNotificationEmailCtaField",
  contentType,
}: PostNotificationEmailCtaFormFieldProps) {
  const theme = useTheme()

  // If an email isn't being sent, we don't need to display this toggle
  if (!form.state.content.sendNotificationEmail) return null

  return (
    <Grid container style={{ gap: theme.spacing(1) }}>
      <DiscoSwitch
        testid={testid}
        labelPlacement={"end"}
        value={form.state.content.notificationEmailCtaButton}
        checked={form.state.content.notificationEmailCtaButton === "enabled"}
        onChange={(checked) =>
          checked
            ? (form.state.content.notificationEmailCtaButton = "enabled")
            : (form.state.content.notificationEmailCtaButton = "disabled")
        }
        label={`Include a button that links back to the ${contentType}`}
      />
    </Grid>
  )
}

export default observer(ContentFormNotificationEmailCtaField)
