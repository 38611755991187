/**
 * @generated SignedSource<<fa9eb06e4f8dad7ec4f0ff8e5a77384f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentDescriptionSectionFragment$data = {
  readonly id: string;
  readonly richEditorDescriptionContent: string | null;
  readonly type: ContentType;
  readonly " $fragmentSpreads": FragmentRefs<"DiscoEditorMentionsFragment">;
  readonly " $fragmentType": "ContentDescriptionSectionFragment";
};
export type ContentDescriptionSectionFragment$key = {
  readonly " $data"?: ContentDescriptionSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentDescriptionSectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentDescriptionSectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richEditorDescriptionContent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DiscoEditorMentionsFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "82f56b53b73a6d8e839d0df1cb73babf";

export default node;
