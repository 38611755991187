import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import ContentThumbnail from "@/content/detail/ContentThumbnail"
import { ContentThumbnailWithDetails_ContentFragment$key } from "@/content/detail/__generated__/ContentThumbnailWithDetails_ContentFragment.graphql"
import { ContentThumbnailWithDetails_ContentUsageFragment$key } from "@/content/detail/__generated__/ContentThumbnailWithDetails_ContentUsageFragment.graphql"
import ContentUtils from "@/content/util/contentUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText, DiscoTextSkeleton, DiscoTooltip } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ContentThumbnailWithDetailsProps extends TestIDProps {
  contentKey: ContentThumbnailWithDetails_ContentFragment$key
  contentUsageKey?: ContentThumbnailWithDetails_ContentUsageFragment$key | null
  truncateText?: number
  thumbnailWidth?: string
  thumbnailHeight?: string
  variant?: "with-label" | "with-creation-date"
  textAdornment?: React.ReactNode
}

function ContentThumbnailWithDetails({
  testid,
  contentKey,
  contentUsageKey,
  truncateText,
  thumbnailWidth,
  thumbnailHeight,
  variant = "with-label",
  textAdornment,
}: ContentThumbnailWithDetailsProps) {
  const classes = useStyles({ thumbnailWidth, thumbnailHeight })
  const timezone = useUserTimezone()

  const content = useFragment<ContentThumbnailWithDetails_ContentFragment$key>(
    graphql`
      fragment ContentThumbnailWithDetails_ContentFragment on Content {
        id
        type
        label
        name
        creationDatetime
        ...ContentThumbnail_ContentFragment
      }
    `,
    contentKey
  )

  const contentUsage = useFragment<ContentThumbnailWithDetails_ContentUsageFragment$key>(
    graphql`
      fragment ContentThumbnailWithDetails_ContentUsageFragment on ContentUsage {
        id
        dueAt
        entity
      }
    `,
    contentUsageKey || null
  )

  const contentLabel = ContentUtils.useContentLabel({
    content,
    entity: contentUsage?.entity,
  })

  return (
    <div className={classes.container}>
      <div className={classes.thumbnailContainer}>
        <ContentThumbnail contentKey={content} />
      </div>
      <div className={classes.titleContainer}>
        {variant === "with-label" && (
          <div className={classes.labelContainer}>
            <DiscoText
              testid={`${testid}.kind-label`}
              color={"primary.main"}
              variant={"body-xs-600-uppercase"}
              truncateText={truncateText || 1}
            >
              {contentLabel.toUpperCase()}
            </DiscoText>
            {textAdornment}
          </div>
        )}

        <div className={classes.customTitleContainer}>
          {renderTooltip()}
          <DiscoText
            testid={`${testid}.title`}
            variant={"body-md-600"}
            truncateText={truncateText || 2}
          >
            {content.name}
          </DiscoText>
        </div>
        {variant === "with-creation-date" && (
          <DiscoText
            testid={`${testid}.creation-date`}
            color={"text.secondary"}
            variant={"body-xs-500"}
          >
            {`created on ${formatDateWithOptions({
              timeZone: timezone,
            })(new Date(content.creationDatetime))}`}
          </DiscoText>
        )}
      </div>
    </div>
  )

  function renderTooltip() {
    if (!contentUsage?.dueAt) return null

    return (
      <DiscoTooltip
        content={`This ${contentLabel} is due on ${ContentUsageUtils.getFormattedDueDate(
          new Date(contentUsage.dueAt),
          timezone,
          "at"
        )}`}
        placement={"bottom"}
      >
        <span className={classes.calendarIcon} data-testid={`${testid}.due-date`}>
          <DiscoIcon icon={"calendar"} marginRight={0.5} />
        </span>
      </DiscoTooltip>
    )
  }
}

type StyleProps = {
  thumbnailWidth?: string
  thumbnailHeight?: string
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(2),
    gap: theme.spacing(0.5),

    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  customTitleContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    alignItems: "center",
    gap: theme.spacing(0.5),

    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  thumbnailContainer: {
    position: "relative",
    minWidth: ({ thumbnailWidth }: StyleProps) => thumbnailWidth ?? "91px",
    width: ({ thumbnailWidth }: StyleProps) => thumbnailWidth ?? "91px",
    height: ({ thumbnailHeight }: StyleProps) => thumbnailHeight ?? "46px",
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.medium,
    marginRight: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& svg path": {
      color: theme.palette.text.primary,
    },
  },
  calendarIcon: {
    display: "flex",
    alignItems: "center",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    "& > svg": {
      color: theme.palette.groovy.neutral[500],
    },
  },
}))

export function ContentThumbnailWithDetailsSkeleton(
  props: Pick<ContentThumbnailWithDetailsProps, "thumbnailWidth" | "thumbnailHeight">
) {
  const classes = useStyles(props)
  const theme = useTheme()
  return (
    <div className={classes.container}>
      <div className={classes.thumbnailContainer}>
        <Skeleton
          variant={"rect"}
          style={{
            borderRadius: theme.measure.borderRadius.big,
            height: "46px",
            width: "91px",
          }}
        />
      </div>
      <div className={classes.titleContainer}>
        <DiscoTextSkeleton width={125} />

        <div className={classes.customTitleContainer}>
          <DiscoTextSkeleton width={200} />
        </div>
      </div>
    </div>
  )
}

export default ContentThumbnailWithDetails
