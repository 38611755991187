import ROUTE_NAMES from "@/core/route/util/routeNames"
import { useRouteMatch } from "react-router-dom"

function useIsOnContentDetailPage() {
  const match = useRouteMatch()
  return (
    match.path === ROUTE_NAMES.PRODUCT.FEED.POSTS.DETAIL ||
    match.path === ROUTE_NAMES.PRODUCT.RESOURCES.DETAIL
  )
}

export default useIsOnContentDetailPage
