import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import FormStore from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { DiscoButton, DiscoInput } from "@disco-ui"
import useDebounce from "@utils/hook/useDebounce"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import ShareInstanceAppList from "./ShareInstanceAppList"
import {
  AddShareInstanceModal_createInstanceMutation,
  UpdateContentUsageInput,
} from "./__generated__/AddShareInstanceModal_createInstanceMutation.graphql"
import { ShareInstanceFormQuery } from "./__generated__/ShareInstanceFormQuery.graphql"

type ShareInstanceFormState = {
  search?: string
}

export const PRODUCTS_PER_PAGE = 10

interface ShareInstanceFormProps {
  form: FormStore<UpdateContentUsageInput, AddShareInstanceModal_createInstanceMutation>
  onSubmit: () => void
  onClose: () => void
}

function ShareInstanceForm({ form, onSubmit, onClose }: ShareInstanceFormProps) {
  const activeOrganization = useActiveOrganization()!
  const [toolbarState, setToolbarState] = useState<ShareInstanceFormState>({
    search: "",
  })
  const classes = useStyles()

  const [inputText, setInputText] = useState("")

  const debounceSearchTextChange = useDebounce((newSearchText: string) => {
    setToolbarState((prev) => ({ ...prev, search: newSearchText }))
  }, 750)

  const { organization } = useLazyLoadQuery<ShareInstanceFormQuery>(
    graphql`
      query ShareInstanceFormQuery(
        $id: ID!
        $first: Int!
        $after: String
        $search: String
      ) {
        organization: node(id: $id) {
          ... on Organization {
            ...ShareInstanceAppList_PaginationFragment
              @arguments(first: $first, after: $after, search: $search)
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      first: PRODUCTS_PER_PAGE,
      search: toolbarState.search,
    },
    {
      fetchPolicy: "network-only",
    }
  )

  if (!organization) return null

  return (
    <Form
      classes={{
        formFieldsContainer: classes.form,
      }}
      buttons={
        <>
          <DiscoButton
            color={"grey"}
            variant={"outlined"}
            onClick={onClose}
            testid={"AddShareInstanceModal.cancel"}
          >
            {"Maybe Later"}
          </DiscoButton>

          <Form.SubmitButton
            form={form}
            testid={"AddShareInstanceModal.confirm"}
            onClick={onSubmit}
            disabled={!form.isChanged || form.isSubmitting}
          >
            {"Add Share Instance"}
          </Form.SubmitButton>
        </>
      }
    >
      <DiscoInput
        fullWidth
        placeholder={"Search for product or community app..."}
        value={inputText}
        onChange={handleSearchTextChange}
      />
      <ShareInstanceAppList organizationKey={organization} form={form} />
    </Form>
  )

  function handleSearchTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputText(e.target.value)
    debounceSearchTextChange(e.target.value)
  }
}

const useStyles = makeUseStyles((theme) => ({
  form: {
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
  },
}))

export default Relay.withSkeleton({
  component: observer(ShareInstanceForm),
  skeleton: () => null,
})
