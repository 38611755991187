import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { displayGraphQLErrorToast } from "@components/toast/ToastProvider"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { graphql } from "relay-runtime"
import { ControlRemoveDiscoStaffMembershipModalMutation } from "./__generated__/ControlRemoveDiscoStaffMembershipModalMutation.graphql"

interface Props {
  isOpen: boolean
  onClose?: () => void
  onSubmit?: () => void
  organizationMembershipId: GlobalID
}

function ControlRemoveDiscoStaffMembership({
  organizationMembershipId,
  isOpen,
  onClose,
  onSubmit,
}: Props) {
  const commitMutation =
    Relay.useAsyncMutation<ControlRemoveDiscoStaffMembershipModalMutation>(
      graphql`
        mutation ControlRemoveDiscoStaffMembershipModalMutation($id: ID!) {
          controlRemoveDiscoStaffMembership(id: $id) {
            node {
              isDiscoStaff
            }
            errors {
              field
              message
            }
          }
        }
      `
    )

  return (
    <DiscoWarningModal
      testid={`remove_staff`}
      modalContentLabel={"Remove Staff Membership"}
      isOpen={isOpen}
      onClose={onClose}
      title={`Remove Staff Membership`}
      description={`Are you sure you want to remove this user's staff membership to this community?`}
      confirmationButtonProps={{
        onClick: () => {
          runAction()
        },
        children: `Yes, remove`,
      }}
    />
  )

  async function runAction() {
    const res = await commitMutation({
      id: organizationMembershipId,
    })
      .catch((err) => {
        displayGraphQLErrorToast(err)
      })
      .finally(() => {
        if (onSubmit) onSubmit()
        if (onClose) onClose()
      })
    if (!res) return
    if (res.controlRemoveDiscoStaffMembership.errors) {
      displayGraphQLErrorToast(res.controlRemoveDiscoStaffMembership.errors[0])
    }
  }
}

export default ControlRemoveDiscoStaffMembership
