import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { useDrawerContext } from "@/core/context/DrawerContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { RequireOnlyOne } from "@/types/util/RequireOnlyOne"
import styleIf from "@assets/style/util/styleIf"
import {
  DiscoButton,
  DiscoFormControl,
  DiscoIcon,
  DiscoInput,
  DiscoText,
  DiscoTextProps,
} from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"

/**
 *  Unlike other static inline elements this does not take in a fragment key
 *  because there are times we use a string as placeholder for the title, such as
 *  default curriculum item tasks. ie. "Add Events to your Calendar"
 */
interface InlineContentTitleSectionStaticProps {
  name: string
  color?: DiscoTextProps["color"]
}

const InlineContentTitleSectionStatic = ({
  name,
  color,
}: InlineContentTitleSectionStaticProps) => {
  return (
    <DiscoText
      color={color}
      testid={`InlineContentTitleSection.static.name`}
      variant={"heading-md"}
      truncateText={2}
    >
      {name}
    </DiscoText>
  )
}

interface InlineContentTitleSectionFormProps {
  form: ContentFormStore
  placeholder?: string
  onSave?: () => Promise<{ success: boolean }>
  focusOnMount?: boolean
  submitButton?: React.ReactNode
}

const InlineContentTitleSectionForm = observer<InlineContentTitleSectionFormProps>(
  ({ form, focusOnMount, submitButton, ...props }) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const isMobile = useIsMobile()

    const { setHideHeaderActions } = useDrawerContext()

    const [showStatic, setShowStatic] = useState(!focusOnMount)
    const showButtons = (!showStatic || form.isChanged) && Boolean(props.onSave)

    const classes = useStyles({ showButtons, hasError: Boolean(form.errorsByField.name) })

    const placeholder = props.placeholder ?? "Untitled"

    useEffect(() => {
      if (!showStatic && inputRef.current) {
        // Focus input after it is rendered, fix for Chrome
        setTimeout(() => inputRef.current?.focus(), 50)
      }
    }, [showStatic])

    useEffect(() => {
      setHideHeaderActions(showButtons && !isMobile)
    }, [showButtons, isMobile, setHideHeaderActions])

    // Show clickable title when not editing to display the title on multiple lines
    // This is because the input field is a single line
    if (!showButtons && showStatic) {
      const hasName = Boolean(form.state.content!.name)

      return (
        <DiscoFormControl
          className={classes.titleFormControl}
          errorMessages={form.errorsByField.name}
        >
          <DiscoContainerButton
            onClick={handleOnClick}
            className={classes.clickableTitle}
          >
            <DiscoText
              color={hasName ? "text.primary" : "text.disabled"}
              testid={`InlineContentTitleSection.clickable.name`}
              variant={"heading-md"}
              truncateText={2}
            >
              {form.state.content!.name || placeholder}
            </DiscoText>
          </DiscoContainerButton>
        </DiscoFormControl>
      )
    }

    if (!showButtons) return <div className={classes.inputContainer}>{renderInput()}</div>

    return (
      <form
        id={"InlineContentTitleForm"}
        onSubmit={handleSubmit}
        className={classes.inputContainer}
      >
        {renderInput()}
        {renderButtons()}
      </form>
    )

    async function handleSubmit(e: React.FormEvent) {
      e.preventDefault()
      if (!props.onSave) return

      const { success } = await props.onSave()
      if (success) inputRef.current?.blur()
    }

    function renderInput() {
      return (
        <DiscoFormControl
          className={classes.titleFormControl}
          errorMessages={form.errorsByField.name}
        >
          <DiscoInput
            inputRef={inputRef}
            classes={{ root: classes.inputRoot, input: classes.input }}
            data-testid={`InlineContentTitleSection.editable.name`}
            value={form.state.content!.name}
            onChange={(e) => (form.state.content!.name = e.target.value)}
            placeholder={placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </DiscoFormControl>
      )
    }

    function renderButtons() {
      return (
        <>
          {submitButton && !isMobile ? (
            submitButton
          ) : (
            <DiscoButton
              form={"InlineContentTitleForm"}
              type={"submit"}
              shouldDisplaySpinner={form.isSubmitting}
              classes={{ root: classes.button }}
              testid={"InlineContentTitleSection.save"}
            >
              {isMobile ? <DiscoIcon icon={"check"} /> : "Save"}
            </DiscoButton>
          )}
          <DiscoButton
            onClick={handleCancel}
            color={"grey"}
            variant={"outlined"}
            classes={{ root: classes.button }}
            testid={"InlineContentTitleSection.cancel-button"}
          >
            {isMobile ? <DiscoIcon icon={"close"} /> : "Cancel"}
          </DiscoButton>
        </>
      )
    }

    function handleOnClick() {
      setShowStatic(false)
    }

    function handleBlur() {
      setShowStatic(true)
    }

    function handleFocus() {
      setShowStatic(false)
    }

    function handleCancel() {
      form.reset()
      setShowStatic(true)
    }
  }
)

export type InlineContentTitleSectionProps = RequireOnlyOne<
  {
    form?: ContentFormStore
    name?: string
    placeholder?: string
    onSave?: () => Promise<{ success: boolean }>
    focusOnMount?: boolean
    submitButton?: React.ReactNode
  },
  "form" | "name"
>

function InlineContentTitleSection(props: InlineContentTitleSectionProps) {
  if (props.form)
    return (
      <InlineContentTitleSectionForm
        form={props.form}
        placeholder={props.placeholder}
        onSave={props.onSave}
        focusOnMount={props.focusOnMount}
        submitButton={props.submitButton}
      />
    )
  return <InlineContentTitleSectionStatic name={props.name} />
}

type StyleProps = {
  showButtons?: boolean
  hasError?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  inputContainer: {
    height: "42px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
  },
  titleFormControl: {
    marginBottom: 0,

    "& > p": {
      marginTop: 0,
    },
  },
  inputRoot: ({ showButtons }: StyleProps) => ({
    height: "36px",
    borderRadius: theme.measure.borderRadius.medium,
    paddingLeft: "10px",
    backgroundColor: theme.palette.groovy.neutral[100],

    ...styleIf(showButtons, {
      backgroundColor: theme.palette.groovy.neutral[100],
      paddingLeft: "10px",
      border: "2px solid",
      borderColor: theme.palette.groovy.neutral[100],

      "&.Mui-focused": {
        border: "2px solid rbg(47, 118, 255)",
      },
    }),
  }),
  clickableTitle: ({ hasError }: StyleProps) => ({
    transition: "padding-left 0.1s ease-in-out",
    display: "grid",
    alignItems: "start",
    borderRadius: theme.measure.borderRadius.medium,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.75),
    cursor: "text",

    ...styleIf(hasError, {
      border: "2px solid",
      borderColor: theme.palette.error.main,
      paddingLeft: "10px",
    }),

    "&:hover": {
      paddingLeft: "10px",
      backgroundColor: theme.palette.groovy.neutral[100],
    },
  }),
  input: {
    ...theme.typography["body-lg"],
    ...theme.typography.modifiers.fontWeight[600],
  },
  button: {
    height: "36px",

    [theme.breakpoints.down("sm")]: {
      width: "36px",
      padding: theme.spacing(0, 0.5),
    },
  },
}))

export default observer(InlineContentTitleSection)
