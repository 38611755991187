import { SelectedTab } from "@/content-usage/buttons/add-button/AddContentUsageContentOptions"
import GenerateContentWithAI from "@/content/ai/GenerateContentWithAI"
import { GenerateContentWithAISubmitProps } from "@/content/ai/GenerateContentWithAIForm"
import ContentTemplateLists from "@/content/templates/ContentTemplateLists"
import { ContentTemplateKind } from "@/content/util/contentTemplates"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import Relay from "@/relay/relayUtils"
import AiStarsIcon from "@assets/disco/icons/ai/ai-stars.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AITag from "@components/ai/AITag"
import { DiscoIcon, DiscoModal } from "@disco-ui"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"

interface ContentTemplatesModalProps extends TestIDProps {
  onClose: () => void
  templatesToExclude?: ContentTemplateKind[]
}

function ContentTemplatesModal(props: ContentTemplatesModalProps) {
  const { onClose, templatesToExclude, testid = "ContentTemplatesModal" } = props
  const drawer = useGlobalDrawer("adminContent")
  const isMobile = useIsMobile()
  const [selectedTab, setSelectedTab] = useState<Omit<SelectedTab, "existing">>("new")
  const classes = useStyles()
  return (
    <DiscoModal
      {...props}
      isOpen
      onClose={onClose}
      title={"Add Content"}
      modalContentLabel={"Add Content"}
      width={"720px"}
      maxWidth={"90vw"}
      maxHeight={"95vh"}
      testid={`${testid}.modal`}
      disableTransition
      body={
        <>
          <DiscoTabs
            routes={[
              {
                label: isMobile ? "New" : "New Content",
                onClick: () => {
                  setSelectedTab("new")
                },
                testid: "new-tab",
                active: selectedTab === "new",
              },
              {
                label: "Generate",
                onClick: () => {
                  setSelectedTab("generate")
                },
                testid: "generate",
                active: selectedTab === "generate",
                tabClassname:
                  selectedTab === "generate" ? classes.activeGenerateTab : undefined,
                rightContent:
                  selectedTab === "generate" ? (
                    <div className={classes.betaTag}>
                      <AITag name={"BETA"} />
                    </div>
                  ) : undefined,
                leftIcon:
                  selectedTab === "generate" ? (
                    <AiStarsIcon />
                  ) : (
                    <DiscoIcon icon={"stars"} />
                  ),
              },
            ]}
            testid={"AddContentModal.tabs"}
            tabVariant={"default"}
          />
          {selectedTab === "new" ? (
            <ContentTemplateLists
              templatesToExclude={templatesToExclude}
              onTemplateSelect={onTemplateSelect}
            />
          ) : selectedTab === "generate" ? (
            <GenerateContentWithAI onSubmit={onSubmitAiForm} />
          ) : null}
        </>
      }
    />
  )

  function onTemplateSelect(template: ContentTemplateKind) {
    if (template === "ai") {
      setSelectedTab("generate")
      return
    }

    drawer.open({
      contentId: Relay.toGlobalId("Content", uuidv4()),
      contentTemplate: template,
    })
    onClose()
  }

  function onSubmitAiForm({
    prompt,
    referenceUrl,
    referenceEmbeddingSourceIds,
    referenceModuleContentUsageId,
  }: GenerateContentWithAISubmitProps) {
    drawer.open({
      contentTemplate: "ai",
      // Generate a new uuid for the Content so we do not have to navigate
      // to a new drawer after creation
      contentId: Relay.toGlobalId("Content", uuidv4()),
      drawerAIPrompt: prompt,
      drawerAIReferenceUrl: referenceUrl,
      drawerAIReferenceEmbeddingSourceIds: encodeURIComponent(
        JSON.stringify(referenceEmbeddingSourceIds)
      ),
      drawerAIReferenceModuleContentUsageId: referenceModuleContentUsageId,
    })

    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  activeGenerateTab: {
    whiteSpace: "nowrap",
    "&:after": {
      position: "absolute",
      bottom: "0px",
      left: 0,
      width: "100%",
      height: "4px",
      borderRadius: `${theme.measure.borderRadius.default}px ${theme.measure.borderRadius.default}px 0 0`,
      backgroundColor: "transparent",
      backgroundImage: theme.palette.aiGradient.aiDark,
    },
    "& span": {
      color: "unset",
      backgroundImage: theme.palette.aiGradient.aiDark,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      MozBackgroundClip: "text",
      MozTextFillColor: "transparent",
    },
  },
  betaTag: {
    display: "inline-flex",
    marginLeft: theme.spacing(0.5),
  },
}))

export default ContentTemplatesModal
