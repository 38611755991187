/**
 * @generated SignedSource<<d17868ae1e769d67f4dccdd8deca6430>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentFooterSection_ContentUsageFragment$data = {
  readonly id: string;
  readonly contentId: string;
  readonly showComments: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"ContentReactionButton_ContentUsageFragment" | "ContentCommentButton_ContentUsageFragment" | "BookmarkButtonContentUsageFragment">;
  readonly " $fragmentType": "ContentFooterSection_ContentUsageFragment";
};
export type ContentFooterSection_ContentUsageFragment$key = {
  readonly " $data"?: ContentFooterSection_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentFooterSection_ContentUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentFooterSection_ContentUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showComments",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentReactionButton_ContentUsageFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentCommentButton_ContentUsageFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookmarkButtonContentUsageFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "b86b82fa3fe9d723c02f666be9f73899";

export default node;
