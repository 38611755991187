import { InlineContentDrawerMode } from "@/content-usage/drawer/InlineContentDrawerTemplate"
import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import InlineContentHeroButtons from "@/content/inline/hero-section/InlineContentHeroButtons"
import InlineContentHeroSection from "@/content/inline/hero-section/InlineContentHeroSection"
import InlineContentAttachmentsSection from "@/content/inline/InlineContentAttachmentsSection"
import { InlineContentHeroAndProperties_ContentFragment$key } from "@/content/inline/__generated__/InlineContentHeroAndProperties_ContentFragment.graphql"
import { ContentTemplateKind, CONTENT_TEMPLATES } from "@/content/util/contentTemplates"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoSpinner } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

/**
 * This component is responsible for rendering the hero and attachments sections
 * of the inline content drawer. It also renders buttons for adding a cover
 * photo, cover video, and attachment.
 *
 * It handles the logic for determining which sections and buttons to render and what
 * to highlight based on the content type and whether or not the content has a cover
 * photo, cover video, or attachment.
 */

interface InlineContentHeroAndPropertiesProps extends TestIDProps {
  mode: InlineContentDrawerMode
  contentForm: ContentFormStore
  contentKey?: InlineContentHeroAndProperties_ContentFragment$key | null
  contentUsageId?: GlobalID | null
  contentTemplateKind?: ContentTemplateKind | null
  showAttachmentsList?: boolean
  alwaysAllowAttachmentUpload?: boolean
  isLoading?: boolean
}

function InlineContentHeroAndProperties({
  testid = "InlineContentHeroAndProperties",
  mode,
  contentForm,
  contentKey,
  contentUsageId,
  contentTemplateKind,
  showAttachmentsList = true,
  alwaysAllowAttachmentUpload = false,
  isLoading,
}: InlineContentHeroAndPropertiesProps) {
  const templateContentType = contentTemplateKind
    ? CONTENT_TEMPLATES[contentTemplateKind]?.contentType
    : undefined

  const content = useFragment<InlineContentHeroAndProperties_ContentFragment$key>(
    graphql`
      fragment InlineContentHeroAndProperties_ContentFragment on Content {
        id
        name
        type
        attachments {
          edges {
            node {
              id
              name
              mediaUrl
            }
          }
        }
        thumbnailUrl
        coverPhoto
        coverVideo
        coverVideoDurationSeconds
        showComments
        showCoverPlayIcon
        ...InlineContentHeroSectionFragment
        ...InlineContentAttachmentsSectionFragment
        ...InlineContentHeroButtonsFragment
      }
    `,
    contentKey || null
  )

  const hasCoverVideo = Boolean(contentForm.state.content.coverVideo)
  const hasCoverPhoto = Boolean(contentForm.state.content.coverPhoto)
  const hasAttachment = Boolean(contentForm.state.content.attachments?.length)

  const highlightCoverVideo = Boolean(templateContentType === "video")
  const highlightAttachments = Boolean(templateContentType === "attachment")

  if (isLoading) return <DiscoSpinner />

  return (
    <>
      {renderCoverSection()}
      <InlineContentHeroButtons
        contentKey={content}
        form={mode === "read" ? undefined : contentForm}
        templateContentType={templateContentType}
        showAttachmentsList={showAttachmentsList}
        alwaysAllowAttachmentUpload={alwaysAllowAttachmentUpload}
        testid={testid}
      />
    </>
  )

  function renderCoverSection() {
    if (!hasAttachment && highlightAttachments) {
      return <InlineContentAttachmentsSection {...getInlineProps()} isHighlighted />
    }

    if (!hasCoverVideo && !hasCoverPhoto && !highlightCoverVideo) return null

    if (!hasCoverVideo && !hasCoverPhoto && highlightCoverVideo) {
      return <InlineContentHeroSection {...getInlineProps()} coverVideoOnly />
    }

    return <InlineContentHeroSection {...getInlineProps()} />
  }

  function getInlineProps() {
    if (mode === "read") return { contentKey: content!, contentUsageId }
    return { form: contentForm }
  }
}

export default observer(InlineContentHeroAndProperties)
