import { ContentDescriptionSectionFragment$key } from "@/content/detail/sections/__generated__/ContentDescriptionSectionFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import DiscoEditor from "@components/editor/DiscoEditor"
import EditorUtils from "@components/editor/EditorUtils"
import { DiscoTextSkeleton } from "@disco-ui"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  contentKey: ContentDescriptionSectionFragment$key
  contentUsageId?: GlobalID
  testid: string
  className?: string
  disableMediaModal?: boolean
}

function ContentDescriptionSection({
  contentKey,
  contentUsageId,
  testid,
  className,
  disableMediaModal = false,
}: Props) {
  const content = useFragment<ContentDescriptionSectionFragment$key>(
    graphql`
      fragment ContentDescriptionSectionFragment on Content {
        id
        richEditorDescriptionContent
        type
        ...DiscoEditorMentionsFragment
      }
    `,
    contentKey
  )

  if (EditorUtils.isEmpty(content.richEditorDescriptionContent)) return null

  return (
    <div
      className={className}
      data-testid={`${testid}.DescriptionSection`}
      role={"button"}
      tabIndex={0}
    >
      <DiscoEditor
        key={content.richEditorDescriptionContent}
        defaultValue={content.richEditorDescriptionContent}
        disableGutter
        readOnly
        editorUsageData={{ contentId: content.id, contentUsageId }}
        mentionsContentKey={content}
        config={content.type === "post" ? "post" : "content"}
        disableMediaModal={disableMediaModal}
      />
    </div>
  )
}

interface SkeletonProps {
  className?: string
}

export function ContentDescriptionSectionSkeleton({ className }: SkeletonProps) {
  return (
    <div className={className}>
      <DiscoTextSkeleton width={"100%"} />
    </div>
  )
}

export default Relay.withSkeleton({
  component: ContentDescriptionSection,
  skeleton: ContentDescriptionSectionSkeleton,
})
