import { ContentMediaSectionFragment$key } from "@/content/detail/sections/__generated__/ContentMediaSectionFragment.graphql"
import useTrackAssetVideoProgress from "@/content/util/useTrackAssetVideoProgress"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import DiscoVideo from "@disco-ui/video/DiscoVideo"
import DiscoInteractivePlayer from "@disco-ui/video/interactive-player/DiscoInteractivePlayer"
import { Skeleton } from "@material-ui/lab"
import classnames from "classnames"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  contentKey: ContentMediaSectionFragment$key
  contentUsageId?: GlobalID
  testid: string
  className?: string
  showInteractivePlayer?: boolean
}

function ContentMediaSection({
  contentKey,
  contentUsageId,
  testid,
  className,
  showInteractivePlayer,
}: Props) {
  const classes = useStyles()
  const trackAssetVideoProgress = useTrackAssetVideoProgress()

  const content = useFragment<ContentMediaSectionFragment$key>(
    graphql`
      fragment ContentMediaSectionFragment on Content {
        id
        productId
        coverPhoto
        coverVideo
        coverVideoAssetUsage {
          id
          asset {
            id
            ...DiscoVideoFragment
          }
        }
      }
    `,
    contentKey
  )

  if (!content.coverPhoto && !content.coverVideo) return null

  return (
    <div
      className={classnames(classes.container, className)}
      data-testid={`${testid}.MediaSection`}
    >
      {content.coverVideo ? (
        showInteractivePlayer && content.coverVideoAssetUsage?.asset?.id ? (
          <DiscoInteractivePlayer
            // This is used to manually re-render this component when the cover photo is removed
            key={content.coverPhoto + content.coverVideo}
            poster={content.coverPhoto}
            src={content.coverVideo}
            onVideoTimeUpdate={(seconds: number, percent: number) => {
              if (!content.coverVideoAssetUsage) return
              trackAssetVideoProgress({
                input: {
                  assetUsageId: content.coverVideoAssetUsage.id,
                  contentId: content.id,
                  contentUsageId,
                  productId: content.productId!,
                  seconds,
                  percent,
                },
              })
            }}
            assetId={content.coverVideoAssetUsage?.asset?.id}
            radius={"big"}
            maxHeight={"unset"}
            fullWidth
          />
        ) : (
          <DiscoVideo
            // This is used to manually re-render this component when the cover photo is removed
            key={content.coverPhoto + content.coverVideo}
            poster={content.coverPhoto}
            src={content.coverVideo}
            onVideoTimeUpdate={(seconds: number, percent: number) => {
              if (!content.coverVideoAssetUsage) return
              trackAssetVideoProgress({
                input: {
                  assetUsageId: content.coverVideoAssetUsage.id,
                  contentId: content.id,
                  contentUsageId,
                  productId: content.productId!,
                  seconds,
                  percent,
                },
              })
            }}
            assetKey={content.coverVideoAssetUsage?.asset}
            radius={"big"}
            maxHeight={"unset"}
            fullWidth
          />
        )
      ) : content.coverPhoto ? (
        <CoverPhoto coverPhoto={content.coverPhoto} />
      ) : null}
    </div>
  )
}

const useStyles = makeUseStyles({
  container: {
    overflow: "hidden",
  },
})

interface SkeletonProps {
  className?: string
}

export function ContentMediaSectionSkeleton({ className }: SkeletonProps) {
  const classes = useStyles()
  return (
    <div className={classnames(classes.container, className)}>
      <Skeleton width={"100%"} height={"50px"} />
    </div>
  )
}

export default Relay.withSkeleton({
  component: ContentMediaSection,
  skeleton: ContentMediaSectionSkeleton,
})
