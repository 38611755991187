import ContentLabelSelectorDropdown from "@/content-usage/drawer/label/ContentLabelSelectorDropdown"
import QuizEditorBanner from "@/content-usage/drawer/quizzes/QuizEditorBanner"
import {
  ContentType,
  EditContentInput,
} from "@/content-usage/drawer/__generated__/InlineContentDrawerTitleFormMutation.graphql"
import { useGenerateContentWithAI } from "@/content/ai/GenerateContentWithAIProvider"
import GenerateContentWithAIStatusPopup from "@/content/ai/GenerateContentWithAIStatusPopup"
import AdminContentDrawerFooter, {
  ADMIN_CONTENT_DRAWER_FOOTER_HEIGHT,
} from "@/content/drawer/AdminContentDrawerFooter"
import { AdminContentDrawerContent_ContentFragment$key } from "@/content/drawer/__generated__/AdminContentDrawerContent_ContentFragment.graphql"
import { AdminContentDrawerLayout_UpdateContentMutation } from "@/content/drawer/__generated__/AdminContentDrawerLayout_UpdateContentMutation.graphql"
import InlineContentDescriptionSection from "@/content/inline/InlineContentDescriptionSection"
import InlineContentHeroAndProperties from "@/content/inline/InlineContentHeroAndProperties"
import InlineContentTitleSection from "@/content/inline/InlineContentTitleSection"
import ContentUtils from "@/content/util/contentUtils"
import { useDrawerContext } from "@/core/context/DrawerContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import FormStore from "@/core/form/store/FormStore"
import NotFoundPageContent from "@/core/route/component/not-found/NotFoundPageContent"
import { GlobalID } from "@/relay/RelayTypes"
import { CreateWebFormRevisionInput } from "@/web-form/utils/webFormEditorUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { EditorInstance } from "@components/editor/LexicalEditor"
import { EmbedKind } from "@components/editor/plugins/embeds/EmbedNode"
import Form from "@components/form/Form"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import {
  DiscoDrawerHeader,
  DiscoIcon,
  DiscoIconButton,
  DiscoTextSkeleton,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export type AdminContentDrawerMode = "add" | "edit"

export type AdminContentDrawerFormState = {
  contentId: GlobalID
  contentType?: ContentType | null
  mode?: AdminContentDrawerMode
  content: Omit<EditContentInput, "webFormRevision"> & {
    webFormRevision?: CreateWebFormRevisionInput | null
  }
}

export type AdminContentDrawerFormStore = FormStore<
  AdminContentDrawerFormState,
  AdminContentDrawerLayout_UpdateContentMutation
>

interface AdminContentDrawerContentProps extends TestIDProps {
  contentKey?: AdminContentDrawerContent_ContentFragment$key | null
  form: AdminContentDrawerFormStore
  handleSubmit: () => Promise<void>
  mode: AdminContentDrawerMode
  contentType?: ContentType
  contentLabel: string
  defaultEmbedKind?: EmbedKind | null
}

function AdminContentDrawerContent({
  contentKey,
  form,
  handleSubmit,
  mode,
  contentType,
  contentLabel,
  defaultEmbedKind,
  testid = "AdminContentDrawerContent",
}: AdminContentDrawerContentProps) {
  const drawer = useGlobalDrawer("adminContent")
  const isMobile = useIsMobile()

  const content = useFragment<AdminContentDrawerContent_ContentFragment$key>(
    graphql`
      fragment AdminContentDrawerContent_ContentFragment on Content {
        id
        name
        type
        ...DiscoEditorMentionsFragment
        ...InlineContentHeroAndProperties_ContentFragment
        ...usePermissionsFragment
        ...QuizEditorBanner_ContentFragment
      }
    `,
    contentKey || null
  )

  const { openDrawerSidebar } = useDrawerContext()
  const { setUnsavedChanges, handleLeave } = useUnsavedChangesModalContext()

  useEffect(() => {
    setUnsavedChanges(form.isChanged)
  }, [form.isChanged, setUnsavedChanges])

  const hasValidDrawerContent = Boolean(content || drawer.params.contentTemplate)
  const editingContent = Boolean(mode === "edit" && form.isChanged)
  const addingContent = Boolean(mode === "add" && drawer.params.contentTemplate)
  const showFooter = Boolean(editingContent || addingContent)

  const classes = useStyles({ showFooter })

  const {
    coverImageStatus,
    stopGenerating,
    handleDrawerMount,
    showStatusPopup: showGenerationStatus,
    closeStatusPopup,
    titleStatus,
    descriptionStatus,
  } = useGenerateContentWithAI()

  return (
    <>
      <div className={classes.container}>
        <DiscoDrawerHeader
          className={classes.drawerHeader}
          testid={testid}
          overlineTitle={renderOverlineTitle()}
          headerActions={
            <>
              {isMobile && (
                <DiscoIconButton
                  testid={`${testid}.settings`}
                  size={"medium"}
                  onClick={openDrawerSidebar}
                >
                  <DiscoIcon icon={"settings"} />
                </DiscoIconButton>
              )}
            </>
          }
          title={renderTitle()}
          onClose={handleClose}
        />
        <div className={classes.content}>
          <ScrollShadowContainer> {renderTab()}</ScrollShadowContainer>
          {showGenerationStatus && (
            <GenerateContentWithAIStatusPopup
              statuses={[
                {
                  name: "Title",
                  status: titleStatus,
                },

                {
                  name: "Cover Image",
                  status: coverImageStatus,
                },
                {
                  name: "Description",
                  status: descriptionStatus,
                },
              ]}
              onClose={closeStatusPopup}
            />
          )}
        </div>
        {showFooter && <AdminContentDrawerFooter form={form} mode={mode} />}
      </div>
    </>
  )

  function handleClose() {
    handleLeave({
      onLeave: () => {
        stopGenerating()
        drawer.close()
      },
    })
  }

  function renderTab() {
    if (!hasValidDrawerContent) return <NotFoundPageContent />
    return (
      <Form
        id={"AdminContentDrawerForm"}
        testid={`${testid}.${mode}`}
        onSubmit={handleSubmit}
        classes={{ formFieldsContainer: classes.contentContainer }}
      >
        <InlineContentHeroAndProperties
          mode={mode}
          contentForm={form}
          contentTemplateKind={drawer.params.contentTemplate}
          isLoading={coverImageStatus === "loading"}
        />
        <InlineContentDescriptionSection
          contentMentionKey={content}
          form={form}
          defaultEmbedKind={defaultEmbedKind}
          staticSectionClasses={{ root: classes.richEditor }}
          onMount={(editor: EditorInstance) => handleDrawerMount(form, editor, drawer)}
        />
        {ContentUtils.isWebFormContent(contentType) && (
          <QuizEditorBanner
            form={form}
            mode={mode}
            contentLabel={contentLabel}
            contentKey={content}
          />
        )}
      </Form>
    )
  }

  function renderTitle() {
    switch (mode) {
      case "edit":
        return <InlineContentTitleSection form={form} />
      case "add":
        if (!hasValidDrawerContent)
          return <InlineContentTitleSection name={"Content Not Found"} />
        return <InlineContentTitleSection form={form} focusOnMount />
    }
  }

  function renderOverlineTitle() {
    if (!hasValidDrawerContent) return ""
    return <ContentLabelSelectorDropdown form={form} contentLabel={contentLabel} />
  }
}

export function AdminContentDrawerContentSkeleton() {
  const classes = useStyles({ showFooter: false })
  const theme = useTheme()
  const drawer = useGlobalDrawer("adminContent")

  return (
    <div className={classes.container}>
      <DiscoDrawerHeader
        className={classes.drawerHeader}
        title={
          <div>
            <DiscoTextSkeleton variant={"body-xs-600-uppercase"} width={100} />
            <DiscoTextSkeleton variant={"heading-lg"} width={250} />
          </div>
        }
        onClose={drawer.close}
      />
      <ScrollShadowContainer>
        <div className={classes.contentContainer}>
          <Skeleton
            variant={"rect"}
            style={{
              width: "100%",
              height: "260px",
              borderRadius: theme.measure.borderRadius.big,
            }}
          />
          <Skeleton
            variant={"rect"}
            style={{
              width: "100%",
              height: "160px",
              borderRadius: theme.measure.borderRadius.big,
            }}
          />
        </div>
      </ScrollShadowContainer>
    </div>
  )
}

type StyleProps = {
  showFooter: boolean
}

const useStyles = makeUseStyles((theme) => ({
  drawerHeader: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  richEditor: {
    paddingBottom: theme.spacing(2),
  },
  container: {
    height: "100%",
    width: "100%",
    display: "grid",
    position: "relative",
  },
  content: ({ showFooter }: StyleProps) => ({
    height: "100%",
    overflow: "hidden",
    ...styleIf(showFooter, {
      paddingBottom: ADMIN_CONTENT_DRAWER_FOOTER_HEIGHT,
    }),
  }),
  contentContainer: {
    height: "100%",
    width: "100%",
    display: "grid",
    gap: theme.spacing(2.5),
    alignContent: "start",
    maxWidth: "1140px",
    margin: "0 auto",
    padding: theme.spacing(3, 3, 6),

    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(3, 4, 6),
    },

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.5),
    },
  },
}))

export default observer(AdminContentDrawerContent)
