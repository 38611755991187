import ControlRemoveDiscoStaffMembershipModal from "@/control/components/ControlRemoveDiscoStaffMembershipModal"
import { GlobalID } from "@/relay/RelayTypes"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"

interface Props extends OverridableDiscoButtonProps {
  organizationMembershipId: GlobalID
  onSubmit?: () => void
}
function ControlRemoveDiscoStaffMembershipButton({
  organizationMembershipId,
  children,
  onSubmit,
  disabled,
}: Props) {
  return (
    <OverridableDiscoButton
      variant={"outlined"}
      color={"grey"}
      disabled={disabled}
      modal={({ isOpen, onClose }) => {
        if (!isOpen) return null
        return (
          <ControlRemoveDiscoStaffMembershipModal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
            organizationMembershipId={organizationMembershipId}
          />
        )
      }}
    >
      {children}
    </OverridableDiscoButton>
  )
}

export default ControlRemoveDiscoStaffMembershipButton
