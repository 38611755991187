import PostDrawerPageContent from "@/content/post/PostDrawerPageContent"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"

export default function PostDrawer() {
  const drawer = useGlobalDrawer("post")
  const classes = useStyles()

  return (
    <DiscoDrawer
      open={drawer.isOpen}
      size={"large"}
      onClose={drawer.close}
      containerClasses={{ drawerContainer: classes.drawer }}
    >
      {drawer.isOpen && <PostDrawerPageContent />}
    </DiscoDrawer>
  )
}

const useStyles = makeUseStyles({
  drawer: {
    padding: 0,
    display: "grid",
    gridTemplateRows: "1fr max-content",
    overflow: "hidden",
  },
})
