/**
 * @generated SignedSource<<acef4572df0396450b5baeaf17971440>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentFooterSection_ContentFragment$data = {
  readonly id: string;
  readonly showComments: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"ContentReactionButton_ContentFragment" | "ContentCommentButton_ContentFragment" | "BookmarkButtonContentFragment">;
  readonly " $fragmentType": "ContentFooterSection_ContentFragment";
};
export type ContentFooterSection_ContentFragment$key = {
  readonly " $data"?: ContentFooterSection_ContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentFooterSection_ContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentFooterSection_ContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showComments",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentReactionButton_ContentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentCommentButton_ContentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookmarkButtonContentFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "aaa65046d4f1a946cb0dbc0d03717641";

export default node;
