import { MemberCurriculumProgressItem } from "@/product/reports/page/curriculum/tab/MemberCurriculumProgressBarSection"
import Relay from "@/relay/relayUtils"
import { graphql, readInlineData } from "relay-runtime"
import { curriculumUtils__getContentProgress$key } from "./__generated__/curriculumUtils__getContentProgress.graphql"
import { curriculumUtils__getNextCurriculumItem$key } from "./__generated__/curriculumUtils__getNextCurriculumItem.graphql"

export function getNextCurriculumItem(
  drawerContentUsageId: string,
  curriculumKey: curriculumUtils__getNextCurriculumItem$key,
  canManage?: boolean
) {
  const curriculum = readInlineData<curriculumUtils__getNextCurriculumItem$key>(
    graphql`
      fragment curriculumUtils__getNextCurriculumItem on Curriculum @inline {
        id
        modules {
          edges {
            node {
              id
              content {
                id
                children {
                  edges {
                    node {
                      id
                      isLocked
                      content {
                        id
                        systemTaskKind
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    curriculumKey
  )

  const contentModules = Relay.connectionToArray(curriculum?.modules)
  if (!contentModules.length) return

  const usages = contentModules.flatMap((cm) =>
    Relay.connectionToArray(cm.content.children)
  )

  const currUsageIdx = usages.findIndex((cu) => cu.id === drawerContentUsageId)

  function getNextUsage() {
    if (currUsageIdx === -1) return
    // Find the next item that can be opened in drawer.
    const nextNavUsage = usages.find((_, i) => {
      if (i <= currUsageIdx) return false
      return true
    })
    if (!nextNavUsage || (!canManage && nextNavUsage.isLocked)) return

    return nextNavUsage
  }

  const nextUsage = getNextUsage()

  if (!nextUsage || nextUsage.isLocked) return

  return nextUsage
}

export function getContentProgress(
  membershipKey: curriculumUtils__getContentProgress$key
) {
  const membership = readInlineData<curriculumUtils__getContentProgress$key>(
    graphql`
      fragment curriculumUtils__getContentProgress on ProductMembership @inline {
        id
        ...ModuleCurriculumProgressFragment
        curriculumContentCompletions {
          edges {
            node {
              id
              contentUsage {
                id
              }
            }
          }
        }
        product {
          curriculum {
            modules {
              totalCount
              edges {
                node {
                  id
                  content {
                    children {
                      edges {
                        node {
                          id
                          isReleased
                          content {
                            name
                            label
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    membershipKey
  )

  const completedContentUsageIds = Relay.connectionToArray(
    membership?.curriculumContentCompletions
  ).map((cic) => cic.contentUsage.id)

  const modules = Relay.connectionToArray(membership?.product?.curriculum?.modules)

  const contentUsages = modules
    .map((cm) => Relay.connectionToArray(cm.content.children))
    .flat(1)

  const contentsByLabel: Record<
    string,
    { items: MemberCurriculumProgressItem[]; totalCompleted: number }
  > = {}

  contentUsages.forEach((contentUsage) => {
    const { label } = contentUsage.content
    if (!contentsByLabel[label]) {
      contentsByLabel[label] = { items: [], totalCompleted: 0 }
    }

    const isCompleted = completedContentUsageIds.includes(contentUsage.id)

    contentsByLabel[label].items.push({
      name: contentUsage.content.name!,
      completed: isCompleted,
      released: contentUsage.isReleased,
    })

    if (isCompleted) {
      contentsByLabel[label].totalCompleted++
    }
  })

  return contentsByLabel
}
