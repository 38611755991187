/**
 * @generated SignedSource<<36c3776f915068fd1f51c99fad38b422>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "content" | "collection" | "curriculum" | "member_onboarding" | "product_app" | "dashboard_block_content" | "organization" | "content_usage_confirmation" | "%future added value";
export type ProductAppKind = "content" | "link" | "curriculum" | "events" | "members" | "resources" | "slack" | "collection" | "product_link" | "organization_events" | "organization_members" | "chat_channel" | "posts" | "nav_folder" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShareInstanceAppListItemFragment$data = {
  readonly id: string;
  readonly customAppTitle: string | null;
  readonly productId: string | null;
  readonly kind: ProductAppKind;
  readonly badge: {
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  };
  readonly curriculum: {
    readonly modules: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly entity: ContentUsageEntity;
          readonly content: {
            readonly id: string;
            readonly name: string | null;
          };
        };
      }>;
    };
  } | null;
  readonly collection: {
    readonly modules: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly entity: ContentUsageEntity;
          readonly content: {
            readonly id: string;
            readonly name: string | null;
          };
        };
      }>;
    };
  } | null;
  readonly " $fragmentType": "ShareInstanceAppListItemFragment";
};
export type ShareInstanceAppListItemFragment$key = {
  readonly " $data"?: ShareInstanceAppListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShareInstanceAppListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContentUsageNodeConnection",
    "kind": "LinkedField",
    "name": "modules",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentUsageNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentUsage",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShareInstanceAppListItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BadgeFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Curriculum",
      "kind": "LinkedField",
      "name": "curriculum",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Collection",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};
})();

(node as any).hash = "cdbd3c84571f7c992a5fcd8dbac99fb6";

export default node;
