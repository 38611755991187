import { ControlAppContextPreloadedQuery } from "@/control/ControlAppContextQuery"
import ControlAppFlows from "@/control/ControlAppFlows"
import { ControlAppContextQuery } from "@/control/__generated__/ControlAppContextQuery.graphql"
import ControlAuthUserProvider from "@/control/context/ControlAuthUserContext"
import ControlContextProviders from "@/control/context/ControlContextProviders"
import ControlAppPageLayout from "@/control/page/ControlAppPageLayout"
import LoadingPage from "@components/loading-page/LoadingPage"
import ScrollToTop from "@components/scroll-to-top/ScrollToTop"
import { WindowSizeProvider } from "@components/window-size/WindowSize"
import { SnackbarProvider } from "notistack"
import { Suspense, useEffect } from "react"
import { HelmetProvider } from "react-helmet-async"
import { useQueryLoader } from "react-relay"

export default function ControlAppContext() {
  const [queryReference, loadQuery] = useQueryLoader<ControlAppContextQuery>(
    ControlAppContextPreloadedQuery
  )
  useEffect(() => {
    loadQuery({})
  }, [loadQuery])
  return (
    <Suspense fallback={<LoadingPage />}>
      {queryReference != null && (
        <ControlContextProviders
          providers={[
            {
              Component: ControlAuthUserProvider,
              props: { queryRef: queryReference },
            },
            HelmetProvider,
            SnackbarProvider,
            ScrollToTop,
            WindowSizeProvider,
            ControlAppPageLayout,
          ]}
        >
          <ControlAppFlows />
        </ControlContextProviders>
      )}
    </Suspense>
  )
}
