import { AiApiGeneratePostTemplate } from "@/common/AiApi"
import FormStore from "@/core/form/store/FormStore"
import { CreateContentInput } from "@/post/add/__generated__/CreatePostModalMutation.graphql"
import { FeedSelection } from "@/post/FeedSelectorDropdown"
import EditorUtils from "@components/editor/EditorUtils"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { observable } from "mobx"

export type ContentFormState = {
  content: CreateContentInput
  isScheduled?: boolean
  step?: "default" | "schedule"
}

export type PostFormState = ContentFormState & {
  selectedFeed: FeedSelection
  aiTemplate?: AiApiGeneratePostTemplate
}

export type ContentFormMoveStepOptions = {
  formValidator?: () => boolean
  updateCallback?: VoidFunction
}

export type ContentFormStore = FormStore<ContentFormState>
export default class ContentFormUtil {
  static areNotificationFieldsEmpty(form: ContentFormStore) {
    if (!form.state.content.notificationEmailSubject) return true
    if (EditorUtils.isEmpty(form.state.content.richEditorNotificationEmailBodyContent))
      return true
    return false
  }

  static handleCreateAttachment(form: ContentFormStore, result: MediaResult) {
    form.state.content.attachments = form.state.content.attachments || observable.array()
    form.state.content.attachments.push({
      name: result.name,
      mediaUrl: result.url,
      mediaAssetId: result.id,
    })
  }

  static moveToScheduleStep(
    form: ContentFormStore,
    { formValidator, updateCallback }: ContentFormMoveStepOptions | undefined = {}
  ) {
    if (formValidator) {
      const isValid = formValidator()
      if (!isValid) return
    }
    updateCallback?.()
    form.state.isScheduled = true
    form.state.step = "schedule"
  }

  static moveToDefaultStep(
    form: ContentFormStore,
    { formValidator, updateCallback }: ContentFormMoveStepOptions | undefined = {}
  ) {
    if (formValidator) {
      const isValid = formValidator()
      if (!isValid) return
    }
    updateCallback?.()
    form.state.step = "default"
    form.state.isScheduled = false
  }
}
