import CONTROL_ROUTE_NAMES from "@/control/route/util/controlRouteNames"
import controlLogo from "@/core/ui/images/control/control-logo.png"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { DiscoSideBar, DiscoSideBarItem } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import classnames from "classnames"
function ControlAppSideBar() {
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles({
    hideWithDisplay: { stylesWhenShown: { display: "flex" } },
  })

  return (
    <DiscoSideBar
      fullHeight
      header={
        <DiscoImage
          className={classes.logo}
          src={controlLogo}
          alt={`Y r u reading this`}
        />
      }
      items={
        <div className={classes.items}>
          <DiscoSideBarItem
            className={classnames(showOnHoverClasses.hoverable)}
            to={CONTROL_ROUTE_NAMES.ROOT}
            name={"Home"}
          />
          <DiscoSideBarItem
            className={classnames(showOnHoverClasses.hoverable)}
            to={CONTROL_ROUTE_NAMES.COMMUNITIES.ROOT}
            name={"Communities"}
          />
          <DiscoSideBarItem
            className={classnames(showOnHoverClasses.hoverable)}
            to={CONTROL_ROUTE_NAMES.USERS.ROOT}
            name={"Users"}
          />
        </div>
      }
    />
  )
}

export default ControlAppSideBar

const useStyles = makeUseStyles((theme) => ({
  items: {
    padding: theme.spacing(2),
  },
  logo: {
    display: "block",
  },
}))
