import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { TestIDProps } from "@utils/typeUtils"
import { ReactNode } from "react"

interface InlineContentPropertiesProps extends TestIDProps {
  isForm?: boolean
  children: ReactNode
}

function InlineContentProperties({ children, isForm }: InlineContentPropertiesProps) {
  const classes = useStyles({ isForm: Boolean(isForm) })

  return <div className={classes.container}>{children}</div>
}

type StyleProps = {
  isForm: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ isForm }: StyleProps) => ({
    display: "grid",
    alignContent: "start",
    gap: theme.spacing(1),

    ...styleIf(isForm, {
      [theme.breakpoints.up("sm")]: {
        // Emulate RichTextEditor gutter
        padding: "0 54px",
      },
    }),
  }),
}))

export default InlineContentProperties
