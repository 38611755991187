/**
 * @generated SignedSource<<329d394b45ca133dd5a9bfe140fd00b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContentNotificationEmailCtaButton = "enabled" | "disabled" | "%future added value";
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
export type SendPreviewEmailInput = {
  title?: string | null;
  body?: string | null;
  productId?: string | null;
  richEditorBody?: string | null;
  postTitle?: string | null;
  notificationEmailCtaButton?: ContentNotificationEmailCtaButton | null;
  createdById?: string | null;
  ctaUrl?: string | null;
  organizationId: string;
  contentType: ContentType;
  contentLabelId?: string | null;
};
export type ContentFormPreviewEmailButtonMutation$variables = {
  input: SendPreviewEmailInput;
};
export type ContentFormPreviewEmailButtonMutation$data = {
  readonly sendPreviewEmail: boolean | null;
};
export type ContentFormPreviewEmailButtonMutation = {
  variables: ContentFormPreviewEmailButtonMutation$variables;
  response: ContentFormPreviewEmailButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "sendPreviewEmail",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentFormPreviewEmailButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContentFormPreviewEmailButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d273ecb64aaccd948b28125a57251094",
    "id": null,
    "metadata": {},
    "name": "ContentFormPreviewEmailButtonMutation",
    "operationKind": "mutation",
    "text": "mutation ContentFormPreviewEmailButtonMutation(\n  $input: SendPreviewEmailInput!\n) {\n  sendPreviewEmail(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "326c6e5421f92fba7fdeae790ee1cec6";

export default node;
