import ChatBotAvatar from "@/admin/integrations/chat-bot/ChatBotAvatar"
import { AppIconKind } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON } from "@/apps/util/appConstants"
import {
  ContentSystemTaskKind,
  ContentType,
} from "@/content-usage/drawer/__generated__/InlineContentDrawerTitleFormMutation.graphql"
import AssignmentContentIcon from "@/core/ui/images/content-templates/assignment-content.svg"
import AttachmentContentIcon from "@/core/ui/images/content-templates/attachment-content.svg"
import CompleteProfileContentIcon from "@/core/ui/images/content-templates/complete-profile-content.svg"
import ConnectSlackCoverPhoto from "@/core/ui/images/content-templates/connect-slack-cover-photo.png"
import ConnectSlackThumbnail from "@/core/ui/images/content-templates/connect-slack-thumbnail-photo.png"
import EmbedContentIcon from "@/core/ui/images/content-templates/embed-content.svg"
import EventRSVPContentIcon from "@/core/ui/images/content-templates/event-rsvp-content.svg"
import QuizContentIcon from "@/core/ui/images/content-templates/quiz-content.svg"
import SurveyContentIcon from "@/core/ui/images/content-templates/survey-content.svg"
import TextContentIcon from "@/core/ui/images/content-templates/text-content.svg"
import VideoContentIcon from "@/core/ui/images/content-templates/video-content.svg"
import SlackIcon from "@/core/ui/images/logos/slack-icon-with-background_40x40.svg"
import ExternalDiscoTextButton from "@components/buttons/ExternalDiscoTextButton"
import EditorUtils from "@components/editor/EditorUtils"
import { EmbedKind } from "@components/editor/plugins/embeds/EmbedNode"
import { DiscoText, DiscoTooltipProps } from "@disco-ui"
import { Entitlement } from "@utils/hook/__generated__/useHasEntitlementActiveOrganizationFragment.graphql"
import { ReactNode } from "react"

export type ContentTemplateKind =
  | "text"
  | "video"
  | "attachment"
  | "custom_embed"
  | "assignment"
  | "scorm"
  | "airtable"
  | "discord"
  | "figma"
  | "google_docs"
  | "google_forms"
  | "miro"
  | "typeform"
  | "whereby"
  | "quiz"
  | "connect_slack"
  | "ai"
  | "survey"
  | "complete_profile"
  | "add_events_to_calendar"

export const CURRICULUM_SYSTEM_TASKS: ContentTemplateKind[] = [
  "complete_profile",
  "add_events_to_calendar",
  "connect_slack",
]

export const CONTENT_TEMPLATES: Record<
  ContentTemplateKind,
  {
    title: string
    description?: string
    icon: React.FC<React.SVGProps<SVGSVGElement>> | AppIconKind
    contentType: ContentType
    entitlement?: Entitlement
    systemTaskKind?: ContentSystemTaskKind | null
    disabledTooltip?: ReactNode
    disabledTooltipProps?: Partial<DiscoTooltipProps>
  }
> = {
  text: {
    title: "Text",
    description: "Create text-based content with links and images.",
    icon: TextContentIcon,
    contentType: "text",
  },
  video: {
    title: "Video",
    description: "Deliver video content in a variety of formats.",
    icon: VideoContentIcon,
    contentType: "video",
  },
  ai: {
    title: "AI",
    description: "Harness the power of Disco's AI to create engaging content.",
    icon: () => {
      return <ChatBotAvatar showBorder />
    },
    contentType: "text",
  },
  attachment: {
    title: "Attachment",
    description: "Attach relevant documents for easy access.",
    icon: AttachmentContentIcon,
    contentType: "attachment",
  },
  custom_embed: {
    title: "Custom Embed",
    description: "Embed custom HTML code, such as an iframe.",
    icon: EmbedContentIcon,
    contentType: "embed",
  },
  assignment: {
    title: "Assignment",
    description: "Prompt members to complete a project or assignment.",
    icon: AssignmentContentIcon,
    contentType: "assignment",
  },
  scorm: {
    title: "SCORM",
    icon: APP_KIND_ICON.scorm,
    contentType: "embed",
    entitlement: "scorm",
  },
  quiz: {
    title: "Quiz",
    description: "Evaluate members with a variety of question types.",
    icon: QuizContentIcon,
    contentType: "quiz",
    entitlement: "quizzes",
  },
  discord: {
    title: "Discord",
    icon: APP_KIND_ICON.discord,
    contentType: "embed",
  },
  airtable: {
    title: "Airtable",
    icon: APP_KIND_ICON.airtable,
    contentType: "embed",
  },
  google_docs: {
    title: "Google Doc",
    icon: APP_KIND_ICON.google_doc,
    contentType: "embed",
  },
  google_forms: {
    title: "Google Form",
    icon: APP_KIND_ICON.google_form,
    contentType: "embed",
  },
  typeform: {
    title: "Typeform",
    icon: APP_KIND_ICON.typeform,
    contentType: "embed",
  },
  figma: {
    title: "Figma",
    icon: APP_KIND_ICON.figma,
    contentType: "embed",
  },
  miro: {
    title: "Miro",
    icon: APP_KIND_ICON.miro,
    contentType: "embed",
  },
  whereby: {
    title: "Whereby",
    icon: APP_KIND_ICON.whereby,
    contentType: "embed",
  },
  connect_slack: {
    title: "Connect Slack",
    icon: SlackIcon,
    contentType: "custom",
    description: "Prompt members to join and connect to a Slack workspace.",
    systemTaskKind: "connect_slack",
    disabledTooltip: (
      <>
        <DiscoText variant={"body-xs-600"}>
          {"Please connect to Slack to create this content."}
        </DiscoText>
        <ExternalDiscoTextButton
          to={
            "https://support.disco.co/hc/en-us/articles/4407643480852-Slack-Integration"
          }
          title={"Learn More"}
          iconPosition={"right"}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        />
      </>
    ),
    disabledTooltipProps: {
      interactive: true,
    },
  },
  survey: {
    title: "Survey",
    description: "Collect insights from members with a variety of question types.",
    icon: SurveyContentIcon,
    contentType: "survey",
  },
  complete_profile: {
    title: "Complete Profile",
    description: "Prompt members to complete their profile.",
    icon: CompleteProfileContentIcon,
    contentType: "custom",
    systemTaskKind: "complete_profile",
  },
  add_events_to_calendar: {
    title: "Event RSVP",
    description: "Prompt members to add events to their calendar.",
    icon: EventRSVPContentIcon,
    contentType: "custom",
    systemTaskKind: "add_events_to_calendar",
    disabledTooltip: "Please add an Events app to create this content.",
  },
}

export function getContentTemplateDefaultCoverPhoto(
  contentTemplate: ContentTemplateKind
) {
  switch (contentTemplate) {
    case "connect_slack":
      return ConnectSlackCoverPhoto
    default:
      return null
  }
}

export function getContentTemplateDefaultThumbnail(contentTemplate: ContentTemplateKind) {
  switch (contentTemplate) {
    case "connect_slack":
      return ConnectSlackThumbnail
    default:
      return null
  }
}

export function getContentTemplateDefaultEmbedKind(
  contentTemplate: ContentTemplateKind
): EmbedKind | null {
  if (
    contentTemplate === "scorm" ||
    contentTemplate === "discord" ||
    contentTemplate === "airtable" ||
    contentTemplate === "google_docs" ||
    contentTemplate === "google_forms" ||
    contentTemplate === "typeform" ||
    contentTemplate === "figma" ||
    contentTemplate === "miro" ||
    contentTemplate === "whereby"
  ) {
    return contentTemplate
  }

  if (contentTemplate === "custom_embed") {
    return "embed_code"
  }

  return null
}

export function getContentTemplateDefaultRichDescription(
  contentTemplate: ContentTemplateKind
) {
  if (contentTemplate === "connect_slack") {
    return JSON.stringify(
      EditorUtils.createParagraphs(["This community uses Slack for discussions."])
    )
  }
  return JSON.stringify(EditorUtils.createParagraphs([""]))
}

export const CONTENT_TEMPLATES_CARDS_ORDER: { template: ContentTemplateKind }[] = [
  { template: "text" },
  { template: "video" },
  { template: "ai" },
  { template: "assignment" },
  { template: "survey" },
  { template: "quiz" },
  { template: "complete_profile" },
  { template: "add_events_to_calendar" },
  { template: "connect_slack" },
]
