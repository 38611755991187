import { BookmarkKind } from "@/bookmarks/__generated__/BookmarkButtonCreateMutation.graphql"
import { ContentCard_ContentFragment$key } from "@/content/detail/__generated__/ContentCard_ContentFragment.graphql"
import { ContentCard_ContentUsageFragment$key } from "@/content/detail/__generated__/ContentCard_ContentUsageFragment.graphql"
import ContentAttachmentsSection, {
  ContentAttachmentsSectionSkeleton,
} from "@/content/detail/sections/ContentAttachmentsSection"
import ContentDescriptionSection, {
  ContentDescriptionSectionSkeleton,
} from "@/content/detail/sections/ContentDescriptionSection"
import ContentFooterSection, {
  ContentFooterSectionSkeleton,
} from "@/content/detail/sections/ContentFooterSection"
import ContentHeaderSection, {
  ContentHeaderSectionSkeleton,
} from "@/content/detail/sections/ContentHeaderSection"
import ContentMediaSection, {
  ContentMediaSectionSkeleton,
} from "@/content/detail/sections/ContentMediaSection"
import ContentTitleSection, {
  ContentTitleSectionSkeleton,
} from "@/content/detail/sections/ContentTitleSection"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { Theme, useMediaQuery } from "@material-ui/core"
import classNames from "classnames"
import { useFragment } from "react-relay"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

export interface ContentCardProps {
  contentKey: ContentCard_ContentFragment$key
  contentUsageKey?: ContentCard_ContentUsageFragment$key
  testid: string
  onClick?: VoidFunction
  onCommentButtonClick?: VoidFunction
  detailPagePath?: string
  banner?: React.ReactNode
  overflow?: React.ReactNode
  headerContent?: React.ReactNode
  titleContent?: React.ReactNode
  headerRightContent?: React.ReactNode
  footerRightContent?: React.ReactNode
  className?: string
  hideHeader?: boolean
  hideDescription?: boolean
  hideMedia?: boolean
  hideAttachments?: boolean
  compact?: boolean
  readOnlyReactionButton?: boolean
  bookmarkKind?: BookmarkKind
  classes?: {
    overflow?: string
    footerContainer?: string
  }
}

function ContentCard({
  contentKey,
  contentUsageKey,
  testid,
  onClick,
  onCommentButtonClick,
  detailPagePath,
  banner,
  overflow,
  headerContent,
  titleContent,
  headerRightContent,
  footerRightContent,
  className,
  hideHeader = false,
  hideDescription = false,
  hideMedia = false,
  hideAttachments = false,
  compact = false,
  readOnlyReactionButton,
  bookmarkKind,
  classes: propsClasses,
}: ContentCardProps) {
  const classes = useStyles()
  const history = useHistory()

  const content = useFragment<ContentCard_ContentFragment$key>(
    graphql`
      fragment ContentCard_ContentFragment on Content {
        id
        ...ContentHeaderSectionFragment
        ...ContentMediaSectionFragment
        ...ContentTitleSectionFragment
        ...ContentDescriptionSectionFragment
        ...ContentAttachmentsSectionFragment
        ...ContentFooterSection_ContentFragment
        ...BookmarkButtonContentFragment
      }
    `,
    contentKey
  )

  const contentUsage = useFragment<ContentCard_ContentUsageFragment$key>(
    graphql`
      fragment ContentCard_ContentUsageFragment on ContentUsage {
        id
        ...ContentFooterSection_ContentUsageFragment
      }
    `,
    contentUsageKey || null
  )

  const isMdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))

  if (!content && !contentUsage) return null

  return (
    <DiscoContainerButton
      className={classNames(classes.container, className)}
      testid={testid}
      onClick={handleGoToDetailPage}
    >
      {hideHeader
        ? null
        : headerContent || (
            <ContentHeaderSection
              classes={{ overflow: propsClasses?.overflow }}
              className={classes.header}
              contentKey={content}
              testid={testid}
              overflow={
                <>
                  <>{overflow}</>
                  <>{headerRightContent}</>
                </>
              }
            />
          )}

      {banner}

      {!hideMedia && (
        <ContentMediaSection
          className={classes.media}
          contentKey={content}
          contentUsageId={contentUsage?.id}
          testid={testid}
        />
      )}

      {titleContent || (
        <ContentTitleSection
          className={classes.title}
          contentKey={content}
          testid={testid}
        />
      )}

      {!hideDescription && (
        <ContentDescriptionSection
          className={classes.description}
          contentKey={content}
          contentUsageId={contentUsage?.id}
          testid={testid}
          disableMediaModal
        />
      )}
      {!hideAttachments && (
        <ContentAttachmentsSection
          className={classes.attachments}
          contentKey={content}
          testid={testid}
        />
      )}

      <div className={classNames(classes.footerContainer, propsClasses?.footerContainer)}>
        <ContentFooterSection
          {...(contentUsage
            ? { contentUsageKey: contentUsage! }
            : { contentKey: content })}
          testid={testid}
          onCommentButtonClick={onCommentButtonClick}
          hideText={compact || isMdScreen}
          readOnlyReactionButton={readOnlyReactionButton}
          bookmarkKind={bookmarkKind}
          hideReactionsList={compact}
          hideBookmarkText
        />
        {footerRightContent}
      </div>
    </DiscoContainerButton>
  )

  function handleGoToDetailPage() {
    if (detailPagePath) {
      history.push(detailPagePath)
    } else if (onClick) {
      onClick()
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.xl,
    border: theme.palette.constants.borderSmallDarkOnly,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    marginBottom: theme.spacing(2.5),
  },
  media: { marginBottom: theme.spacing(2.5) },
  title: { marginBottom: theme.spacing(1) },
  description: { marginBottom: theme.spacing(2.5), flex: "1 1 auto" },
  attachments: { marginBottom: theme.spacing(2.5) },
  footerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flex: "0 1 auto",
  },
}))

export function ContentCardSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <ContentHeaderSectionSkeleton className={classes.header} />
      <ContentMediaSectionSkeleton className={classes.media} />
      <ContentTitleSectionSkeleton className={classes.title} />
      <ContentDescriptionSectionSkeleton className={classes.description} />
      <ContentAttachmentsSectionSkeleton />
      <div className={classes.footerContainer}>
        <ContentFooterSectionSkeleton />
      </div>
    </div>
  )
}

export default Relay.withSkeleton({
  component: ContentCard,
  skeleton: ContentCardSkeleton,
})
