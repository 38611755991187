import DiscoBallIcon from "@/core/ui/images/empty-state/disco-ball.svg"
import FormStore from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoEmptyState, DiscoSection } from "@disco-ui"
import { range } from "lodash"
import { graphql, usePaginationFragment } from "react-relay"
import ShareInstanceAppListItem, {
  ShareInstanceAppListItemSkeleton,
} from "./ShareInstanceAppListItem"
import ShareInstanceProductListItem from "./ShareInstanceProductListItem"
import {
  AddShareInstanceModal_createInstanceMutation,
  UpdateContentUsageInput,
} from "./__generated__/AddShareInstanceModal_createInstanceMutation.graphql"
import { ShareInstanceAppListPaginationQuery } from "./__generated__/ShareInstanceAppListPaginationQuery.graphql"
import { ShareInstanceAppList_PaginationFragment$key } from "./__generated__/ShareInstanceAppList_PaginationFragment.graphql"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { PRODUCTS_PER_PAGE } from "./ShareInstanceForm"

interface ShareInstanceAppListProps {
  organizationKey: ShareInstanceAppList_PaginationFragment$key
  form: FormStore<UpdateContentUsageInput, AddShareInstanceModal_createInstanceMutation>
}

function ShareInstanceAppList({ organizationKey, form }: ShareInstanceAppListProps) {
  const classes = useStyles()

  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
    ShareInstanceAppListPaginationQuery,
    ShareInstanceAppList_PaginationFragment$key
  >(
    graphql`
      fragment ShareInstanceAppList_PaginationFragment on Organization
      @refetchable(queryName: "ShareInstanceAppListPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int!" }
        after: { type: "String" }
        search: { type: "String" }
      ) {
        apps(search: $search, kinds: [collection], includeNested: true) {
          edges {
            node {
              id
              ...ShareInstanceAppListItemFragment
            }
          }
        }
        products(type: "course", search: $search, first: $first, after: $after)
          @connection(key: "ShareInstanceAppsList__products") {
          edges {
            node {
              id
              name
              productApps(kinds: [collection, curriculum], includeNested: true) {
                totalCount
              }
              ...ShareInstanceProductListItemFragment
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organizationKey
  )

  const apps = Relay.connectionToArray(data.apps)
  const products = Relay.connectionToArray(data.products).filter(
    (product) => product.productApps.totalCount
  )
  const isEmpty = !apps.length && !products.length

  return (
    <DiscoSection className={classes.section}>
      {isEmpty && (
        <DiscoEmptyState
          testid={"ShareInstanceAppList.empty-state"}
          title={"No Apps Found"}
          icon={<DiscoBallIcon />}
          align={"center"}
        />
      )}
      {apps.map((app) => (
        <ShareInstanceAppListItem key={app.id} appKey={app} form={form} />
      ))}
      {products.map((product) => (
        <ShareInstanceProductListItem key={product.id} productKey={product} form={form} />
      ))}
      {hasNext && (
        <DiscoScrolledIntoView
          isLoading={isLoadingNext}
          onScrolledIntoView={() => loadNext(PRODUCTS_PER_PAGE)}
        />
      )}
    </DiscoSection>
  )
}

const useStyles = makeUseStyles((theme) => ({
  section: {
    boxShadow: theme.palette.groovyDepths.insideCard,
    padding: theme.spacing(2),
    height: "100%",
  },
}))

function ShareInstanceAppListSkeleton() {
  const classes = useStyles()

  return (
    <DiscoSection className={classes.section}>
      {range(10).map((i) => (
        <ShareInstanceAppListItemSkeleton key={i} />
      ))}
    </DiscoSection>
  )
}

export default Relay.withSkeleton({
  component: ShareInstanceAppList,
  skeleton: ShareInstanceAppListSkeleton,
})
