import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { useAuthUser } from "@/core/context/AuthUserContext"
import MediaLibraryButton, {
  MediaLibraryButtonProps,
} from "@/media/add/MediaLibraryButton"
import { ALL_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"

interface InlineContentCreateAttachmentButtonProps
  extends Omit<
    MediaLibraryButtonProps,
    "onUpload" | "onMediaSelect" | "dropzoneOptions"
  > {
  contentForm: ContentFormStore
}

function InlineContentCreateAttachmentButton({
  contentForm,
  ...props
}: InlineContentCreateAttachmentButtonProps) {
  const { authUser } = useAuthUser({ required: true })

  return (
    <MediaLibraryButton
      {...props}
      onUpload={handleCreateAttachment}
      onMediaSelect={handleCreateAttachment}
      dropzoneOptions={{ accept: ALL_FILE_TYPES }}
    />
  )

  /** Add new attachment to form state */
  function handleCreateAttachment(result: MediaResult) {
    contentForm.state.content = contentForm.state.content || { createdById: authUser.id }
    contentForm.state.content.attachments =
      contentForm.state.content.attachments || observable.array()
    contentForm.state.content.attachments.push({
      name: result.name,
      mediaUrl: result.url,
      mediaAssetId: result.id,
    })
  }
}

export default observer(InlineContentCreateAttachmentButton)
