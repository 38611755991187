import ControlUserRemoveModal from "@/control/components/ControlUserRemoveModal"
import { GlobalID } from "@/relay/RelayTypes"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"

interface Props extends OverridableDiscoButtonProps {
  userId: GlobalID
  onSubmit?: () => void
}
function ControlUserRemoveButton({ userId, children, onSubmit, disabled }: Props) {
  return (
    <OverridableDiscoButton
      variant={"outlined"}
      color={"grey"}
      disabled={disabled}
      modal={({ isOpen, onClose }) => {
        if (!isOpen) return null
        return (
          <ControlUserRemoveModal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
            userId={userId}
          />
        )
      }}
    >
      {children}
    </OverridableDiscoButton>
  )
}

export default ControlUserRemoveButton
