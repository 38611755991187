import Badge from "@/admin/experiences/badges/Badge"
import FormStore from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import useDisclosure from "@utils/hook/useDisclosure"
import { graphql, useFragment } from "react-relay"
import ShareInstanceAppListItem from "./ShareInstanceAppListItem"
import {
  AddShareInstanceModal_createInstanceMutation,
  UpdateContentUsageInput,
} from "./__generated__/AddShareInstanceModal_createInstanceMutation.graphql"
import { ShareInstanceProductListItemFragment$key } from "./__generated__/ShareInstanceProductListItemFragment.graphql"

interface ShareInstanceProductListItemProps {
  productKey: ShareInstanceProductListItemFragment$key
  form: FormStore<UpdateContentUsageInput, AddShareInstanceModal_createInstanceMutation>
}

export default function ShareInstanceProductListItem({
  productKey,
  form,
}: ShareInstanceProductListItemProps) {
  const { isOpen, onToggle } = useDisclosure(false)
  const classes = useStyles()
  const product = useFragment<ShareInstanceProductListItemFragment$key>(
    graphql`
      fragment ShareInstanceProductListItemFragment on Product {
        id
        name
        badge {
          ...BadgeFragment
        }
        productApps(kinds: [collection, curriculum], includeNested: true) {
          edges {
            node {
              id
              ...ShareInstanceAppListItemFragment
            }
          }
        }
      }
    `,
    productKey
  )

  const productApps = Relay.connectionToArray(product.productApps)

  return (
    <>
      <DiscoContainerButton
        className={classes.item}
        onClick={onToggle}
        testid={`ShareInstanceProductListItem.${product.name}`}
      >
        <div className={classes.nameContainer}>
          <Badge badgeKey={product.badge} />
          <DiscoText color={"text.secondary"}>{product.name}</DiscoText>
        </div>
      </DiscoContainerButton>
      <div className={classes.modules}>
        {isOpen &&
          productApps.map((app) => (
            <ShareInstanceAppListItem key={app.id} appKey={app} form={form} />
          ))}
      </div>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  item: {
    padding: theme.spacing(1),
    width: "auto",
    borderRadius: theme.measure.borderRadius.medium,
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[200],
    },
  },
  nameContainer: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  modules: {
    marginLeft: theme.spacing(2.25),
    borderLeft: `2px solid ${theme.palette.groovy.neutral[200]}`,
    paddingLeft: theme.spacing(1),
  },
}))
