import { useControlAuthUser } from "@/control/context/ControlAuthUserContext"
import UnstyledRoute from "@/core/route/unstyled-route/UnstyledRoute"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import MetaTitle from "@/main/page/MetaTitle"
import { Redirect, Route, RouteProps } from "react-router-dom"

type StaffRouteProps = RouteProps & {
  metaTitle?: string
  unstyled?: boolean
}

export default function StaffRoute(props: StaffRouteProps) {
  const { metaTitle, unstyled, ...routeProps } = props

  const { controlAuthUser } = useControlAuthUser()

  return (
    <>
      <MetaTitle>{metaTitle}</MetaTitle>
      {renderRoute()}
    </>
  )

  function renderRoute() {
    // Check for superuser
    if (controlAuthUser?.isStaff) {
      if (unstyled) {
        return <UnstyledRoute {...routeProps} />
      }
      return <Route {...routeProps} />
    }

    return (
      <Redirect
        to={{
          pathname: ROUTE_NAMES.AUTHENTICATION.LOGIN,
          state: { redirectUrl: window.location.href },
        }}
      />
    )
  }
}
