const CONTROL_ROOT_ROUTE = "/" as const
const CONTROL_COMMUNITIES_ROOT_ROUTE = `${CONTROL_ROOT_ROUTE}communities` as const
const CONTROL_USERS_ROOT_ROUTE = `${CONTROL_ROOT_ROUTE}users` as const

const CONTROL_ROUTE_NAMES = {
  ROOT: CONTROL_ROOT_ROUTE,
  COMMUNITIES: {
    ROOT: CONTROL_COMMUNITIES_ROOT_ROUTE,
    DETAIL: `${CONTROL_COMMUNITIES_ROOT_ROUTE}/:id`,
    REDIRECT: `${CONTROL_COMMUNITIES_ROOT_ROUTE}/:id/redirect`,
  },
  USERS: {
    ROOT: CONTROL_USERS_ROOT_ROUTE,
    DETAIL: `${CONTROL_USERS_ROOT_ROUTE}/:id`,
  },
}

export default CONTROL_ROUTE_NAMES

export { CONTROL_COMMUNITIES_ROOT_ROUTE, CONTROL_USERS_ROOT_ROUTE }
