import Badge from "@/admin/experiences/badges/Badge"
import ContentUtils from "@/content/util/contentUtils"
import FormStore from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoText, DiscoTextSkeleton } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { Skeleton } from "@material-ui/lab"
import useDisclosure from "@utils/hook/useDisclosure"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useMemo } from "react"
import { graphql, useFragment } from "react-relay"
import {
  AddShareInstanceModal_createInstanceMutation,
  UpdateContentUsageInput,
} from "./__generated__/AddShareInstanceModal_createInstanceMutation.graphql"
import { ShareInstanceAppListItemFragment$key } from "./__generated__/ShareInstanceAppListItemFragment.graphql"

interface ShareInstanceAppListItemProps {
  appKey: ShareInstanceAppListItemFragment$key
  form: FormStore<UpdateContentUsageInput, AddShareInstanceModal_createInstanceMutation>
}

function ShareInstanceAppListItem({ appKey, form }: ShareInstanceAppListItemProps) {
  const classes = useStyles({ selected: false })
  const { isOpen, onToggle } = useDisclosure(false)

  const app = useFragment<ShareInstanceAppListItemFragment$key>(
    graphql`
      fragment ShareInstanceAppListItemFragment on ProductApp {
        id
        customAppTitle
        productId
        kind
        badge {
          ...BadgeFragment
        }
        curriculum {
          modules {
            edges {
              node {
                id
                entity
                content {
                  id
                  name
                }
              }
            }
          }
        }
        collection {
          modules {
            edges {
              node {
                id
                entity
                content {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    appKey
  )

  const modules = Relay.connectionToArray(
    app.collection?.modules || app.curriculum?.modules
  )
  const contentLabel = ContentUtils.useDefaultContentLabel(form.state.contentType)

  const disabled = useMemo(() => {
    let allowedKinds
    switch (form.state.contentType) {
      case "survey":
      case "quiz":
        allowedKinds = ["curriculum"]
        break
      default:
        allowedKinds = ["collection", "curriculum"]
    }
    return !allowedKinds.includes(app.kind)
  }, [form.state.contentType, app.kind])

  return (
    <>
      <DiscoContainerButton
        className={classes.item}
        onClick={onToggle}
        testid={`ShareInstanceAppListItem.${app.customAppTitle}`}
        disabled={disabled}
        tooltip={
          disabled && `${pluralize(contentLabel)} can't be added to this kind of app`
        }
      >
        <div className={classes.nameContainer}>
          <Badge badgeKey={app.badge} />
          <DiscoText color={"text.secondary"}>{app.customAppTitle}</DiscoText>
        </div>
      </DiscoContainerButton>
      <div className={classes.modules}>
        {isOpen &&
          modules.map((module) => (
            <Item
              key={module.id}
              selected={form.state.contentUsageInput?.entityId === module.content.id}
              onClick={() => selectModule(module.content.id)}
              name={module.content.name!}
            />
          ))}
      </div>
    </>
  )

  function selectModule(moduleId: string) {
    form.state = {
      ...form.state,
      contentUsageInput: {
        ...form.state.contentUsageInput,
        entity: "content",
        entityId: moduleId,
      },
      productId: app.productId,
    }
  }
}

interface ItemProps {
  selected: boolean
  onClick: () => void
  name: string
}

function Item({ selected, onClick, name }: ItemProps) {
  const classes = useStyles({ selected })

  return (
    <DiscoContainerButton
      className={classes.item}
      onClick={onClick}
      testid={`ShareInstanceAppSubListItem.${name}`}
    >
      <DiscoText color={selected ? "common.white" : "text.secondary"}>{name}</DiscoText>
    </DiscoContainerButton>
  )
}

interface StyleProps {
  selected?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  item: ({ selected }: StyleProps) => ({
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.medium,
    "&:hover": {
      backgroundColor: selected
        ? theme.palette.primary.main
        : theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[500]
        : theme.palette.groovy.neutral[200],
    },
    ...styleIf(selected, {
      backgroundColor: theme.palette.primary.main,
    }),
  }),
  nameContainer: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  modules: {
    marginLeft: theme.spacing(2.25),
    borderLeft: `2px solid ${theme.palette.groovy.neutral[200]} `,
    paddingLeft: theme.spacing(1),
  },
  skeletonItem: {
    display: "flex",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    alignItems: "center",
  },
}))

export function ShareInstanceAppListItemSkeleton() {
  const classes = useStyles({ selected: false })

  return (
    <div className={classes.skeletonItem}>
      <Skeleton variant={"rect"} width={24} height={24} />
      <DiscoTextSkeleton variant={"body-md-700"} width={190} />
    </div>
  )
}

export default observer(ShareInstanceAppListItem)
