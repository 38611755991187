import { useTrackAssetVideoProgressMutation } from "@/content/util/__generated__/useTrackAssetVideoProgressMutation.graphql"
import { sendSentryAnException } from "@/core/sentryHandler"
import Relay from "@/relay/relayUtils"
import { useCallback } from "react"
import { graphql } from "relay-runtime"

export default function useTrackAssetVideoProgress() {
  const trackAssetVideoProgress =
    Relay.useAsyncMutation<useTrackAssetVideoProgressMutation>(
      graphql`
        mutation useTrackAssetVideoProgressMutation(
          $input: TrackAssetVideoProgressInput!
        ) {
          response: trackAssetVideoProgress(input: $input) {
            errors {
              field
              message
            }
          }
        }
      `
    )

  return useCallback(
    async (vars: useTrackAssetVideoProgressMutation["variables"]) => {
      await trackAssetVideoProgress(vars).catch((err) => {
        sendSentryAnException(err, {
          extra: {
            title: "useTrackAssetVideoProgress",
          },
        })
      })
    },
    [trackAssetVideoProgress]
  )
}
