/**
 * @generated SignedSource<<3d6cd0441cc4a3ef6ae441d092ab3326>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "content" | "collection" | "curriculum" | "member_onboarding" | "product_app" | "dashboard_block_content" | "organization" | "content_usage_confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentThumbnailWithDetails_ContentUsageFragment$data = {
  readonly id: string;
  readonly dueAt: string | null;
  readonly entity: ContentUsageEntity;
  readonly " $fragmentType": "ContentThumbnailWithDetails_ContentUsageFragment";
};
export type ContentThumbnailWithDetails_ContentUsageFragment$key = {
  readonly " $data"?: ContentThumbnailWithDetails_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentThumbnailWithDetails_ContentUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentThumbnailWithDetails_ContentUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "3de763f13619f620e7b8e0b8d9eaf6de";

export default node;
