import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import MediaLibraryButton, {
  MediaLibraryButtonProps,
} from "@/media/add/MediaLibraryButton"
import { IMAGE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { observer } from "mobx-react-lite"

interface InlineContentAddCoverPhotoButtonProps
  extends Omit<
    MediaLibraryButtonProps,
    | "onUpload"
    | "onMediaSelect"
    | "dropzoneOptions"
    | "allowedFileTypes"
    | "suggestedDimensions"
  > {
  contentForm: ContentFormStore
}

function InlineContentAddCoverPhotoButton({
  contentForm,
  ...props
}: InlineContentAddCoverPhotoButtonProps) {
  return (
    <MediaLibraryButton
      {...props}
      dropzoneOptions={{ accept: IMAGE_FILE_TYPES }}
      allowedFileTypes={["image"]}
      suggestedDimensions={{ width: 1200, height: 600 }}
      message={"Drop or upload a cover image"}
      onUpload={handleAddCoverPhoto}
      onMediaSelect={handleAddCoverPhoto}
    />
  )

  function handleAddCoverPhoto(result: MediaResult) {
    contentForm.state.content.coverPhoto = result.url
    contentForm.state.content.coverPhotoAssetId = result.id
  }
}

export default observer(InlineContentAddCoverPhotoButton)
