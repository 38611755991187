import {
  AdminContentDrawerFormStore,
  AdminContentDrawerMode,
} from "@/content/drawer/AdminContentDrawerContent"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { DiscoButton } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface AdminContentDrawerFooterProps extends TestIDProps {
  form: AdminContentDrawerFormStore
  mode: AdminContentDrawerMode
}

function AdminContentDrawerFooter({
  testid = "AdminContentDrawerFooter",
  form,
  mode,
}: AdminContentDrawerFooterProps) {
  const drawer = useGlobalDrawer("adminContent")
  const { handleLeave } = useUnsavedChangesModalContext()

  const classes = useStyles()

  return (
    <div className={classes.footer}>
      <div className={classes.container} data-testid={`${testid}.container`}>
        <DiscoButton
          color={"grey"}
          variant={"outlined"}
          onClick={handleCancel}
          testid={`${testid}.cancel`}
        >
          {"Cancel"}
        </DiscoButton>
        <Form.SubmitButton
          id={"AdminContentDrawerForm"}
          form={form}
          testid={`${testid}.save`}
        >
          {"Save Changes"}
        </Form.SubmitButton>
      </div>
    </div>
  )

  function handleCancel() {
    if (mode === "add") {
      handleLeave({
        onLeave: drawer.close,
      })
    } else {
      handleLeave({
        onLeave: resetForm,
      })
    }
  }

  function resetForm() {
    if (form) form.reset()
  }
}

export const ADMIN_CONTENT_DRAWER_FOOTER_HEIGHT = "80px"

const useStyles = makeUseStyles((theme) => ({
  footer: {
    borderTop: theme.palette.constants.borderSmall,
    height: ADMIN_CONTENT_DRAWER_FOOTER_HEIGHT,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2.5),
    position: "absolute",
    bottom: 0,
    zIndex: theme.zIndex.raise2,
  },
  container: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    width: "100%",
    justifyContent: "end",
  },
}))

export default observer(AdminContentDrawerFooter)
