/**
 * @generated SignedSource<<8009fe09dc431b64b71b934005982c93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShareInstanceAppListPaginationQuery$variables = {
  after?: string | null;
  first: number;
  search?: string | null;
  id: string;
};
export type ShareInstanceAppListPaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ShareInstanceAppList_PaginationFragment">;
  } | null;
};
export type ShareInstanceAppListPaginationQuery = {
  variables: ShareInstanceAppListPaginationQuery$variables;
  response: ShareInstanceAppListPaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v6 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v7 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "kind": "Literal",
  "name": "includeNested",
  "value": true
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Badge",
  "kind": "LinkedField",
  "name": "badge",
  "plural": false,
  "selections": [
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaUrl",
      "storageKey": null
    },
    (v9/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContentUsageNodeConnection",
    "kind": "LinkedField",
    "name": "modules",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentUsageNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentUsage",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v9/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductAppNodeEdge",
  "kind": "LinkedField",
  "name": "edges",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customAppTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productId",
          "storageKey": null
        },
        (v11/*: any*/),
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Curriculum",
          "kind": "LinkedField",
          "name": "curriculum",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Collection",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  {
    "kind": "Literal",
    "name": "type",
    "value": "course"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ShareInstanceAppListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ShareInstanceAppList_PaginationFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ShareInstanceAppListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  (v10/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "kinds",
                    "value": [
                      "collection"
                    ]
                  },
                  (v7/*: any*/)
                ],
                "concreteType": "ProductAppNodeConnection",
                "kind": "LinkedField",
                "name": "apps",
                "plural": false,
                "selections": [
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v16/*: any*/),
                "concreteType": "ProductNodeConnection",
                "kind": "LinkedField",
                "name": "products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              (v10/*: any*/),
                              {
                                "kind": "Literal",
                                "name": "kinds",
                                "value": [
                                  "collection",
                                  "curriculum"
                                ]
                              }
                            ],
                            "concreteType": "ProductAppNodeConnection",
                            "kind": "LinkedField",
                            "name": "productApps",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalCount",
                                "storageKey": null
                              },
                              (v15/*: any*/)
                            ],
                            "storageKey": "productApps(includeNested:true,kinds:[\"collection\",\"curriculum\"])"
                          },
                          (v12/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfoObjectType",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v16/*: any*/),
                "filters": [
                  "type",
                  "search"
                ],
                "handle": "connection",
                "key": "ShareInstanceAppsList__products",
                "kind": "LinkedHandle",
                "name": "products"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5abc3d81f6f95617e6c49026a5d9edf6",
    "id": null,
    "metadata": {},
    "name": "ShareInstanceAppListPaginationQuery",
    "operationKind": "query",
    "text": "query ShareInstanceAppListPaginationQuery(\n  $after: String\n  $first: Int!\n  $search: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ShareInstanceAppList_PaginationFragment_1Ozsmw\n    id\n  }\n}\n\nfragment BadgeFragment on Badge {\n  kind\n  color\n  icon\n  emoji\n  mediaUrl\n}\n\nfragment ShareInstanceAppListItemFragment on ProductApp {\n  id\n  customAppTitle\n  productId\n  kind\n  badge {\n    ...BadgeFragment\n    id\n  }\n  curriculum {\n    modules {\n      edges {\n        node {\n          id\n          entity\n          content {\n            id\n            name\n          }\n        }\n      }\n    }\n    id\n  }\n  collection {\n    modules {\n      edges {\n        node {\n          id\n          entity\n          content {\n            id\n            name\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ShareInstanceAppList_PaginationFragment_1Ozsmw on Organization {\n  apps(search: $search, kinds: [collection], includeNested: true) {\n    edges {\n      node {\n        id\n        ...ShareInstanceAppListItemFragment\n      }\n    }\n  }\n  products(type: \"course\", search: $search, first: $first, after: $after) {\n    edges {\n      node {\n        id\n        name\n        productApps(kinds: [collection, curriculum], includeNested: true) {\n          totalCount\n        }\n        ...ShareInstanceProductListItemFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n\nfragment ShareInstanceProductListItemFragment on Product {\n  id\n  name\n  badge {\n    ...BadgeFragment\n    id\n  }\n  productApps(kinds: [collection, curriculum], includeNested: true) {\n    edges {\n      node {\n        id\n        ...ShareInstanceAppListItemFragment\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "364b9845defa08e8cd35230d2c338b5a";

export default node;
