import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { InlineContentCoverVideoFormFragment$key } from "@/content/inline/hero-section/__generated__/InlineContentCoverVideoFormFragment.graphql"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import MediaLibraryButton from "@/media/add/MediaLibraryButton"
import Relay from "@/relay/relayUtils"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { IMAGE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoButton } from "@disco-ui"
import DiscoVideo from "@disco-ui/video/DiscoVideo"
import DiscoInteractivePlayer from "@disco-ui/video/interactive-player/DiscoInteractivePlayer"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { SyntheticEvent } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export interface InlineContentCoverVideoFormProps {
  form: ContentFormStore
  classes: { coverContainer: string }
  handleAddImage: (result: MediaResult) => void
  handleRemoveImage: (e: SyntheticEvent) => void
  handleRemoveVideo: (e: SyntheticEvent) => void
  contentKey?: InlineContentCoverVideoFormFragment$key | null
}

function InlineContentCoverVideoForm({
  form,
  classes: propClasses,
  handleAddImage,
  handleRemoveImage,
  handleRemoveVideo,
  contentKey,
}: InlineContentCoverVideoFormProps) {
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()
  const isMobile = useIsMobile()

  const content = useFragment<InlineContentCoverVideoFormFragment$key>(
    graphql`
      fragment InlineContentCoverVideoFormFragment on Content {
        coverVideoAsset {
          id
        }
      }
    `,
    contentKey ?? null
  )

  return (
    <div className={classes.container}>
      <div
        className={classNames(propClasses.coverContainer, showOnHoverClasses.hoverable)}
      >
        {content?.coverVideoAsset?.id || form.state.content.coverVideoAssetId ? (
          <DiscoInteractivePlayer
            testid={`InlineContentCoverVideoForm.interactive-cover-video`}
            maxHeight={"unset"}
            fullWidth
            radius={"big"}
            poster={form.state.content.coverPhoto}
            stackActions
            actionButtons={!isMobile && renderButtons()}
            assetId={
              content?.coverVideoAsset?.id || form.state.content.coverVideoAssetId!
            }
          />
        ) : (
          // Backwards compatibility for old cover video
          <DiscoVideo
            testid={`InlineContentCoverVideoForm.cover-video`}
            maxHeight={"unset"}
            fullWidth
            radius={"big"}
            poster={form.state.content.coverPhoto}
            src={form.state.content.coverVideo}
            stackActions
            actionButtons={!isMobile && renderButtons()}
          />
        )}
      </div>
      {isMobile && renderButtons()}
    </div>
  )

  function renderButtons() {
    return (
      <div
        className={classNames(classes.coverButtonsContainer, showOnHoverClasses.showable)}
      >
        <DiscoButton
          className={classes.button}
          color={"grey"}
          variant={"outlined"}
          leftIcon={isMobile ? undefined : "trash"}
          onClick={handleRemoveVideo}
          testid={`InlineContentCoverVideoForm.remove-video-button`}
        >
          {"Remove Video"}
        </DiscoButton>
        {form.state.content.coverPhoto ? (
          <DiscoButton
            className={classes.button}
            color={"grey"}
            variant={"outlined"}
            leftIcon={isMobile ? undefined : "trash"}
            onClick={handleRemoveImage}
          >
            {"Remove Cover Image"}
          </DiscoButton>
        ) : (
          renderAddImageButton()
        )}
      </div>
    )
  }

  function renderAddImageButton() {
    return (
      <MediaLibraryButton
        onUpload={handleAddImage}
        dropzoneOptions={{ accept: IMAGE_FILE_TYPES }}
        allowedFileTypes={["image"]}
        suggestedDimensions={{ width: 1200, height: 600 }}
        message={"Drop or upload an image"}
        onMediaSelect={handleAddImage}
        PopoverProps={{
          anchorOrigin: {
            vertical: "center",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
      >
        {(btnprops) => (
          <DiscoButton
            {...btnprops}
            color={isMobile ? "grey" : "primary"}
            variant={isMobile ? "outlined" : "contained"}
            className={classes.button}
          >
            {"Add Cover Image"}
          </DiscoButton>
        )}
      </MediaLibraryButton>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    gap: theme.spacing(1.5),
  },
  coverButtonsContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      flexGrow: 1,
    },
  },
}))

export default Relay.withSkeleton({
  component: observer(InlineContentCoverVideoForm),
  skeleton: () => null,
})
