import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import InlineContentAddCoverPhotoButton from "@/content/inline/hero-section/InlineContentAddCoverPhotoButton"
import InlineContentAddCoverVideoButton from "@/content/inline/hero-section/InlineContentAddCoverVideoButton"
import { InlineContentHeroButtonsFragment$key } from "@/content/inline/hero-section/__generated__/InlineContentHeroButtonsFragment.graphql"
import InlineContentAttachmentsSection from "@/content/inline/InlineContentAttachmentsSection"
import InlineContentCreateAttachmentButton from "@/content/inline/InlineContentCreateAttachmentButton"
import InlineContentProperties from "@/content/inline/InlineContentProperties"
import { ContentType } from "@/content/inline/__generated__/InlineContentHeroAndProperties_ContentFragment.graphql"
import useIsWebView from "@/product/util/hook/useIsWebView"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoButton } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"

type Props = TestIDProps & {
  contentKey?: InlineContentHeroButtonsFragment$key | null
  form?: ContentFormStore
  templateContentType?: ContentType
  showAttachmentsList?: boolean
  alwaysAllowAttachmentUpload?: boolean
  hideCoverVideo?: boolean
}

function InlineContentHeroButtons(props: Props) {
  const {
    testid = "InlineContentHeroButtons",
    contentKey,
    form,
    templateContentType,
    showAttachmentsList = true,
    alwaysAllowAttachmentUpload = false,
    hideCoverVideo = false,
  } = props
  const isWebView = useIsWebView()
  const isMobile = useIsMobile()

  const classes = useStyles({ isWebView })
  const theme = useTheme()
  const isDark = theme.palette.type === "dark"

  const content = useFragment<InlineContentHeroButtonsFragment$key>(
    graphql`
      fragment InlineContentHeroButtonsFragment on Content {
        attachments {
          totalCount
        }
        coverPhoto
        coverVideo
        ...InlineContentAttachmentsSectionFragment
      }
    `,
    contentKey || null
  )

  const hasCoverVideo = Boolean(
    form ? form.state.content.coverVideo : content?.coverVideo
  )
  const hasCoverPhoto = Boolean(
    form ? form.state.content.coverPhoto : content?.coverPhoto
  )
  const hasAttachment = Boolean(
    form ? form.state.content.attachments?.length : content?.attachments.totalCount
  )

  return (
    <>
      {renderButtons()}
      {renderAttachmentsSection()}
    </>
  )

  function renderAttachmentsSection() {
    if (!hasAttachment || !showAttachmentsList) return null

    return (
      <InlineContentProperties isForm={Boolean(form)}>
        <InlineContentAttachmentsSection {...getInlineProps()} />
      </InlineContentProperties>
    )
  }

  function getInlineProps() {
    if (form) return { form }
    return { contentKey: content! }
  }

  function renderButtons() {
    if (!form) return null
    const showPhotoButton = !hasCoverPhoto
    const highlightCoverVideo = templateContentType === "video"
    const showVideoButton = !hideCoverVideo && !hasCoverVideo && !highlightCoverVideo
    const highlightAttachments = templateContentType === "attachment"
    const showAttachmentButton =
      (!hasAttachment || alwaysAllowAttachmentUpload) && !highlightAttachments
    if (!showVideoButton && !showPhotoButton && !showAttachmentButton) return null

    return (
      <div className={classes.buttons}>
        {showPhotoButton && (
          <InlineContentAddCoverPhotoButton
            contentForm={form}
            testid={`${testid}.add-cover-photo-button`}
          >
            {(btnProps) => (
              <DiscoButton
                {...btnProps}
                leftIcon={"image"}
                className={classes.button}
                customButtonColor={{
                  color: isDark
                    ? theme.palette.groovy.onDark[200]
                    : theme.palette.groovy.neutral[400],
                  backgroundColor: isWebView
                    ? theme.palette.groovy.neutral[100]
                    : theme.palette.background.paper,
                  hover: {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.groovy.neutral[200],
                  },
                }}
              >
                {isMobile ? "Cover Image" : "Add Cover Image"}
              </DiscoButton>
            )}
          </InlineContentAddCoverPhotoButton>
        )}
        {showVideoButton && (
          <InlineContentAddCoverVideoButton
            contentForm={form}
            testid={`${testid}.add-cover-video-button`}
          >
            {(btnProps) => (
              <DiscoButton
                {...btnProps}
                leftIcon={"video-square"}
                className={classes.button}
                customButtonColor={{
                  color: isDark
                    ? theme.palette.groovy.onDark[200]
                    : theme.palette.groovy.neutral[400],
                  backgroundColor: isWebView
                    ? theme.palette.groovy.neutral[100]
                    : theme.palette.background.paper,
                  hover: {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.groovy.neutral[200],
                  },
                }}
              >
                {isMobile ? "Cover Video" : "Add Cover Video"}
              </DiscoButton>
            )}
          </InlineContentAddCoverVideoButton>
        )}
        {showAttachmentButton && (
          <InlineContentCreateAttachmentButton
            contentForm={form}
            testid={`${testid}.add-attachment-button`}
          >
            {(btnProps) => (
              <DiscoButton
                {...btnProps}
                leftIcon={"paperclip"}
                className={classes.button}
                customButtonColor={{
                  color: isDark
                    ? theme.palette.groovy.onDark[200]
                    : theme.palette.groovy.neutral[400],
                  backgroundColor: isWebView
                    ? theme.palette.groovy.neutral[100]
                    : theme.palette.background.paper,
                  hover: {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.groovy.neutral[200],
                  },
                }}
              >
                {isMobile ? "Attachment" : "Add Attachment"}
              </DiscoButton>
            )}
          </InlineContentCreateAttachmentButton>
        )}
      </div>
    )
  }
}

type StyleProps = {
  isWebView: boolean
}

const useStyles = makeUseStyles((theme) => ({
  buttons: (props: StyleProps) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.5),

    ...styleIf(props.isWebView, {
      flexWrap: "nowrap",
      overflowX: "auto",

      "-ms-overflow-style": "none" /* IE and Edge */,
      "scrollbar-width": "none" /* Firefox */,

      "&::-webkit-scrollbar": {
        display: "none",
      },
    }),
  }),
  button: {
    "& > span": {
      fontSize: "14px",
      fontWeight: 500,
    },
  },
}))

export default observer(InlineContentHeroButtons)
