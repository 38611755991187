import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { InlineContentDescriptionSectionFragment$key } from "@/content/inline/__generated__/InlineContentDescriptionSectionFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import DiscoEditor from "@components/editor/DiscoEditor"
import EditorUtils from "@components/editor/EditorUtils"
import { LexicalChildProps } from "@components/editor/LexicalEditor"
import { EmbedKind } from "@components/editor/plugins/embeds/EmbedNode"
import { DiscoEditorMentionsFragment$key } from "@components/editor/__generated__/DiscoEditorMentionsFragment.graphql"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { RequireOnlyOne } from "stream-chat"

type InlineContentDescriptionSectionStaticProps = {
  contentKey: InlineContentDescriptionSectionFragment$key
  contentUsageId?: GlobalID
  classes?: Partial<ClassNameMap<"root">>
}

const InlineContentDescriptionSectionStatic = ({
  contentKey,
  contentUsageId,
  classes: customClasses,
}: InlineContentDescriptionSectionStaticProps) => {
  const content = useFragment<InlineContentDescriptionSectionFragment$key>(
    graphql`
      fragment InlineContentDescriptionSectionFragment on Content {
        id
        richEditorDescriptionContent
        ...DiscoEditorMentionsFragment
      }
    `,
    contentKey
  )

  if (EditorUtils.isEmpty(content.richEditorDescriptionContent)) return null

  return (
    <DiscoEditor
      testid={"InlineContentDescriptionSectionForm.static.description"}
      defaultValue={content.richEditorDescriptionContent}
      readOnly
      disableGutter
      className={customClasses?.root}
      editorUsageData={{ contentId: content.id, contentUsageId }}
      mentionsContentKey={content}
      config={"content"}
    />
  )
}

type InlineContentDescriptionSectionFormProps = {
  form: ContentFormStore
  placeholder?: string
  classes?: Partial<ClassNameMap<"root">>
  defaultEmbedKind?: EmbedKind | null
  onMount?: LexicalChildProps["onMount"]
  contentMentionKey?: DiscoEditorMentionsFragment$key | null
  moduleContentUsageId?: GlobalID
}

const InlineContentDescriptionSectionForm =
  observer<InlineContentDescriptionSectionFormProps>(
    ({
      form,
      classes: customClasses,
      defaultEmbedKind,
      onMount,
      contentMentionKey,
      moduleContentUsageId,
    }) => {
      return (
        <DiscoEditor
          testid={"InlineContentDescriptionSectionForm.editable.description"}
          defaultValue={form.state.content.richEditorDescriptionContent}
          defaultEmbedKind={defaultEmbedKind}
          onChange={(v) => (form.state.content.richEditorDescriptionContent = v)}
          className={customClasses?.root}
          height={"unset"}
          onMount={onMount}
          mentionsContentKey={contentMentionKey}
          config={"content"}
          editorUsageData={{ moduleContentUsageId }}
        />
      )
    }
  )

type InlineContentDescriptionSectionProps = RequireOnlyOne<
  {
    form?: ContentFormStore
    contentKey?: InlineContentDescriptionSectionFragment$key
    contentMentionKey?: DiscoEditorMentionsFragment$key | null
    contentUsageId?: GlobalID
    placeholder?: string
    staticSectionClasses?: InlineContentDescriptionSectionStaticProps["classes"]
    defaultEmbedKind?: EmbedKind | null
    onMount?: LexicalChildProps["onMount"]
    moduleContentUsageId?: GlobalID
  },
  "form" | "contentKey"
>

function InlineContentDescriptionSection(props: InlineContentDescriptionSectionProps) {
  if (props.form)
    return (
      <InlineContentDescriptionSectionForm
        key={props.form.key}
        form={props.form}
        placeholder={props.placeholder}
        classes={props.staticSectionClasses}
        defaultEmbedKind={props.defaultEmbedKind}
        onMount={props.onMount}
        contentMentionKey={props.contentMentionKey}
        moduleContentUsageId={props.moduleContentUsageId}
      />
    )

  return (
    <InlineContentDescriptionSectionStatic
      contentKey={props.contentKey}
      contentUsageId={props.contentUsageId}
      classes={props.staticSectionClasses}
    />
  )
}

export default observer(InlineContentDescriptionSection)
