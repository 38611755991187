import { useControlAuthUser } from "@/control/context/ControlAuthUserContext"
import ControlNotFoundPageContent from "@/control/route/component/not-found/ControlNotFoundPageContent"
import { getControlRouteComponents } from "@/control/route/controlRoutes"
import { Route, Switch } from "react-router-dom"

export default function ControlAppFlows() {
  useControlAuthUser()

  return (
    <>
      <Switch>
        {[...getControlRouteComponents()]}
        <Route component={ControlNotFoundPageContent} />
      </Switch>
    </>
  )
}
