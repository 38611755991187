import ControlAppContext from "@/control/ControlAppContext"
import RelayEnvironment from "@/relay/RelayEnvironment"
import userConfirmationModal from "@/unsavedChangesRouter/userConfirmationModal"
import { appTheme } from "@assets/style/appMuiTheme"
import ToastProvider from "@components/toast/ToastProvider"
import { DiscoThemeProvider } from "@disco-ui"
import { CssBaseline } from "@material-ui/core"
import { StrictMode } from "react"
import ReactDOM from "react-dom"
import { RelayEnvironmentProvider } from "react-relay"
import { BrowserRouter } from "react-router-dom"

export default function loadControlApp() {
  const rootElement = document.getElementById("root")
  if (!rootElement) return
  const app = <BootstrapControlApp />
  ReactDOM.render(app, rootElement)
}

function BootstrapControlApp() {
  return (
    <StrictMode>
      <DiscoThemeProvider theme={appTheme}>
        <CssBaseline />
        <RelayEnvironmentProvider environment={RelayEnvironment}>
          <BrowserRouter
            basename={"/"}
            getUserConfirmation={(message, callback) => {
              userConfirmationModal(message, callback)
            }}
          >
            <ToastProvider>
              <ControlAppContext />
            </ToastProvider>
          </BrowserRouter>
        </RelayEnvironmentProvider>
      </DiscoThemeProvider>
    </StrictMode>
  )
}
