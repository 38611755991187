import AttachmentListItem from "@/content/attachment/list/item/AttachmentListItem"
import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import InlineContentCreateAttachmentButton from "@/content/inline/InlineContentCreateAttachmentButton"
import InlineContentProperty from "@/content/inline/InlineContentProperty"
import { InlineContentAttachmentsSectionFragment$key } from "@/content/inline/__generated__/InlineContentAttachmentsSectionFragment.graphql"
import { useAuthUser } from "@/core/context/AuthUserContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import { RequireOnlyOne } from "@/types/util/RequireOnlyOne"
import DragDrop from "@components/drag-drop/DragDrop"
import FileDropzone, { ALL_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoButton } from "@disco-ui"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import { DropResult } from "react-beautiful-dnd"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type InlineContentAttachmentsSectionStaticProps = {
  contentKey: InlineContentAttachmentsSectionFragment$key
}

const InlineContentAttachmentsSectionStatic = ({
  contentKey,
}: InlineContentAttachmentsSectionStaticProps) => {
  const content = useFragment<InlineContentAttachmentsSectionFragment$key>(
    graphql`
      fragment InlineContentAttachmentsSectionFragment on Content {
        attachments {
          edges {
            node {
              id
              name
              type
              downloadUrl
              videoUrl
              mediaUrl
            }
          }
        }
      }
    `,
    contentKey
  )

  const classes = useStyles()

  const attachments = Relay.connectionToArray(content.attachments)
  const hasAttachments = attachments.length > 0

  if (!hasAttachments) return null

  return (
    <InlineContentProperty property={{ label: "Attachments", icon: "paperclip" }}>
      <div
        className={classes.attachmentList}
        data-testid={"InlineContentAttachmentsSection.static"}
      >
        {attachments.map((attachment, index) => {
          const { downloadUrl, videoUrl, mediaUrl } = attachment
          const url = downloadUrl || videoUrl || mediaUrl

          return (
            <div key={attachment.id} className={classes.attachmentItem}>
              <AttachmentListItem
                attachment={{ ...attachment, mediaUrl: url }}
                isCompact
                testid={`attachment.${index}`}
              />
            </div>
          )
        })}
      </div>
    </InlineContentProperty>
  )
}

type InlineContentAttachmentsSectionFormProps = {
  form: ContentFormStore
  isHighlighted?: boolean
}

const InlineContentAttachmentsSectionForm =
  observer<InlineContentAttachmentsSectionFormProps>(({ form, isHighlighted }) => {
    const { authUser } = useAuthUser({ required: true })
    const classes = useStyles()

    const attachments = form.state.content?.attachments || []
    const hasAttachments = attachments.length > 0

    if (!hasAttachments && isHighlighted) {
      return (
        <FileDropzone
          className={classes.fileDropzone}
          dropzoneOptions={{ accept: ALL_FILE_TYPES }}
          onUpload={handleCreateAttachment}
          onMediaSelect={handleCreateAttachment}
          message={"Drop or upload an attachment"}
          testid={"InlineContentAttachmentsSection.FileDropzone"}
        />
      )
    }

    return (
      <InlineContentProperty property={{ label: "Attachments", icon: "paperclip" }}>
        <div
          className={classes.attachmentsContainer}
          data-testid={"InlineContentAttachmentsSection.editable"}
        >
          {hasAttachments && (
            <DragDrop
              uniqueKey={"attachments"}
              items={attachments.map((attachment) => ({
                id: attachment.mediaUrl!,
                name: attachment.name,
                mediaUrl: attachment.mediaUrl,
              }))}
              classes={{
                list: classes.attachmentList,
                draggable: classes.attachmentItem,
              }}
              onDragEnd={(result) => handleDragEnd(result)}
            >
              {(attachment, _testid, index, isDragging) => {
                const { downloadUrl, videoUrl, mediaUrl } = attachment
                const url = downloadUrl || videoUrl || mediaUrl

                return (
                  <AttachmentListItem
                    testid={`attachment.${index}`}
                    showDragIcon
                    attachment={{ ...attachment, mediaUrl: url }}
                    onClickDelete={handleDeleteAttachment}
                    isDragging={isDragging}
                    isCompact
                  />
                )
              }}
            </DragDrop>
          )}
          <InlineContentCreateAttachmentButton
            testid={"InlineContentAttachmentsSection.add-button"}
            contentForm={form}
            PopoverProps={{
              anchorOrigin: {
                vertical: "center",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }}
          >
            {(btnprops) => (
              <DiscoButton
                {...btnprops}
                color={"grey"}
                variant={"text"}
                className={classes.addAttachmentButton}
              >
                {"Add Attachment"}
              </DiscoButton>
            )}
          </InlineContentCreateAttachmentButton>
        </div>
      </InlineContentProperty>
    )

    /** Reorder attachments in form state */
    function handleDragEnd(result: DropResult) {
      if (!form) throw new Error("Called handleDragEnd when form not provided")

      if (!result.destination) return

      form.state.content = form.state.content || { createdById: authUser.id }

      const fromIndex = result.source.index
      const toIndex = result.destination.index

      form.state.content.attachments =
        form.state.content.attachments || observable.array()
      const [removed] = form.state.content.attachments.splice(fromIndex, 1)
      form.state.content.attachments.splice(toIndex, 0, removed)
    }

    /** Remove the attachment from form!.state */
    function handleDeleteAttachment(attachment: { mediaUrl?: string | null }) {
      if (!form) throw new Error("Called handleDeleteAttachment when form not provided")

      form.state.content = form.state.content || { createdById: authUser.id }
      form.state.content.attachments =
        form.state.content.attachments || observable.array()

      const removeIndex = form.state.content.attachments.findIndex(
        (a) => a.mediaUrl === attachment.mediaUrl
      )
      if (removeIndex >= 0) {
        form.state.content.attachments.splice(removeIndex, 1)
      }
    }

    /** Add new attachment to form state */
    function handleCreateAttachment(result: MediaResult) {
      if (!form) throw new Error("Called handleCreateAttachment when form not provided")

      form.state.content = form.state.content || { createdById: authUser.id }
      form.state.content.attachments =
        form.state.content.attachments || observable.array()
      form.state.content.attachments.push({
        name: result.name,
        mediaUrl: result.url,
        mediaAssetId: result.id,
      })
    }
  })

type InlineContentAttachmentsSectionProps = RequireOnlyOne<
  {
    form?: ContentFormStore
    contentKey?: InlineContentAttachmentsSectionFragment$key
    isHighlighted?: boolean
  },
  "form" | "contentKey"
>

const InlineContentAttachmentsSection = (props: InlineContentAttachmentsSectionProps) => {
  if (props.form)
    return (
      <InlineContentAttachmentsSectionForm
        form={props.form}
        isHighlighted={props.isHighlighted}
      />
    )
  return <InlineContentAttachmentsSectionStatic contentKey={props.contentKey} />
}

const useStyles = makeUseStyles((theme) => ({
  fileDropzone: {
    width: "100%",
  },
  attachmentsContainer: {
    display: "grid",
    gap: theme.spacing(1),
  },
  attachmentList: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  attachmentItem: {
    // Necessary to have text truncate properly
    display: "grid",
    "& a": {
      display: "inherit",
    },
  },
  addAttachmentButton: {
    height: "40px",
    padding: theme.spacing(1, 1.5),
    "& span": {
      color: theme.palette.groovy.grey[300],
      width: "100%",
      textAlign: "start",
      fontSize: "14px",
      fontWeight: "normal",
    },
  },
}))

export default observer(InlineContentAttachmentsSection)
