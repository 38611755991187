import AttachmentListItem from "@/content/attachment/list/item/AttachmentListItem"
import { Attachment } from "@/content/attachment/util/contentAttachmentTypes"
import { ContentAttachmentsSectionFragment$key } from "@/content/detail/sections/__generated__/ContentAttachmentsSectionFragment.graphql"
import Relay from "@/relay/relayUtils"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ContentAttachmentsSectionProps {
  contentKey: ContentAttachmentsSectionFragment$key
  testid: string
  className?: string
  isCompact?: boolean
}

function ContentAttachmentsSection({
  contentKey,
  testid,
  className,
  isCompact,
}: ContentAttachmentsSectionProps) {
  const content = useFragment<ContentAttachmentsSectionFragment$key>(
    graphql`
      fragment ContentAttachmentsSectionFragment on Content {
        attachments {
          edges {
            node {
              id
              name
              type
              downloadUrl
              videoUrl
              mediaUrl
            }
          }
        }
      }
    `,
    contentKey
  )
  const attachments = Relay.connectionToArray(content.attachments)

  if (!attachments.length) return null

  return (
    <div className={className} data-testid={`${testid}.AttachmentsSection`}>
      {attachments
        .filter(({ downloadUrl, videoUrl, mediaUrl }) =>
          Boolean(downloadUrl || videoUrl || mediaUrl)
        )
        .map(({ id, name, type, downloadUrl, videoUrl, mediaUrl }, index) => (
          <AttachmentListItem
            key={id}
            attachment={{
              id,
              name,
              type: type as Attachment["type"],
              mediaUrl: downloadUrl || videoUrl || mediaUrl,
            }}
            testid={`ContentAttachmentsSection.attachment-${index}`}
            isCompact={isCompact}
          />
        ))}
    </div>
  )
}

interface SkeletonProps {
  className?: string
}

export function ContentAttachmentsSectionSkeleton({ className }: SkeletonProps) {
  const theme = useTheme()
  return (
    <div className={className} style={{ display: "flex", gap: theme.spacing(1) }}>
      <Skeleton width={"33%"} />
      <Skeleton width={"33%"} />
      <Skeleton width={"33%"} />
    </div>
  )
}

export default Relay.withSkeleton({
  component: ContentAttachmentsSection,
  skeleton: ContentAttachmentsSectionSkeleton,
})
