import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import { ContentThumbnail_ContentFragment$key } from "@/content/detail/__generated__/ContentThumbnail_ContentFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ContentThumbnailProps extends TestIDProps {
  contentKey: ContentThumbnail_ContentFragment$key
}

function ContentThumbnail({ contentKey }: ContentThumbnailProps) {
  const classes = useStyles()

  const content = useFragment<ContentThumbnail_ContentFragment$key>(
    graphql`
      fragment ContentThumbnail_ContentFragment on Content {
        id
        type
        coverVideo
        thumbnailUrl
        showCoverPlayIcon
      }
    `,
    contentKey
  )

  if (!content.thumbnailUrl) return ContentUsageUtils.getContentEmptyThumbnail(content)

  return (
    <CoverPhoto
      customClassName={classes.thumbnail}
      coverPhoto={content.thumbnailUrl}
      showCoverPlayIcon={
        Boolean(content.showCoverPlayIcon) && Boolean(content.coverVideo)
      }
    />
  )
}
const useStyles = makeUseStyles({
  thumbnail: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
})

export default ContentThumbnail
