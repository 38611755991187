import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { LocationState } from "@/core/route/util/routeUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoModal, DiscoText } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { setSearchParams } from "@utils/url/urlUtils"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { ConnectionHandler, graphql, useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory, useLocation } from "react-router-dom"
import ShareInstanceForm from "./ShareInstanceForm"
import { AddShareInstanceModalQuery } from "./__generated__/AddShareInstanceModalQuery.graphql"
import {
  AddShareInstanceModal_createInstanceMutation,
  ContentUsageEntity,
  UpdateContentUsageInput,
} from "./__generated__/AddShareInstanceModal_createInstanceMutation.graphql"

type ShareInstanceState = "share" | "warn"

interface AddShareInstanceModalProps {
  contentId: GlobalID
  isOpen: boolean
  initialState?: ShareInstanceState
  onClose: () => void
}

function AddShareInstanceModal({
  contentId,
  isOpen,
  onClose,
}: AddShareInstanceModalProps) {
  const activeOrganization = useActiveOrganization()!
  const drawer = useGlobalDrawer("adminContent")
  const classes = useStyles()
  const history = useHistory()
  const [step, setStep] = useState<ShareInstanceState>("share")
  const { handleLeave } = useUnsavedChangesModalContext()

  const { content } = useLazyLoadQuery<AddShareInstanceModalQuery>(
    graphql`
      query AddShareInstanceModalQuery($id: ID!) {
        content: node(id: $id) {
          ... on Content {
            id
            type
            usages {
              totalCount
            }
          }
        }
      }
    `,
    {
      id: contentId,
    }
  )

  const createInstanceForm = useFormStore<
    AddShareInstanceModal_createInstanceMutation,
    UpdateContentUsageInput
  >(
    graphql`
      mutation AddShareInstanceModal_createInstanceMutation(
        $input: UpdateContentUsageInput!
        $connections: [ID!]!
      ) {
        response: updateContentUsage(input: $input) {
          node
            @prependNode(
              connections: $connections
              edgeTypeName: "ContentUsageNodeEdge"
            ) {
            id
            entityId
            entity
            product {
              slug
            }
            module {
              usages {
                edges {
                  node {
                    id
                    entity
                    entityId
                  }
                }
              }
            }
            content {
              name
              usages {
                edges {
                  node {
                    id
                    ...ContentUsageInstanceItem_ContentUsageFragment
                  }
                }
              }
            }
            ...InlineContentDrawerTemplateFragment
            ...ContentUsageGridItemFragment
            ...ContentUsageInstanceItem_ContentUsageFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      contentId,
      contentType: content?.type,
      contentUsageInput: {},
    }
  )

  useEffect(() => {
    if (step === "warn") setStep("share")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  if (step === "warn")
    return (
      <DiscoWarningModal
        isOpen={isOpen}
        hideIcon
        modalContentLabel={"AddShareModal.warning"}
        testid={"AddShareModal.warning"}
        title={"Hold On!"}
        variant={"primary"}
        classes={{ buttonsWrapper: classes.buttonsWrapper }}
        description={
          <DiscoText color={"groovy.neutral.500"}>
            {
              "This content is saved in your Admin Content library. For members to access, you will need to share an instance in a curriculum, collection, or content app."
            }
          </DiscoText>
        }
        onClose={onClose}
        onCancel={shareInstance}
        cancelButtonText={"Share & Add Instance"}
        confirmationButtonProps={{
          children: "Okay, I Understand",
          onClick: onClose,
        }}
      />
    )

  return (
    <DiscoModal
      testid={"AddShareInstanceModal"}
      title={"Add Instance"}
      height={"600px"}
      maxWidth={"600px"}
      maxHeight={"700px"}
      modalContentLabel={"AddShareInstanceModal"}
      classes={{
        footer: classes.footer,
      }}
      isOpen={isOpen}
      onClose={closeModal}
      body={
        <ShareInstanceForm
          form={createInstanceForm}
          onSubmit={addInstance}
          onClose={closeModal}
        />
      }
      buttons
    />
  )

  function closeModal() {
    if (!content?.usages?.totalCount && step === "share") {
      setStep("warn")
      return
    }
    onClose()
  }

  function shareInstance() {
    setStep("share")
  }

  async function addInstance() {
    const { didSave, response } = await createInstanceForm.submit(
      {
        ...createInstanceForm.state,
        contentUsageInput: {
          // Inherit the default settings for the content type
          ...(content?.type && ContentUsageUtils.getDefaultSettings(content.type)),
          // Overwrite the default settings with the form state
          ...createInstanceForm.state.contentUsageInput,
        },
      },
      {
        connections: [
          ConnectionHandler.getConnectionID(
            contentId,
            "AdminContentLibraryItemUsages__usages"
          ),
          ConnectionHandler.getConnectionID(
            createInstanceForm.state.contentUsageInput?.entityId || "",
            "ContentUsageGrid__children"
          ),
        ],
      }
    )
    if (!didSave || !response?.node) return

    displaySuccessToast({
      message: "Content usage created!",
    })
    onClose()

    const { product, module } = response.node
    const contentModules = Relay.connectionToArray(module?.usages)
    if (!contentModules.length) return
    const contentModule = contentModules[0]

    const path = generateContentUsagePath(
      contentModule.entity,
      contentModule.entityId,
      product?.slug
    )
    handleLeave({
      onLeave: () => {
        drawer.close()
        if (!path) return

        history.push({
          pathname: path,
          search: setSearchParams(location.search, {
            u: Relay.fromGlobalId(response.node!.id).id,
            drawerTab: "settings",
          }),
        })
      },
    })
  }

  function generateContentUsagePath(
    entity: ContentUsageEntity,
    entityId: string,
    productSlug?: string
  ) {
    switch (entity) {
      case "curriculum":
        return generatePath(ROUTE_NAMES.PRODUCT.CURRICULUM.OVERVIEW, {
          productSlug: productSlug || "",
        })
      case "collection":
        if (productSlug)
          return generatePath(ROUTE_NAMES.PRODUCT.COLLECTION.DETAIL, {
            productSlug: productSlug || "",
            collectionId: entityId,
          })
        return generatePath(ROUTE_NAMES.COMMUNITY.COLLECTION.DETAIL, {
          collectionId: entityId,
        })
      default:
        return null
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  footer: {
    padding: theme.spacing(2, 2),
  },
  buttonsWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
}))

/** looks for `openSharePostModal` in location state and renders the modal with the given postId from state value */
export function LocationStateControlledShareInstanceModal() {
  const location = useLocation<LocationState>()
  const history = useHistory()
  if (!location.state?.openShareInstanceModal) return null
  return (
    <AddShareInstanceModal
      isOpen={true}
      contentId={location.state.openShareInstanceModal}
      onClose={() =>
        history.replace({
          ...location,
          state: { ...location.state, openShareInstanceModal: undefined },
        })
      }
    />
  )
}

export default observer(AddShareInstanceModal)
