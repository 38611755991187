import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import MediaLibraryButton, {
  MediaLibraryButtonProps,
} from "@/media/add/MediaLibraryButton"
import { VIDEO_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { observer } from "mobx-react-lite"

interface InlineContentAddCoverVideoButtonProps
  extends Omit<
    MediaLibraryButtonProps,
    | "onUpload"
    | "onMediaSelect"
    | "dropzoneOptions"
    | "allowedFileTypes"
    | "suggestedDimensions"
  > {
  contentForm: ContentFormStore
}

function InlineContentAddCoverVideoButton({
  contentForm,
  ...props
}: InlineContentAddCoverVideoButtonProps) {
  return (
    <MediaLibraryButton
      {...props}
      dropzoneOptions={{ accept: VIDEO_FILE_TYPES }}
      allowedFileTypes={["video"]}
      suggestedDimensions={{ width: 1200, height: 600 }}
      message={"Drop or upload a video"}
      onUpload={handleAddCoverVideo}
      onMediaSelect={handleAddCoverVideo}
    />
  )

  function handleAddCoverVideo(result: MediaResult) {
    contentForm.state.content.coverVideo = result.url
    contentForm.state.content.coverVideoAssetId = result.id
  }
}

export default observer(InlineContentAddCoverVideoButton)
