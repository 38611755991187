import AttachmentListItem from "@/content/attachment/list/item/AttachmentListItem"
import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import MediaLibraryButton from "@/media/add/MediaLibraryButton"
import styleIf from "@assets/style/util/styleIf"
import DragDrop from "@components/drag-drop/DragDrop"
import FileDropzone, { ALL_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoButton, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import classNames from "classnames"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import { DropResult } from "react-beautiful-dnd"

interface Props {
  form: ContentFormStore
  dropzoneMessage?: string
  label?: string | React.ReactNode | null
  hideCreateButton?: boolean
  hideDropzone?: boolean
  isCompact?: boolean
  classes?: {
    attachmentList?: string
    dropzone?: string
  }
}

const ContentFormAttachmentsSection = observer<Props>((props) => {
  const {
    form,
    dropzoneMessage,
    label,
    hideCreateButton,
    hideDropzone,
    isCompact,
    classes: propsClasses,
  } = props

  const attachments = form.state.content?.attachments || []
  const hasAttachments = attachments.length > 0
  const activeProduct = useActiveProduct()

  const classes = useStyles({ isCompact })
  const membersLabel = useLabel(activeProduct ? "product_member" : "organization_member")

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        {label && typeof label === "string" ? (
          <Grid item xs={12} sm={6} className={classes.labelContainer}>
            <DiscoText variant={"body-md-600"}>{label}</DiscoText>
          </Grid>
        ) : (
          label
        )}
        {hasAttachments && !hideCreateButton && (
          <Grid item xs={12} sm={6} className={classes.buttonContainer}>
            <MediaLibraryButton
              onUpload={handleCreateAttachment}
              onMediaSelect={handleCreateAttachment}
              dropzoneOptions={{ accept: ALL_FILE_TYPES }}
              PopoverProps={{
                anchorOrigin: {
                  vertical: "center",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              }}
            >
              {(btnprops) => (
                <DiscoButton
                  {...btnprops}
                  className={classes.uploadExistingMediabutton}
                  variant={"outlined"}
                  color={"grey"}
                >
                  {"Add Attachment"}
                </DiscoButton>
              )}
            </MediaLibraryButton>
          </Grid>
        )}
      </Grid>

      <div className={propsClasses?.attachmentList}>
        {hasAttachments ? (
          <DragDrop
            uniqueKey={"attachments"}
            items={attachments.map((attachment) => ({
              id: attachment.mediaUrl!,
              name: attachment.name,
              mediaUrl: attachment.mediaUrl,
            }))}
            onDragEnd={handleDragEnd}
          >
            {(attachment, _, index) =>
              isCompact ? (
                <div className={classes.attachmentItemWrapper}>
                  <DiscoButton
                    className={classes.propertyTitle}
                    leftIcon={"attachment"}
                    color={"transparent"}
                  >
                    {"Attachment"}
                  </DiscoButton>
                  <AttachmentListItem
                    attachment={attachment}
                    onClickDelete={handleDeleteAttachment}
                    isCompact={isCompact}
                    testid={`ContentFormAttachmentsSection.${index}`}
                  />
                </div>
              ) : (
                <AttachmentListItem
                  attachment={attachment}
                  showDragIcon
                  onClickDelete={handleDeleteAttachment}
                  isCompact={isCompact}
                  testid={`ContentFormAttachmentsSection.${index}`}
                />
              )
            }
          </DragDrop>
        ) : (
          !hideDropzone && (
            <FileDropzone
              dropzoneOptions={{ multiple: false, accept: ALL_FILE_TYPES }}
              onUpload={handleCreateAttachment}
              onMediaSelect={handleCreateAttachment}
              message={
                dropzoneMessage ||
                `Add an additional file for ${membersLabel.plural} to download`
              }
              className={classNames(classes.dropzone, propsClasses?.dropzone)}
              showSupportedFiles={false}
            />
          )
        )}
      </div>
    </div>
  )

  /** Reorder attachments in form state */
  function handleDragEnd(result: DropResult) {
    if (!result.destination) return

    const fromIndex = result.source.index
    const toIndex = result.destination.index

    form.state.content.attachments = form.state.content.attachments || observable.array()
    const [removed] = form.state.content.attachments.splice(fromIndex, 1)
    form.state.content.attachments.splice(toIndex, 0, removed)
  }

  /** Remove the attachment from form.state */
  function handleDeleteAttachment(attachment: { mediaUrl?: string | null }) {
    form.state.content.attachments = form.state.content.attachments || observable.array()

    const removeIndex = form.state.content.attachments.findIndex(
      (a) => a.mediaUrl === attachment.mediaUrl
    )
    if (removeIndex >= 0) {
      form.state.content.attachments.splice(removeIndex, 1)
    }
  }

  /** Add new attachment to form state */
  function handleCreateAttachment(result: MediaResult) {
    form.state.content.attachments = form.state.content.attachments || observable.array()
    form.state.content.attachments.push({
      name: result.name,
      mediaUrl: result.url,
      mediaAssetId: result.id,
    })
  }
})

type StyleProps = {
  isCompact?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ isCompact }: StyleProps) => ({
    ...styleIf(!isCompact, {
      marginBottom: theme.spacing(3),
    }),
  }),
  dropzone: {
    marginTop: theme.spacing(1.5),
  },
  attachmentItemWrapper: {
    display: "grid",
    gridTemplateColumns: "150px 1fr",
    "& a": {
      display: "inherit",
    },
  },
  propertyTitle: {
    "& > span, & svg": {
      color: theme.palette.groovy.grey[300],
    },
    justifyContent: "flex-start",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.groovy.grey[300],
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  uploadExistingMediabutton: {
    width: "auto",
    padding: theme.spacing(0, 3),
  },
}))

export default ContentFormAttachmentsSection
