import ControlUserRemoveButton from "@/control/components/ControlUserRemoveButton"
import { ControlUserDetailPageQuery } from "@/control/page/__generated__/ControlUserDetailPageQuery.graphql"
import CONTROL_ROUTE_NAMES from "@/control/route/util/controlRouteNames"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import BackButton from "@components/back-button/BackButton"
import { DiscoLink, DiscoSection, DiscoText } from "@disco-ui"
import DiscoPage from "@disco-ui/page/DiscoPage"
import { useTheme } from "@material-ui/core/styles"
import { generatePath, useParams } from "react-router-dom"
import { graphql } from "relay-runtime"
import ControlRemoveDiscoStaffMembershipButton from "../components/ControlRemoveDiscoStaffMembershipButton"

export interface ControlUserDetailPageProps {
  id: string
}

function ControlUserDetailPage() {
  const { id: userId } = useParams<ControlUserDetailPageProps>()
  const theme = useTheme()
  const classes = useStyles()

  const id = Relay.toGlobalId("User", userId)

  const [{ user }, refetch] = Relay.useRefetchableQuery<ControlUserDetailPageQuery>(
    graphql`
      query ControlUserDetailPageQuery($id: ID!) {
        user: controlGetUser(id: $id) {
          id
          fullName
          email
          organizationMemberships {
            edges {
              node {
                id
                isDiscoStaff
                organization {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    { id },
    { fetchPolicy: "network-only" }
  )

  const organizationMemberships = Relay.connectionToArray(user?.organizationMemberships)

  return (
    <>
      <DiscoPage
        headerContent={
          <DiscoSection padding={1.5}>
            <BackButton
              label={"Back"}
              defaultPath={CONTROL_ROUTE_NAMES.USERS.ROOT}
              marginBottom={5}
              color={theme.palette.text.primary}
            />
          </DiscoSection>
        }
      >
        <DiscoSection padding={1.5}>
          <DiscoText>{`ID: ${userId}`}</DiscoText>
          <DiscoText>{`Name: ${user.fullName}`}</DiscoText>
          <DiscoText>{`Email: ${user.email}`}</DiscoText>
          <DiscoText variant={"body-lg-500"} marginTop={2}>
            {"Communities"}
          </DiscoText>
          {organizationMemberships.map((om) => (
            <div key={om.id} className={classes.membership}>
              <DiscoLink
                key={om.id}
                style={{ display: "block" }}
                to={generatePath(CONTROL_ROUTE_NAMES.COMMUNITIES.DETAIL, {
                  id: Relay.fromGlobalId(om.organization.id).id,
                })}
              >
                {om.organization.name}
              </DiscoLink>
              {om.isDiscoStaff && (
                <ControlRemoveDiscoStaffMembershipButton
                  organizationMembershipId={om.id}
                  onSubmit={() => refetch({ id })}
                >
                  {"Remove Staff Membership"}
                </ControlRemoveDiscoStaffMembershipButton>
              )}
            </div>
          ))}
        </DiscoSection>
        <DiscoSection padding={1.5}>
          <DiscoSection className={classes.section}>
            <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={3}>
              {`Remove this user. NOTE: This is only allowed if they are not a member of any community.`}
            </DiscoText>
            <ControlUserRemoveButton
              userId={user.id}
              disabled={organizationMemberships.length > 0}
            >
              {"Remove User"}
            </ControlUserRemoveButton>
          </DiscoSection>
        </DiscoSection>
      </DiscoPage>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  membership: {
    display: "flex",
    padding: theme.spacing(1),
  },
}))

export default ControlUserDetailPage
