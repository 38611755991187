import ControlAppSideBar from "@/control/page/ControlAppSideBar"
import ErrorBoundary from "@/core/error/ErrorBoundary"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoPageSkeleton } from "@disco-ui/page/DiscoPage"
import useResizeObserver from "@utils/hook/useResizeObserver"
import React, { Suspense, useRef, useState } from "react"

export default function ControlAppPageLayout({
  children,
}: {
  children: React.ReactNode
}) {
  // Dynamically handle page content height based on the height of app bars
  const appBarRef = useRef<HTMLElement>()
  const pageBarRef = useRef<HTMLDivElement>(null)
  const [appBarHeight, setAppBarHeight] = useState<number>(
    appBarRef.current?.clientHeight || 0
  )
  const [pageBarHeight, setPageBarHeight] = useState<number>(
    pageBarRef.current?.clientHeight || 0
  )
  useResizeObserver(appBarRef, (el) => setAppBarHeight(el.clientHeight))
  useResizeObserver(pageBarRef, (el) => setPageBarHeight(el.clientHeight))

  const classes = useStyles({
    appBarHeight,
    pageBarHeight,
  })

  return (
    <>
      <div className={classes.container}>
        {renderSideBars()}
        {renderPage()}
      </div>
    </>
  )

  function renderSideBars() {
    return (
      <nav className={classes.navContainer}>
        <ControlAppSideBar />
      </nav>
    )
  }

  function renderPage() {
    return (
      <div id={"app-page-layout-scroll-container"} className={classes.page}>
        <div className={classes.fullHeightContentContainer}>
          {/* Catch any loading/errors state to keep showing the header/nav elements */}
          <Suspense fallback={<DiscoPageSkeleton />}>
            <ErrorBoundary>{children}</ErrorBoundary>
          </Suspense>
        </div>
      </div>
    )
  }
}

type StyleProps = {
  appBarHeight: number
  pageBarHeight: number
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-start",
    backgroundColor: theme.palette.groovy.neutral[700],
  },
  navContainer: ({ appBarHeight }: StyleProps) => ({
    height: `calc(100dvh - ${appBarHeight}px)`,
    display: "flex",
    flex: "1 0 auto",
  }),
  page: ({ appBarHeight }: StyleProps) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: 0,
    // necessary to set height on this container so content does not overflow on an element higher up
    // and cause drawer overlay to have full height of page content
    height: `calc(100dvh - ${appBarHeight}px)`,
    overflowX: "hidden",
    overflowY: "auto",

    backgroundColor: theme.palette.groovy.neutral[100],
  }),
  fullHeightContentContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: ({ appBarHeight, pageBarHeight }: StyleProps) =>
      `calc(100dvh - ${appBarHeight}px - ${pageBarHeight}px)`,
    flexShrink: 0,
  },
}))
