import useDismissNotifications from "@/apps/util/hooks/useDismissNotifications"
import CommentsSection, { CommentsSectionSkeleton } from "@/comments/CommentsSection"
import { CommentsSectionProvider } from "@/comments/CommentsSectionContext"
import CommentEditor from "@/comments/editor/CommentEditor"
import CommentUtils from "@/comments/utils/CommentUtils"
import ContentAttachmentsSection from "@/content/detail/sections/ContentAttachmentsSection"
import ContentDescriptionSection from "@/content/detail/sections/ContentDescriptionSection"
import ContentFooterSection from "@/content/detail/sections/ContentFooterSection"
import ContentHeaderSection from "@/content/detail/sections/ContentHeaderSection"
import ContentMediaSection from "@/content/detail/sections/ContentMediaSection"
import { PostDrawerPageContentQuery } from "@/content/post/__generated__/PostDrawerPageContentQuery.graphql"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import NotFoundPageContent from "@/core/route/component/not-found/NotFoundPageContent"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import DeletePostButton from "@/post/delete/DeletePostButton"
import EditPostButton from "@/post/edit/EditPostButton"
import SharePostButton from "@/post/share/SharePostButton"
import FlagContentButton from "@/product/common/page/content/flagging/FlagContentButton"
import ContentFlaggingsChip from "@/product/common/page/content/flagging/popover/ContentFlaggingsChip"
import useCanFlagContent from "@/product/common/page/content/flagging/popover/useCanFlagContent"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButtonSkeleton,
  DiscoDivider,
  DiscoDrawerHeader,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { Grid } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import usePermissions from "@utils/hook/usePermissions"
import { isFuture } from "date-fns"
import { graphql, useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"

const TESTID = "PostDetailPage"

function PostDrawerPageContent() {
  const history = useHistory()
  const classes = useStyles()
  const activeProduct = useActiveProduct()
  const drawer = useGlobalDrawer("post")
  const { postId } = drawer.params
  const isMobile = useIsMobile()

  const { post } = useLazyLoadQuery<PostDrawerPageContentQuery>(
    graphql`
      query PostDrawerPageContentQuery($id: ID!) {
        post: node(id: $id) {
          __typename
          ... on Post {
            id
            feedId
            ...usePermissionsFragment
            ...DeletePostButtonFragment
            ...EditPostButtonFragment
            ...SharePostButtonFragment
            feed {
              ...usePermissionsFragment
            }
            product {
              slug
            }
            releasedAt
            productId
            content {
              id
              name
              showComments
              totalComments
              ...useCanFlagContentFragment
              ...ContentFlaggingsChipFragment
              ...FlagContentButtonFragment
              ...ContentHeaderSectionFragment
              ...ContentMediaSectionFragment
              ...ContentTitleSectionFragment
              ...ContentDescriptionSectionFragment
              ...ContentAttachmentsSectionFragment
              ...ContentFooterSection_ContentFragment
            }
          }
        }
      }
    `,
    { id: postId }
  )

  const canFlag = useCanFlagContent(Relay.isNodeType(post, "Post") ? post.content : null)
  const postPermissions = usePermissions(Relay.isNodeType(post, "Post") ? post : null)
  const feedPermissions = usePermissions(
    Relay.isNodeType(post, "Post") ? post.feed : null
  )

  // Dismiss the post published notification on render
  useDismissNotifications(
    {
      entityId: Relay.isNodeType(post, "Post") ? post.content.id : "",
      kind: "course-content-publish",
    },
    {
      skip: !Relay.isNodeType(post, "Post"),
    }
  )

  if (!Relay.isNodeType(post, "Post")) return null

  const isScheduled = Boolean(post.releasedAt) && isFuture(new Date(post.releasedAt!))
  const showOverflow = postPermissions.has("post.delete") || canFlag

  if (isScheduled && !feedPermissions.has("posts.schedule")) {
    return <NotFoundPageContent />
  }

  return (
    <CommentsSectionProvider>
      <div className={classes.container}>
        <DiscoDrawerHeader
          testid={`PostDrawer.header`}
          className={classes.headerContainer}
          title={post.content.name || ""}
          onClose={() => drawer.close()}
          disableExpand
        />
        <ContentHeaderSection
          className={classes.header}
          contentKey={post.content}
          testid={TESTID}
          overflow={
            <>
              {showOverflow && (
                <DiscoMoreActionsDropdown
                  testid={TESTID}
                  rotateIcon
                  width={40}
                  height={40}
                >
                  <SharePostButton postKey={post}>
                    {(props) => (
                      <DiscoDropdownItem
                        title={"Share"}
                        icon={"send"}
                        {...props}
                        testid={`${TESTID}.share`}
                      />
                    )}
                  </SharePostButton>

                  <EditPostButton postKey={post}>
                    {(props) => (
                      <DiscoDropdownItem
                        title={"Edit"}
                        icon={"pencil"}
                        {...props}
                        testid={`${TESTID}.edit`}
                      />
                    )}
                  </EditPostButton>

                  <DeletePostButton postKey={post} onDelete={handleDelete}>
                    {(props) => (
                      <DiscoDropdownItem
                        title={"Delete"}
                        icon={"trash"}
                        {...props}
                        testid={`${TESTID}.delete`}
                      />
                    )}
                  </DeletePostButton>

                  <FlagContentButton contentKey={post.content}>
                    {(props) => (
                      <DiscoDropdownItem
                        title={"Flag"}
                        icon={"flag"}
                        {...props}
                        testid={`${TESTID}.flag`}
                      />
                    )}
                  </FlagContentButton>
                </DiscoMoreActionsDropdown>
              )}
              <ContentFlaggingsChip contentKey={post.content} autoOpen />
            </>
          }
        />
        <ContentMediaSection
          className={classes.media}
          contentKey={post.content}
          testid={TESTID}
          showInteractivePlayer
        />
        <ContentDescriptionSection
          className={classes.description}
          contentKey={post.content}
          testid={TESTID}
        />
        <ContentAttachmentsSection
          className={classes.attachments}
          contentKey={post.content}
          testid={TESTID}
        />
        <ContentFooterSection
          className={classes.footer}
          contentKey={post.content}
          testid={TESTID}
          bookmarkKind={"post"}
          hideBookmarkText={isMobile}
        />

        {post.content.showComments && <CommentsSection contentId={post.content.id} />}
      </div>
      {post.content.showComments && (
        <div className={classes.commentFooter}>
          <CommentEditor
            // Key used to reset and clear the RichTextEditor in CommentEditor when a comment is posted
            // contentId helps keep each editor unique to the content the comment is submitted for
            key={`comment-editor-${post.content.id}-${post.content.totalComments}`}
            contentId={post.content.id}
            onCommentSubmitted={CommentUtils.scrollToNewComment}
            enforceMaxHeight
          />
        </div>
      )}
    </CommentsSectionProvider>
  )

  function handleDelete() {
    if (!Relay.isNodeType(post, "Post")) return

    if (activeProduct?.slug) {
      history.push(
        generatePath(ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST, {
          productSlug: activeProduct.slug,
          feedId: post.feedId,
        })
      )
    }

    history.push(
      generatePath(ROUTE_NAMES.COMMUNITY.FEED.POSTS.LIST, { feedId: post.feedId })
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  headerContainer: {
    padding: theme.spacing(2.5, 2.5, 0),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2.5, 2.5, 0, 1.5),
    },
  },
  container: {
    padding: theme.spacing(2.5),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.xl,
    overflow: "auto",

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1.5),
    },
  },
  commentFooter: {
    borderTop: theme.palette.constants.borderSmall,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 2.5, 1.5, 2.5),
  },
  header: {
    marginBottom: theme.spacing(2.5),
  },
  media: {
    marginBottom: theme.spacing(2.5),
  },
  description: {
    marginBottom: theme.spacing(2.5),
  },
  attachments: {
    marginBottom: theme.spacing(2.5),
  },
  footer: {
    marginBottom: theme.spacing(3),
  },
}))

export const PostDrawerPageContentSkeleton: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Grid container alignItems={"center"}>
        <Grid item xs={8}>
          <DiscoTextSkeleton variant={"heading-md"} width={"100%"} />
        </Grid>
      </Grid>
      <DiscoTextSkeleton height={185} />
      <DiscoButtonSkeleton width={"100%"} />
      <DiscoDivider marginTop={4} marginBottom={4} />
      <CommentsSectionSkeleton />
    </div>
  )
}

export default Relay.withSkeleton({
  component: PostDrawerPageContent,
  skeleton: PostDrawerPageContentSkeleton,
})
