/**
 * @generated SignedSource<<3ee25b7666f190d2f4cd5e3beb2f0e50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AdminContentDrawerContent_ContentFragment$data = {
  readonly id: string;
  readonly name: string | null;
  readonly type: ContentType;
  readonly " $fragmentSpreads": FragmentRefs<"DiscoEditorMentionsFragment" | "InlineContentHeroAndProperties_ContentFragment" | "usePermissionsFragment" | "QuizEditorBanner_ContentFragment">;
  readonly " $fragmentType": "AdminContentDrawerContent_ContentFragment";
};
export type AdminContentDrawerContent_ContentFragment$key = {
  readonly " $data"?: AdminContentDrawerContent_ContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminContentDrawerContent_ContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminContentDrawerContent_ContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DiscoEditorMentionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InlineContentHeroAndProperties_ContentFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuizEditorBanner_ContentFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "a349f2ddb3aae10a30158abcb5b13a34";

export default node;
