import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"
import ContentTemplateCard from "@/content/templates/ContentTemplateCard"
import {
  ContentTemplateKind,
  CONTENT_TEMPLATES_CARDS_ORDER,
} from "@/content/util/contentTemplates"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { TestIDProps } from "@utils/typeUtils"

interface ContentTemplateListsProps extends TestIDProps {
  onTemplateSelect: (template: ContentTemplateKind) => void
  templatesToExclude?: ContentTemplateKind[]
  className?: string
  disabled?: boolean
}

function ContentTemplateLists({
  testid = "ContentTemplates",
  className,
  onTemplateSelect,
  templatesToExclude = [],
  disabled,
}: ContentTemplateListsProps) {
  const activeOrganization = useActiveOrganization()!
  const { surveys } = useFeatureFlags()
  const cards = CONTENT_TEMPLATES_CARDS_ORDER.filter(
    ({ template }) =>
      !templatesToExclude.includes(template) && (surveys || template !== "survey")
  )
  const classes = useStyles({ cardCount: cards.length })
  const { connectedApps } = useConnectedProductApps()

  return (
    <div className={className}>
      <div className={classes.templateList}>
        {cards.map(({ template }) => (
          <ContentTemplateCard
            key={template}
            variant={"compact"}
            testid={`${testid}.${template}`}
            template={template}
            onClick={() => onTemplateSelect(template)}
            disabled={disabled || shouldDisableTemplate(template)}
          />
        ))}
      </div>
    </div>
  )

  function shouldDisableTemplate(template: ContentTemplateKind) {
    switch (template) {
      case "connect_slack": {
        return activeOrganization.slackConnection?.version !== 2
      }
      case "add_events_to_calendar": {
        return !connectedApps.has("events")
      }
      default: {
        return false
      }
    }
  }
}

interface StyleProps {
  cardCount: number
}

const useStyles = makeUseStyles((theme) => ({
  templateList: ({ cardCount }: StyleProps) => ({
    width: "100%",
    display: "grid",
    gridTemplateColumns:
      cardCount < 3 ? "repeat(2, minmax(0, 1fr))" : "repeat(3, minmax(0, 1fr))",
    marginTop: theme.spacing(2),
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    },
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "minmax(0, 1fr)",
    },
    gap: theme.spacing(2),
  }),
}))

export default ContentTemplateLists
