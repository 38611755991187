import { ContentTitleSectionFragment$key } from "@/content/detail/sections/__generated__/ContentTitleSectionFragment.graphql"
import useIsOnContentDetailPage from "@/content/hook/useIsOnContentDetailPage"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import { DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  contentKey: ContentTitleSectionFragment$key
  testid: string
  onGoToDetailPage?: () => void
  className?: string
}

function ContentTitleSection({ contentKey, testid, onGoToDetailPage, className }: Props) {
  const isOnContentDetailPage = useIsOnContentDetailPage()
  const classes = useStyles({
    onGoToDetailPage: Boolean(onGoToDetailPage),
    isOnContentDetailPage,
  })

  const content = useFragment<ContentTitleSectionFragment$key>(
    graphql`
      fragment ContentTitleSectionFragment on Content {
        id
        name
      }
    `,
    contentKey
  )

  return (
    <div className={className} data-testid={`${testid}.TitleSection`}>
      <DiscoText
        className={classes.title}
        variant={"heading-sm"}
        onClick={getOnClick()}
        testid={`${testid}.TitleSection.title`}
        truncateText={2}
      >
        {content.name}
      </DiscoText>
    </div>
  )

  function getOnClick() {
    if (isOnContentDetailPage) return
    return onGoToDetailPage
  }
}

type StyleProps = {
  onGoToDetailPage: boolean
  isOnContentDetailPage: boolean
}

const useStyles = makeUseStyles({
  title: ({ onGoToDetailPage, isOnContentDetailPage }: StyleProps) => ({
    textAlign: "left",
    cursor: onGoToDetailPage && !isOnContentDetailPage ? "pointer" : undefined,
  }),
})

interface SkeletonProps {
  className?: string
}

export function ContentTitleSectionSkeleton({ className }: SkeletonProps) {
  const classes = useStyles({ onGoToDetailPage: false, isOnContentDetailPage: false })
  return (
    <div className={className}>
      <DiscoTextSkeleton className={classes.title} width={"75%"} />
    </div>
  )
}

export default Relay.withSkeleton({
  component: ContentTitleSection,
  skeleton: ContentTitleSectionSkeleton,
})
