import { ControlUserRemoveModalMutation } from "@/control/components/__generated__/ControlUserRemoveModalMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { displayGraphQLErrorToast } from "@components/toast/ToastProvider"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { graphql } from "relay-runtime"

interface Props {
  isOpen: boolean
  onClose?: () => void
  onSubmit?: () => void
  userId: GlobalID
}

function ControlUserRemoveModal({ userId, isOpen, onClose, onSubmit }: Props) {
  const commitMutation = Relay.useAsyncMutation<ControlUserRemoveModalMutation>(
    graphql`
      mutation ControlUserRemoveModalMutation($id: ID!) {
        controlRemoveUser(id: $id) {
          data
          errors {
            field
            message
          }
        }
      }
    `
  )

  return (
    <DiscoWarningModal
      testid={`remove_user`}
      modalContentLabel={"Remove User"}
      isOpen={isOpen}
      onClose={onClose}
      title={`Remove user`}
      description={`Are you sure you want to remove this user?`}
      confirmationButtonProps={{
        onClick: () => {
          runAction()
        },
        children: `Yes, remove`,
      }}
    />
  )

  async function runAction() {
    const res = await commitMutation({
      id: userId,
    })
      .catch((err) => {
        displayGraphQLErrorToast(err)
      })
      .finally(() => {
        if (onSubmit) onSubmit()
        if (onClose) onClose()
      })
    if (!res) return
    if (res.controlRemoveUser.errors) {
      displayGraphQLErrorToast(res.controlRemoveUser.errors[0])
    } else {
      window.location.href = "/users"
    }
  }
}

export default ControlUserRemoveModal
