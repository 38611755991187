import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { observer } from "mobx-react-lite"

interface Props {
  form: ContentFormStore
  classes?: Partial<ClassNameMap<"control" | "inputRoot" | "input">>
  label?: string | React.ReactNode | null
  placeholder?: string
}

function ContentFormTitleSection({
  form,
  classes: customClasses,
  label,
  placeholder,
}: Props) {
  return (
    <DiscoFormControl
      className={customClasses?.control}
      label={
        typeof label === "string" ? (
          <DiscoText variant={"body-md-600"} color={"text.secondary"}>
            {label}
          </DiscoText>
        ) : (
          label
        )
      }
      errorMessages={form.errorsByField.name}
    >
      <DiscoInput
        data-testid={"ContentFormFields.name"}
        classes={{
          root: customClasses?.inputRoot,
          input: customClasses?.input,
        }}
        value={form.state.content!.name}
        onChange={(e) => (form.state.content!.name = e.target.value)}
        placeholder={placeholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
    </DiscoFormControl>
  )
}

export default observer(ContentFormTitleSection)
