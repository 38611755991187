import { ContentType } from "@/content-usage/__generated__/ContentUsageUtils_useNavigateToNextContentUsageFragment.graphql"
import { ContentFormPreviewEmailButtonMutation } from "@/content/form/buttons/__generated__/ContentFormPreviewEmailButtonMutation.graphql"
import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { graphql } from "relay-runtime"

interface Props {
  form: ContentFormStore
  contentType: ContentType
  disabled?: boolean
  ctaUrl?: string
  productId?: GlobalID
}

function ContentFormPreviewEmailButton({
  form,
  contentType,
  disabled,
  ctaUrl,
  productId,
}: Props) {
  const activeOrganization = useActiveOrganization()!
  const { authUser } = useAuthUser()

  const sendPreviewEmail = Relay.useAsyncMutation<ContentFormPreviewEmailButtonMutation>(
    graphql`
      mutation ContentFormPreviewEmailButtonMutation($input: SendPreviewEmailInput!) {
        sendPreviewEmail(input: $input)
      }
    `
  )

  if (!authUser) return null

  return (
    <DiscoButton
      testid={"ContentFormPreviewEmailButton"}
      color={"transparent"}
      disabled={disabled}
      onClick={sendPreview}
      leftIcon={"send"}
    >
      {"Send Me Preview"}
    </DiscoButton>
  )

  async function sendPreview() {
    if (!authUser) return
    await sendPreviewEmail({
      input: {
        title: form.state.content.notificationEmailSubject || "",
        postTitle: form.state.content.name,
        richEditorBody: form.state.content.richEditorNotificationEmailBodyContent,
        productId: productId ? Relay.fromGlobalId(productId).id : undefined,
        organizationId: Relay.fromGlobalId(activeOrganization.id).id,
        contentType,
        contentLabelId: form.state.content.contentLabelId,
        notificationEmailCtaButton: form.state.content.notificationEmailCtaButton,
        createdById: form.state.content.createdById,
        ctaUrl,
      },
    })

    displaySuccessToast({
      message: `Preview email sent to ${authUser.email!}`,
      testid: "ContentFormPreviewEmailButton.confetti-emoji",
    })
  }
}

export default observer(ContentFormPreviewEmailButton)
