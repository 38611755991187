import { ControlAppContextQuery } from "@/control/__generated__/ControlAppContextQuery.graphql"
import { ComponentType, FC, ReactNode } from "react"
import { PreloadedQuery } from "react-relay"

export type ControlDefaultProviderProps = {
  children: ReactNode
}

export type ControlPreloadedProviderProps = {
  queryRef: PreloadedQuery<ControlAppContextQuery>
} & ControlDefaultProviderProps

type ControlProviderComponent =
  | FC<{ children?: ReactNode }>
  | ComponentType<ControlDefaultProviderProps>
  // Some third party providers like HelmetProvider doesn't require any children
  | ComponentType<{ children?: ReactNode }>
  | {
      Component: FC<ControlPreloadedProviderProps>
      props: {
        queryRef: PreloadedQuery<ControlAppContextQuery>
      }
    }

interface ControlContextProvidersProps {
  providers: ControlProviderComponent[]
}

/**
 * Render a series of components as children to avoid a nested mess.
 * <ComponentA>
 *  <ComponentB>
 *   {children}
 *  </ComponentB>
 * </ComponentA>
 */
const ControlContextProviders: React.FC<ControlContextProvidersProps> = (props) => {
  const { children, providers } = props
  return <>{renderNestedChildren(children, providers)}</>
}

function renderNestedChildren(
  children: React.ReactNode,
  components: ControlProviderComponent[]
) {
  if (components.length === 0) {
    return children
  }

  const Provider = components[0]

  if (typeof Provider === "object" && "Component" in Provider) {
    const { Component, props: componentProps } = Provider
    return (
      <Component {...componentProps}>
        {renderNestedChildren(children, components.slice(1))}
      </Component>
    )
  }

  return <Provider>{renderNestedChildren(children, components.slice(1))}</Provider>
}

export default ControlContextProviders
