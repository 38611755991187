import { PostFormState } from "@/content/form/util/contentFormUtil"
import FormStore from "@/core/form/store/FormStore"
import { CreatePostInput } from "@/post/add/__generated__/CreatePostModalMutation.graphql"
import { EditPostInput } from "@/post/edit/__generated__/EditPostModalMutation.graphql"
import PostPreviewEmailFormFields from "@/post/share/PostPreviewEmailFormFields"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl } from "@disco-ui"
import DiscoDatePicker, { DateDetails } from "@disco-ui/date/DiscoDatePicker"
import DiscoTimeInput from "@disco-ui/date/DiscoTimeInput"
import { observer } from "mobx-react-lite"

interface Props {
  form: FormStore<(CreatePostInput | EditPostInput) & PostFormState>
}

function ContentFormScheduleReleaseSection({ form }: Props) {
  const classes = useStyles()
  return (
    <>
      <div className={classes.dateTimeInputsContainer}>
        <DiscoFormControl label={"Date"}>
          <DiscoDatePicker
            testid={"ContentForm.release-date-select"}
            onChange={handleReleaseDateChange}
            value={new Date(form.state.content.releaseDatetime!)}
            minDate={new Date()}
            disabled={!form.state.isScheduled}
          />
        </DiscoFormControl>
        <DiscoFormControl
          label={"Time"}
          error={Boolean(form.errorsByField.releaseDatetime)}
          errorMessages={form.errorsByField.releaseDatetime}
        >
          <DiscoTimeInput
            testid={"ContentForm.release-time-select"}
            value={new Date(form.state.content.releaseDatetime!)}
            onChange={handleReleaseTimeChange}
            isDisabled={!form.state.isScheduled}
          />
        </DiscoFormControl>
      </div>
      <PostPreviewEmailFormFields form={form} />
    </>
  )

  function handleReleaseDateChange(details: DateDetails) {
    if (details.value) {
      const month = details.value.getMonth()
      const day = details.value.getDate()
      const year = details.value.getFullYear()

      const newDate = new Date(form.state.content.releaseDatetime!)
      newDate.setMonth(month)
      newDate.setDate(day)
      newDate.setFullYear(year)

      form.state.content.releaseDatetime = newDate.toString()
    }
  }

  function handleReleaseTimeChange(timeString: string) {
    const [time, period] = timeString.split(" ")
    const [hour, minute] = time.split(":")

    const newDate = new Date(form.state.content.releaseDatetime!)
    newDate.setHours(Number(hour))
    newDate.setMinutes(Number(minute))

    if (period === "PM") {
      newDate.setHours(newDate.getHours() + 12)
    }

    form.state.content.releaseDatetime = newDate.toString()
  }
}

const useStyles = makeUseStyles((theme) => ({
  dateTimeInputsContainer: {
    display: "flex",
    gap: theme.spacing(1.5),
  },
}))

export default observer(ContentFormScheduleReleaseSection)
