import { Attachment } from "@/content/attachment/util/contentAttachmentTypes"
import MoveIcon from "@/core/ui/iconsax/linear/custom-dots-tune.svg"
import DownloadIcon from "@/core/ui/iconsax/linear/custom-download.svg"
import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { DiscoIcon, DiscoIconButton, DiscoText } from "@disco-ui"
import DiscoMediaModal from "@disco-ui/modal/DiscoMediaModal"
import classNames from "classnames"
import React, { useState } from "react"

export interface AttachmentListItemProps {
  attachment: Pick<Attachment, "name" | "mediaUrl" | "type"> & { id?: string | null }
  showDragIcon?: boolean
  onClickDelete?(attachment: AttachmentListItemProps["attachment"]): void
  error?: boolean
  testid?: string
  isCompact?: boolean
  isDragging?: boolean
}

function AttachmentListItem({
  attachment,
  showDragIcon,
  onClickDelete,
  testid,
  error,
  isCompact,
  isDragging,
}: AttachmentListItemProps) {
  const fileExtension = attachment.name.split(".").slice(-1)[0]
  const imageExtensionRegex = /png|jpe?g|gif/i
  const isImage = imageExtensionRegex.test(fileExtension)
  const isVideo = attachment.type === "video-url"
  const isMedia = Boolean(isVideo || isImage)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const hasError = Boolean(error)
  const classes = useStyles({
    isMedia,
    hasError,
    isCompact,
    isDragAndDrop: showDragIcon,
    isDragging,
  })
  const showOnHoverClasses = useShowOnHoverStyles({
    hideWithDisplay: {
      stylesWhenShown: {
        display: "flex",
      },
    },
  })

  if (!attachment.mediaUrl) return null

  const item = (
    <>
      <div
        className={classNames(classes.container, showOnHoverClasses.hoverable)}
        onClick={isMedia ? handleAttachmentClick : undefined}
        onKeyPress={isMedia ? handleAttachmentClick : undefined}
        role={isMedia ? "button" : undefined}
        tabIndex={isMedia ? 0 : undefined}
        data-testid={testid && `AttachmentListItem.${testid}`}
      >
        {showDragIcon && (
          <MoveIcon
            className={classNames(classes.dragIcon, showOnHoverClasses.showable)}
          />
        )}
        {isCompact ? (
          <DiscoIcon
            className={classes.fileTypeIcon}
            icon={isImage ? "image" : isVideo ? "video-square" : "file"}
          />
        ) : (
          <div className={classes.fileType}>
            {hasError ? (
              <CloseIcon width={24} height={24} />
            ) : (
              <DiscoText
                testid={`AttachmentListItem.${testid}.file-extension`}
                variant={"body-sm-700"}
                color={"text.secondary"}
              >
                {fileExtension.toUpperCase()}
              </DiscoText>
            )}
          </div>
        )}
        <DiscoText
          testid={`AttachmentListItem.${testid}.name`}
          variant={isCompact ? "body-sm" : "body-sm-700"}
          truncateText={1}
          color={hasError ? "text.danger" : "text.primary"}
        >
          {attachment.name}
        </DiscoText>
        {onClickDelete ? (
          <DiscoIconButton
            onClick={(evt: React.MouseEvent) => {
              evt.preventDefault()
              // Because the attachment is clickable itself, we
              // need to prevent the click event from bubbling
              // up and triggering 'handleAttachmentClick'
              evt.stopPropagation()
              onClickDelete(attachment)
            }}
            className={classNames(classes.deleteButton, {
              [showOnHoverClasses.showable]: isCompact,
            })}
            svgStyles={{
              width: isCompact ? 18 : 24,
              height: isCompact ? 18 : 24,
            }}
            testid={`${testid}.delete-button`}
          >
            <DiscoIcon icon={"trash"} />
          </DiscoIconButton>
        ) : (
          <a
            href={attachment.mediaUrl}
            target={"_blank"}
            rel={"noreferrer noopener"}
            onClick={(e) => e.stopPropagation()}
            data-testid={testid && `AttachmentListItem.${testid}`}
            className={classNames(classes.downloadButton, {
              [showOnHoverClasses.showable]: isCompact,
            })}
          >
            <DiscoIconButton
              svgStyles={{
                width: isCompact ? 18 : 24,
                height: isCompact ? 18 : 24,
              }}
            >
              <DownloadIcon />
            </DiscoIconButton>
          </a>
        )}
      </div>
      {isMedia && (
        <DiscoMediaModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          isImage={isImage}
          isVideo={isVideo}
          testid={testid}
          src={attachment.mediaUrl}
        />
      )}
    </>
  )

  // Only open images when clicked, otherwise download on click
  return isImage ? (
    item
  ) : (
    <a href={attachment.mediaUrl} target={"_blank"} rel={"noreferrer noopener"}>
      {item}
    </a>
  )

  function handleAttachmentClick() {
    if (isMedia) {
      setIsModalOpen(true)
    }
  }
}

export default AttachmentListItem

type StyleProps = {
  isMedia: boolean
  hasError: boolean
  isCompact?: boolean
  isDragAndDrop?: boolean
  downloadOnClick?: boolean
  isDragging?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ isCompact, isDragging }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: theme.measure.borderRadius.xl,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    overflow: "hidden",
    cursor: "pointer",
    ...styleIf(!isCompact, {
      boxShadow: theme.palette.groovyDepths.insideCard,
    }),
    ...styleIf(isCompact, {
      paddingLeft: 0,
      marginTop: 0,
      padding: theme.spacing(0.5, 1),
      borderRadius: theme.measure.borderRadius.medium,
      gap: theme.spacing(1),
      boxShadow: "none",
      height: "40px",
      transition: "box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out",

      "&:hover": {
        backgroundColor: theme.palette.groovy.neutral[100],
      },
    }),
    ...styleIf(isCompact && isDragging, {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    }),
  }),
  fileType: ({ hasError }: StyleProps) => ({
    backgroundColor: hasError
      ? theme.palette.groovy.red[100]
      : theme.palette.background.default,
    borderRadius: theme.measure.borderRadius.big,
    marginRight: theme.spacing(2),
    color: hasError ? "text.danger" : theme.palette.text.secondary,
    height: "48px",
    width: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  downloadButton: ({ isCompact }: StyleProps) => ({
    marginLeft: "auto",
    color: theme.palette.primary.main,

    "& svg path": {
      color: theme.palette.groovy.grey[400],
    },

    ...styleIf(isCompact, {
      "& svg path": {
        color: theme.palette.text.primary,
      },

      "& button": {
        padding: theme.spacing(1),
      },
    }),
  }),
  fileTypeIcon: {
    height: "24px",
    width: "24px",
    minWidth: "24px",
  },
  dragIcon: ({ isCompact }: StyleProps) => ({
    height: "16px",
    width: "16px",
    marginRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,

    ...styleIf(isCompact, {
      marginRight: 0,
      height: "30px",
      width: "18px",
      minWidth: "18px",
    }),
  }),
  deleteButton: ({ hasError, isCompact }: StyleProps) => ({
    marginLeft: "auto",
    ...styleIf(hasError, {
      color: "text.danger",
    }),
    ...styleIf(isCompact, {
      padding: theme.spacing(1),
    }),
  }),
}))
