import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoIconKinds, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core/styles"
import { TestIDProps } from "@utils/typeUtils"
import { ReactNode } from "react"

interface InlineContentPropertyProps extends TestIDProps {
  property: {
    label: string
    icon: DiscoIconKinds
  }
  children: ReactNode
}

function InlineContentProperty({ property, children }: InlineContentPropertyProps) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div>
      <div className={classes.label}>
        <DiscoIcon
          color={theme.palette.groovy.grey[300]}
          height={18}
          width={18}
          icon={property.icon}
        />
        <DiscoText color={"groovy.grey.300"} variant={"body-sm-600"}>
          {property.label}
        </DiscoText>
      </div>
      {children}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  label: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    width: "120px",
    height: "40px",
  },
}))

export default InlineContentProperty
