import { ControlCommunityRedirectPageQuery } from "@/control/page/__generated__/ControlCommunityRedirectPageQuery.graphql"
import Relay from "@/relay/relayUtils"
import { DiscoText } from "@disco-ui"
import { useLazyLoadQuery } from "react-relay"
import { useParams } from "react-router-dom"
import { graphql } from "relay-runtime"

export interface ControlCommunityRedirectPageParams {
  id: string
}

function ControlCommunityRedirectPage() {
  const { id: rawId } = useParams<ControlCommunityRedirectPageParams>()
  const id = Relay.toGlobalId("Organization", rawId)

  const { community } = useLazyLoadQuery<ControlCommunityRedirectPageQuery>(
    graphql`
      query ControlCommunityRedirectPageQuery($id: ID!) {
        community: controlGetCommunity(id: $id) {
          id
          controlAccessUrl
        }
      }
    `,
    { id },
    { fetchPolicy: "network-only" }
  )

  window.location.href = community.controlAccessUrl

  return <DiscoText>{"Redirecting...."}</DiscoText>
}

export default ControlCommunityRedirectPage
