/**
 * @generated SignedSource<<6d78e6f2cc474408dbf44474876dfae3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ControlRemoveDiscoStaffMembershipModalMutation$variables = {
  id: string;
};
export type ControlRemoveDiscoStaffMembershipModalMutation$data = {
  readonly controlRemoveDiscoStaffMembership: {
    readonly node: {
      readonly isDiscoStaff: boolean;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type ControlRemoveDiscoStaffMembershipModalMutation = {
  variables: ControlRemoveDiscoStaffMembershipModalMutation$variables;
  response: ControlRemoveDiscoStaffMembershipModalMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDiscoStaff",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ControlRemoveDiscoStaffMembershipModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ControlRemoveDiscoStaffMembershipResponse",
        "kind": "LinkedField",
        "name": "controlRemoveDiscoStaffMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ControlRemoveDiscoStaffMembershipModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ControlRemoveDiscoStaffMembershipResponse",
        "kind": "LinkedField",
        "name": "controlRemoveDiscoStaffMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e4c976c468c7ec36235b08037c06c8c",
    "id": null,
    "metadata": {},
    "name": "ControlRemoveDiscoStaffMembershipModalMutation",
    "operationKind": "mutation",
    "text": "mutation ControlRemoveDiscoStaffMembershipModalMutation(\n  $id: ID!\n) {\n  controlRemoveDiscoStaffMembership(id: $id) {\n    node {\n      isDiscoStaff\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "12d8f8f96cf41e2d916dda86668881ce";

export default node;
