/**
 * @generated SignedSource<<c10ca111d1ab6b9717f0477d3db5b3bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostCardDashboardFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "PostCardDashboardFragment";
};
export type PostCardDashboardFragment$key = {
  readonly " $data"?: PostCardDashboardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostCardDashboardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardDashboardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Dashboard",
  "abstractKey": null
};

(node as any).hash = "2d1f130d3e37e0dec97cfe94527bb75f";

export default node;
