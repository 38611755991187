import GenerateContentWithAIProvider, {
  useGenerateContentWithAI,
} from "@/content/ai/GenerateContentWithAIProvider"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { useLocation } from "react-router-dom"
import { LocationStateControlledShareInstanceModal } from "../share-instance/AddShareInstanceModal"

const AdminContentDrawerLayout = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin-content-drawer-layout" */ "@/content/drawer/AdminContentDrawerLayout"
    )
)

function AdminContentDrawer() {
  const drawer = useGlobalDrawer("adminContent")

  return (
    <GenerateContentWithAIProvider
      prompt={
        drawer.params.drawerAIPrompt
          ? decodeURIComponent(drawer.params.drawerAIPrompt)
          : undefined
      }
      referenceUrl={
        drawer.params.drawerAIReferenceUrl
          ? decodeURIComponent(drawer.params.drawerAIReferenceUrl)
          : undefined
      }
      referenceEmbeddingSourceIds={
        drawer.params.drawerAIReferenceEmbeddingSourceIds
          ? JSON.parse(
              decodeURIComponent(drawer.params.drawerAIReferenceEmbeddingSourceIds)
            )
          : undefined
      }
      referenceModuleContentUsageId={drawer.params.drawerAIReferenceModuleContentUsageId}
    >
      <Modal />
    </GenerateContentWithAIProvider>
  )
}

function Modal() {
  const drawer = useGlobalDrawer("adminContent")
  const classes = useStyles()
  const location = useLocation()
  const modal = useInitUnsavedChangesModalContext()
  const { stopGenerating } = useGenerateContentWithAI()

  return (
    // If leaving with unsaved changes, display the unsaved changes modal
    <>
      <UnsavedChangesModalProvider {...modal}>
        <DiscoDrawer
          size={"xl"}
          open={drawer.isOpen}
          onClose={handleCloseDrawer}
          drawerAnchorKey={location.search}
          containerClasses={{ drawerContainer: classes.drawerContainer }}
          fullScreen
        >
          {drawer.isOpen && (
            <AdminContentDrawerLayout
              key={drawer.params.contentId}
              contentId={drawer.params.contentId}
            />
          )}
        </DiscoDrawer>
      </UnsavedChangesModalProvider>

      <LocationStateControlledShareInstanceModal />
    </>
  )

  function handleCloseDrawer() {
    // Check that there are no unsaved changes before closing
    modal.handleLeave({
      onLeave: () => {
        stopGenerating()
        drawer.close()
      },
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  drawerContainer: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

export default AdminContentDrawer
