/**
 * @generated SignedSource<<b562310fb7e2b87825f45b0bc415c31f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type curriculumUtils__getContentProgress$data = {
  readonly id: string;
  readonly curriculumContentCompletions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly contentUsage: {
          readonly id: string;
        };
      };
    }>;
  };
  readonly product: {
    readonly curriculum: {
      readonly modules: {
        readonly totalCount: number;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly content: {
              readonly children: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly id: string;
                    readonly isReleased: boolean;
                    readonly content: {
                      readonly name: string | null;
                      readonly label: string;
                    };
                  };
                }>;
              } | null;
            };
          };
        }>;
      };
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ModuleCurriculumProgressFragment">;
  readonly " $fragmentType": "curriculumUtils__getContentProgress";
};
export type curriculumUtils__getContentProgress$key = {
  readonly " $data"?: curriculumUtils__getContentProgress$data;
  readonly " $fragmentSpreads": FragmentRefs<"curriculumUtils__getContentProgress">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "curriculumUtils__getContentProgress"
};

(node as any).hash = "64aa13469f02273de4d65cef293cd974";

export default node;
