import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import PageContent from "../../../../main/page/content/PageContent"

function ControlNotFoundPageContent() {
  const classes = useStyles()

  return (
    <PageContent customClassName={classes.container}>
      <DiscoText
        data-testid={"NotFoundPage.title"}
        marginBottom={3}
        variant={"heading-xxl"}
      >
        {"Oops!"}
      </DiscoText>
      <DiscoText
        data-testid={"NotFoundPage.description"}
        variant={"heading-md"}
        marginBottom={4}
        className={classes.description}
        color={"groovy.grey.500"}
      >
        {"Cannot find the page you want."}
      </DiscoText>
    </PageContent>
  )
}

const useStyles = makeUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "120px",
    textAlign: "center",
  },
  description: {
    whiteSpace: "pre-line",
  },
  loginButton: {
    margin: "0 auto",
    width: "fit-content",
  },
})

export default ControlNotFoundPageContent
