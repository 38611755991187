import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import { ContentUsageEntity } from "@/content-usage/__generated__/ContentModulesDragDropProvider_UpdateContentUsageMutation.graphql"
import {
  ContentType,
  contentUtils_getLocation$key,
} from "@/content/util/__generated__/contentUtils_getLocation.graphql"
import { contentUtils_getLocation_parentContent$key } from "@/content/util/__generated__/contentUtils_getLocation_parentContent.graphql"
import { contentUtils_useContentLabelsQuery } from "@/content/util/__generated__/contentUtils_useContentLabelsQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { ContentUsageDrawerSubtab } from "@/core/context/GlobalDrawerProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { ContentFlaggingReason } from "@/product/common/page/content/flagging/__generated__/FlagContentFormMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { WebFormTemplate } from "@/web-form/utils/__generated__/webFormEditorUtils_validateWebFormRevisionInputMutation.graphql"
import { LocationDescriptor } from "history"
import { useLazyLoadQuery } from "react-relay"
import { generatePath } from "react-router-dom"
import {
  ConnectionHandler,
  RecordSourceProxy,
  graphql,
  readInlineData,
} from "relay-runtime"

function updateRelayContentLike(
  store: RecordSourceProxy,
  opts: {
    contentId: GlobalID
    isLiked: boolean
    contentUsageId?: GlobalID
  }
) {
  const { isLiked, contentId, contentUsageId } = opts

  let contentStore = store.get(contentId)
  // If content usage is provided, we need to update the content usage store
  if (contentUsageId) contentStore = store.get(contentUsageId)
  if (!contentStore) return

  contentStore.setValue(!isLiked, "hasViewerLiked")
  const totalLikes = Number(contentStore.getLinkedRecord("likes")?.getValue("totalCount"))
  contentStore
    .getLinkedRecord("likes")
    ?.setValue(isLiked ? totalLikes - 1 : totalLikes + 1, "totalCount")
}

function updateRelayContentReaction(
  store: RecordSourceProxy,
  opts: {
    contentId: GlobalID
    emojiCode: string
    contentUsageId?: GlobalID
    emojiAlias?: string
  }
) {
  const { contentId, emojiCode, contentUsageId, emojiAlias } = opts

  let contentStore = store.get(contentId)
  if (contentUsageId) contentStore = store.get(contentUsageId) // If content usage is provided, update content usage store
  if (!contentStore) return

  const uniqueReactions = contentStore.getLinkedRecord("uniqueReactions")
  if (!uniqueReactions) return

  const uniqueReactionsEdges = uniqueReactions?.getLinkedRecords("edges")
  const likes = contentStore.getLinkedRecord("likes")
  if (!uniqueReactionsEdges || !likes) {
    contentStore?.invalidateRecord()
    return
  }

  // update likes count
  const totalLikes = Number(likes.getValue("totalCount"))
  const existingEmojiEdge = uniqueReactionsEdges.find(
    (edge) => edge.getLinkedRecord("node")?.getValue("emojiCode") === emojiCode
  )
  const hasViewerReacted = Boolean(
    existingEmojiEdge?.getLinkedRecord("node")?.getValue("hasViewerReacted")
  )
  likes.setValue(hasViewerReacted ? totalLikes - 1 : totalLikes + 1, "totalCount")

  if (!existingEmojiEdge) {
    // new unique reaction, add new record
    const newReactionId = `client:new_reaction:${contentId}${emojiCode}`
    const newReactionRecord = store.create(newReactionId, "ContentReaction")
    newReactionRecord.setValue(emojiCode, "emojiCode")
    newReactionRecord.setValue(emojiAlias, "emojiAlias")
    newReactionRecord.setValue(1, "emojiCount")
    newReactionRecord.setValue(true, "hasViewerReacted")
    const newEdge = ConnectionHandler.createEdge(
      store,
      uniqueReactions,
      newReactionRecord,
      "ContentReactionEdge"
    )
    ConnectionHandler.insertEdgeAfter(uniqueReactions, newEdge)
    return
  }

  const reactionCount =
    Number(existingEmojiEdge.getLinkedRecord("node")?.getValue("emojiCount")) ?? 0
  const newReactionsCount = hasViewerReacted ? reactionCount - 1 : reactionCount + 1

  if (newReactionsCount === 0) {
    // single exisiting reaction -> remove record
    const newUpdatedReactions = uniqueReactionsEdges.filter((record) => {
      const node = record.getLinkedRecord("node")
      const recordEmojiCode = node?.getValue("emojiCode")
      if (recordEmojiCode === emojiCode) {
        const dataID = node?.getDataID()
        if (dataID) store.delete(dataID)
        return false // Delete the record from the store and do not include in new list
      }
      return true
    })
    uniqueReactions?.setLinkedRecords(newUpdatedReactions, "edges")
    return
  }

  // existing unique reaction -> update record
  existingEmojiEdge.getLinkedRecord("node")?.setValue(newReactionsCount, "emojiCount")
  existingEmojiEdge
    .getLinkedRecord("node")
    ?.setValue(!hasViewerReacted, "hasViewerReacted")
}

namespace ContentUtils {
  export function useContentLabels() {
    const activeOrganization = useActiveOrganization()!

    const { node } = useLazyLoadQuery<contentUtils_useContentLabelsQuery>(
      graphql`
        query contentUtils_useContentLabelsQuery($organizationId: ID!) {
          node(id: $organizationId) {
            ... on Organization {
              id
              __typename
              contentLabels {
                edges {
                  node {
                    id
                    label
                    defaultContentTypes
                    updatedAt
                  }
                }
              }
            }
          }
        }
      `,
      { organizationId: activeOrganization.id }
    )
    const organization = Relay.narrowNodeType(node, "Organization")

    if (!organization) return []

    const contentLabels = Relay.connectionToArray(organization.contentLabels).map(
      (cl) => ({
        ...cl,
        defaultContentTypes: cl.defaultContentTypes
          ? (JSON.parse(cl.defaultContentTypes) as ContentType[])
          : [],
        updatedAt: new Date(cl.updatedAt),
      })
    )
    return contentLabels
  }

  export function useDefaultContentLabel(
    contentType: ContentType | undefined | null
  ): string {
    const contentLabels = ContentUtils.useContentLabels()
    if (!contentType) return "Content"
    const contentLabel = contentLabels.find((label) =>
      label.defaultContentTypes.some((type) => type === contentType)
    )
    return contentLabel?.label ?? "Content"
  }

  export function getTypesToExclude(addingTo: ContentUsageEntity): ContentType[] {
    if (addingTo !== "curriculum") {
      return ["assignment", "quiz", "survey"]
    }
    return []
  }

  export function useContentLabel({
    content,
    entity, // The entity that the content is used in. Only required if the content type is 'module'
  }: {
    content: {
      type?: ContentType
      label: string
    }
    entity?: ContentUsageEntity
  }) {
    if (content.type === "module") {
      if (!entity)
        throw new Error(
          "Need to pass the entity that the module is used in when determining the correct label for it"
        )
      return ContentModuleUtils.getContentModuleLabel(entity)
    }
    return content.label || ""
  }

  export const FLAG_REASON_OPTIONS: ContentFlaggingReason[] = [
    "community_policy",
    "abusive",
    "spam",
    "other",
  ]

  const FLAG_REASON_LABELS: Record<
    Exclude<ContentFlaggingReason, "%future added value">,
    string
  > = {
    community_policy: "It violates our community policy",
    abusive: "It is abusive towards people in the community",
    spam: "It is spam, advertising, or irrelevant",
    other: "Other",
  }

  export function getFlagReasonLabel(reason: ContentFlaggingReason): string {
    if (reason in FLAG_REASON_LABELS) {
      return FLAG_REASON_LABELS[
        reason as Exclude<ContentFlaggingReason, "%future added value">
      ]
    }
    return "Other"
  }

  /**
   * Get the location you should redirect to in order to view a given Content.
   * This currently only covers contents that have comments enabled, but please
   * add more cases and extend for more :)
   */
  export function getLocation(
    contentKey: contentUtils_getLocation$key,
    params?: {
      drawerTab?: ContentUsageDrawerSubtab
    }
  ): LocationDescriptor {
    const content = readInlineData<contentUtils_getLocation$key>(
      graphql`
        fragment contentUtils_getLocation on Content @inline {
          id
          type
          comment {
            id
            product {
              slug
            }
            parentContent {
              ...contentUtils_getLocation_parentContent
            }
            contentUsageId
          }
          ...contentUtils_getLocation_parentContent
        }
      `,
      contentKey
    )

    const parentContent = readInlineData<contentUtils_getLocation_parentContent$key>(
      graphql`
        fragment contentUtils_getLocation_parentContent on Content @inline {
          type
          product {
            slug
          }
          post {
            id
            feedId
          }
          assignmentSubmission {
            id
            contentUsageId
          }
        }
      `,
      content.comment ? content.comment.parentContent : content
    )

    const search = new URLSearchParams()
    if (content.comment) {
      search.append("comment", content.comment.id)
    }

    // Posts are viewed on their detail page
    if (parentContent.post) {
      search.append("postId", parentContent.post.id)

      if (content.comment?.product || parentContent.product) {
        return {
          pathname: generatePath(ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST, {
            productSlug: content.comment?.product!.slug || parentContent.product!.slug,
            feedId: parentContent.post.feedId,
          }),
          search: search.toString(),
        }
      }

      return {
        pathname: generatePath(ROUTE_NAMES.COMMUNITY.FEED.POSTS.LIST, {
          feedId: parentContent.post.feedId,
        }),
        search: search.toString(),
      }
    }

    const contentUsageId =
      content.comment?.contentUsageId ||
      parentContent.assignmentSubmission?.contentUsageId

    if (parentContent.assignmentSubmission) {
      search.append("submissionId", parentContent.assignmentSubmission.id)
      search.append("drawerTab", "submissions")
    }

    if (contentUsageId) {
      search.append("u", Relay.fromGlobalId(contentUsageId).id)
      if (params?.drawerTab) search.append("drawerTab", params.drawerTab)
      return {
        pathname: location.pathname,
        search: search.toString(),
      }
    }

    throw new Error(
      `Could not find location for content ${content.id} of type ${content.type}`
    )
  }

  const WEB_FORM_CONTENT_TYPES: ContentType[] = ["survey", "quiz"]

  export function isWebFormContent(contentType: ContentType | undefined | null): boolean {
    if (!contentType) return false
    return WEB_FORM_CONTENT_TYPES.includes(contentType)
  }

  export function webFormTemplateForContent(
    contentType: ContentType | undefined | null
  ): WebFormTemplate {
    switch (contentType) {
      case "survey":
        return "survey"
      case "quiz":
      default:
        return "quiz"
    }
  }
}

export default ContentUtils

export { updateRelayContentLike, updateRelayContentReaction }
