import AppIcon from "@/apps/list/app/AppIcon"
import { ContentTemplateKind, CONTENT_TEMPLATES } from "@/content/util/contentTemplates"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import WithEntitlement from "@components/entitlement/WithEntitlement"
import DiscoCard, { DiscoCardProps, DiscoCardSkeleton } from "@disco-ui/card/DiscoCard"
import { TestIDProps } from "@utils/typeUtils"
import React, { MouseEventHandler } from "react"

interface ContentTemplateCardProps
  extends TestIDProps,
    Pick<DiscoCardProps, "variant" | "inCarousel"> {
  template: ContentTemplateKind
  onClick: MouseEventHandler
  disabled?: boolean
}

function ContentTemplateCard({
  testid,
  template,
  onClick,
  variant,
  inCarousel,
  disabled,
}: ContentTemplateCardProps) {
  const templateConfig = CONTENT_TEMPLATES[template]
  const classes = useStyles()

  if (!templateConfig) return null

  return (
    <WithEntitlement entitlement={templateConfig.entitlement}>
      {({ hasEntitlement }) => (
        <DiscoCard
          testid={testid}
          variant={variant}
          icon={renderIcon()}
          inCarousel={inCarousel}
          title={templateConfig.title}
          description={templateConfig.description}
          onClick={onClick}
          disabled={disabled || !hasEntitlement}
          tooltip={disabled ? templateConfig.disabledTooltip : undefined}
          tooltipProps={disabled ? templateConfig.disabledTooltipProps : undefined}
        />
      )}
    </WithEntitlement>
  )

  function renderIcon() {
    if (!templateConfig) return null
    if (typeof templateConfig.icon === "string") {
      return (
        <div className={classes.appIcon}>
          <AppIcon kind={templateConfig.icon} />
        </div>
      )
    }
    return React.createElement(templateConfig.icon)
  }
}

export function ContentTemplateCardSkeleton() {
  return <DiscoCardSkeleton testid={"ContentTemplateCardSkeleton"} />
}

const useStyles = makeUseStyles((theme) => ({
  appIcon: {
    height: "40px",
    width: "40px",
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor: theme.palette.groovy.neutral[100],
  },
}))

export default ContentTemplateCard
