/**
 * @generated SignedSource<<a8468ccf8568f586ea07da3c1b882ba8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InlineContentDescriptionSectionFragment$data = {
  readonly id: string;
  readonly richEditorDescriptionContent: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"DiscoEditorMentionsFragment">;
  readonly " $fragmentType": "InlineContentDescriptionSectionFragment";
};
export type InlineContentDescriptionSectionFragment$key = {
  readonly " $data"?: InlineContentDescriptionSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentDescriptionSectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InlineContentDescriptionSectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richEditorDescriptionContent",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DiscoEditorMentionsFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "bdd78e543be08291bb53d1c1c60c4921";

export default node;
