import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import { LexicalConfigType } from "@components/editor/config/LexicalConfig"
import DiscoEditor from "@components/editor/DiscoEditor"
import { LexicalChildProps } from "@components/editor/LexicalEditor"
import { DiscoEditorMentionsFragment$key } from "@components/editor/__generated__/DiscoEditorMentionsFragment.graphql"
import { DiscoFormControl, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { MutableRefObject } from "react"

interface Props {
  form: ContentFormStore
  label?: string | React.ReactNode | null
  placeholder?: string
  showOutline?: boolean
  contentKey?: DiscoEditorMentionsFragment$key
  config?: LexicalConfigType
  editorButtonsRef?: MutableRefObject<HTMLDivElement | null>
  setEditorRef?: LexicalChildProps["onMount"]
}

function ContentFormDescriptionSection({
  form,
  label,
  placeholder,
  showOutline = true,
  contentKey,
  config = "content",
  editorButtonsRef,
  setEditorRef,
}: Props) {
  return (
    <DiscoFormControl
      label={
        typeof label === "string" ? (
          <DiscoText variant={"body-md-600"} color={"text.secondary"}>
            {label}
          </DiscoText>
        ) : (
          label
        )
      }
      testid={"ContentFormFields.description"}
      errorMessages={
        form.errorsByField.description || form.errorsByField.richEditorDescriptionContent
      }
    >
      <DiscoEditor
        defaultValue={form.state.content.richEditorDescriptionContent}
        onChange={(v) => (form.state.content.richEditorDescriptionContent = v)}
        minHeight={200}
        showOutline={showOutline}
        placeholder={placeholder}
        mentionsContentKey={contentKey}
        config={config}
        addBlockButtonsAnchorRef={editorButtonsRef}
        onMount={setEditorRef}
      />
    </DiscoFormControl>
  )
}

export default observer(ContentFormDescriptionSection)
