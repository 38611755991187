import { ContentSubscriptionDropdownItemMutation } from "@/content/subscription-button/__generated__/ContentSubscriptionDropdownItemMutation.graphql"
import { ContentSubscriptionDropdownItemQuery } from "@/content/subscription-button/__generated__/ContentSubscriptionDropdownItemQuery.graphql"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoTextSkeleton } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql, useLazyLoadQuery } from "react-relay"

interface Props extends TestIDProps {
  contentId: GlobalID
}

function ContentSubscriptionDropdownItem(props: Props) {
  const { contentId, testid = "ContentSubscriptionDropdownItem" } = props

  const { content } = useLazyLoadQuery<ContentSubscriptionDropdownItemQuery>(
    graphql`
      query ContentSubscriptionDropdownItemQuery($id: ID!) {
        content: node(id: $id) {
          ... on Content {
            viewerIsSubscribed
          }
        }
      }
    `,
    { id: contentId },

    // Check each time since you could be mentioned by someone
    // else and become subscribed in the background.
    { fetchPolicy: "network-only" }
  )

  const form = useFormStore<ContentSubscriptionDropdownItemMutation>(
    graphql`
      mutation ContentSubscriptionDropdownItemMutation(
        $input: ManageContentSubscriptionInput!
      ) {
        response: manageContentSubscription(input: $input) {
          node {
            content {
              viewerIsSubscribed
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      contentId,
      status: content?.viewerIsSubscribed ? "unsubscribed" : "subscribed",
    }
  )

  if (!content) return null

  return (
    <DiscoDropdownItem
      title={
        content.viewerIsSubscribed
          ? "Turn off notifications for replies"
          : "Get notifications for replies"
      }
      onClick={handleSubmit}
      testid={testid}
    />
  )

  async function handleSubmit() {
    const newStatus = content!.viewerIsSubscribed ? "unsubscribed" : "subscribed"
    const { didSave } = await form.submit({ contentId, status: newStatus })
    if (!didSave) return
    displaySuccessToast({
      message: `Notifications turned ${newStatus === "subscribed" ? "on" : "off"}`,
      testid: `${testid}.success-toast`,
    })
  }
}

export default Relay.withSkeleton({
  component: observer(ContentSubscriptionDropdownItem),
  skeleton: () => (
    <DiscoDropdownItem
      title={<DiscoTextSkeleton variant={"body-sm"} width={"175px"} />}
    />
  ),
})
