import ControlCommunityRedirectPage from "@/control/page/ControlCommunityRedirectPage"
import ControlUserDetailPage from "@/control/page/ControlUserDetailPage"
import StaffRoute from "@/control/route/staff-route/StaffRoute"
import CONTROL_ROUTE_NAMES from "@/control/route/util/controlRouteNames"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const ControlHomePage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "control-home-page" */ "@/control/page/ControlHomePage")
)

const ControlUserPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "control-user-page" */ "@/control/page/ControlUserPage")
)

const ControlCommunityPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "control-community-page" */ "@/control/page/ControlCommunityPage"
    )
)

const ControlCommunityDetailPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "control-community-detail-page" */ "@/control/page/ControlCommunityDetailPage"
    )
)

export function getControlRouteComponents() {
  return [
    <StaffRoute
      key={`route:=${CONTROL_ROUTE_NAMES.ROOT}`}
      path={CONTROL_ROUTE_NAMES.ROOT}
      unstyled
      exact
    >
      <ControlHomePage />
    </StaffRoute>,
    <StaffRoute
      key={`route:=${CONTROL_ROUTE_NAMES.COMMUNITIES.ROOT}`}
      path={CONTROL_ROUTE_NAMES.COMMUNITIES.ROOT}
      unstyled
      exact
    >
      <ControlCommunityPage />
    </StaffRoute>,
    <StaffRoute
      key={`route:=${CONTROL_ROUTE_NAMES.COMMUNITIES.DETAIL}`}
      path={CONTROL_ROUTE_NAMES.COMMUNITIES.DETAIL}
      unstyled
      exact
    >
      <ControlCommunityDetailPage />
    </StaffRoute>,
    <StaffRoute
      key={`route:=${CONTROL_ROUTE_NAMES.COMMUNITIES.REDIRECT}`}
      path={CONTROL_ROUTE_NAMES.COMMUNITIES.REDIRECT}
      unstyled
      exact
    >
      <ControlCommunityRedirectPage />
    </StaffRoute>,
    <StaffRoute
      key={`route:=${CONTROL_ROUTE_NAMES.USERS.ROOT}`}
      path={CONTROL_ROUTE_NAMES.USERS.ROOT}
      unstyled
      exact
    >
      <ControlUserPage />
    </StaffRoute>,
    <StaffRoute
      key={`route:=${CONTROL_ROUTE_NAMES.USERS.DETAIL}`}
      path={CONTROL_ROUTE_NAMES.USERS.DETAIL}
      unstyled
      exact
    >
      <ControlUserDetailPage />
    </StaffRoute>,
  ]
}
