import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import MediaLibraryButton from "@/media/add/MediaLibraryButton"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { IMAGE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoButton } from "@disco-ui"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { SyntheticEvent } from "react"

interface InlineCoverPhotoFormProps {
  coverPhoto: string
  classes: ClassNameMap<"coverContainer" | "coverPhoto">
  handleChangeImage: (result: MediaResult) => void
  handleRemoveImage?: (e: SyntheticEvent) => void
}

function InlineCoverPhotoForm({
  coverPhoto,
  classes: propClasses,
  handleChangeImage,
  handleRemoveImage,
}: InlineCoverPhotoFormProps) {
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()
  const isMobile = useIsMobile()

  return (
    <div className={classes.container}>
      <div
        className={classNames(propClasses.coverContainer, showOnHoverClasses.hoverable)}
      >
        <CoverPhoto
          customClassName={propClasses.coverPhoto}
          coverPhoto={coverPhoto}
          actionButtons={!isMobile && renderButtons()}
          testid={"InlineCoverPhotoForm.cover-photo"}
        />
      </div>
      {isMobile && renderButtons()}
    </div>
  )

  function renderButtons() {
    return (
      <div
        className={classNames(classes.coverButtonsContainer, showOnHoverClasses.showable)}
      >
        {renderChangeImageButton()}
        {renderRemoveButton()}
      </div>
    )
  }

  function renderRemoveButton() {
    if (!handleRemoveImage) return null

    return (
      <DiscoButton
        color={"grey"}
        variant={"outlined"}
        leftIcon={isMobile ? undefined : "trash"}
        onClick={handleRemoveImage}
        className={classes.button}
        testid={"InlineCoverPhotoForm.remove-image-button"}
      >
        {"Remove Image"}
      </DiscoButton>
    )
  }

  function renderChangeImageButton() {
    return (
      <MediaLibraryButton
        onUpload={handleChangeImage}
        dropzoneOptions={{ accept: IMAGE_FILE_TYPES }}
        allowedFileTypes={["image"]}
        suggestedDimensions={{ width: 1200, height: 600 }}
        message={"Drop or upload an image"}
        testid={"InlineCoverPhotoForm.change-image-button"}
        onMediaSelect={handleChangeImage}
        cropperProps={{
          stencilProps: { aspectRatio: ASPECT_RATIOS.COVER_PHOTO },
        }}
        PopoverProps={{
          anchorOrigin: {
            vertical: "center",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
      >
        {(btnprops) => (
          <DiscoButton
            {...btnprops}
            className={classes.button}
            testid={"InlineCoverPhotoForm.change-image-button"}
            leftIcon={isMobile ? undefined : "pencil"}
            color={"grey"}
            variant={"outlined"}
          >
            {"Change Image"}
          </DiscoButton>
        )}
      </MediaLibraryButton>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    gap: theme.spacing(1.5),
  },
  coverButtonsContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      flexGrow: 1,
    },
  },
}))

export default observer(InlineCoverPhotoForm)
